import {useEffect, useState} from "react";
import {PDFDocViewer, PDFDocViewerActions} from "./PDFDocViewer";
import {CommonUtil} from "../../util/CommonUtil";

import "./PDFPresenter.css";

import iconZoomIn from "../../assets/images/zoom_in.svg";
import iconZoomOut from "../../assets/images/zoom_out.svg";
import iconZoomReset from "../../assets/images/zoom_contain.svg";
import iconPrev from "../../assets/images/arrow_left.svg";
import iconNext from "../../assets/images/arrow_right.svg";
import {ImageUtil} from "../../util/ImageUtil";
import Rosetta from "../../rosetta/Rosetta";

export const PDFPresenterActions = {
    PAGE_CHANGE : "pagechange"
}

export const PDFPresenter = (props) => {

    const {url} = props;
    const {callback} = props;
    const {options} = props;

    const [scale, setScale] = useState(1);
    const [initialScale, setInitialScale] = useState(1);
    const [rotation, setRotation] = useState(0);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        handleCallback(PDFPresenterActions.PAGE_CHANGE, page);
    }, [page]);

    function handleCallback(action, data) {
        if (action === PDFDocViewerActions.INITIAL_SCALE) {
            console.log("Initial scale: " + data);
            setScale(data);
            setInitialScale(data);
        } else if (action === PDFDocViewerActions.REPORT_PAGES) {
            console.log("Pages reported: " + data);
            setPageCount(data);
        }

        if (callback) {
            callback(action, data);
        }
    }

    function zoom(direction, amount) {
        if (amount === undefined) {
            amount = 0.2;
        }

        let newScale = scale;
        if (direction === "in") {
            newScale += amount;
            if (newScale > 2) {
                newScale = 2;
            }
        } else if (direction === "out") {
            newScale -= amount;
            if (newScale < 0.2) {
                newScale = 0.2;
            }
        }
        setScale(newScale);
    }

    let nextPageButton = (<span className={"pdf-action-placeholder me2"} />);
    let prevPageButton = (<span className={"pdf-action-placeholder ms-2"} />);

    if (page > 1) {
        prevPageButton = (
            <span className={"pdf-action me-2"} onClick={() => setPage(page - 1)} style={{backgroundImage : ImageUtil.background(iconPrev)}} />
        )
    }

    if (page < pageCount) {
        nextPageButton = (
            <span className={"pdf-action ms-2"} onClick={() => setPage(page + 1)} style={{backgroundImage : ImageUtil.background(iconNext)}} />
        )
    }

    return (
        <div className={"pdf-presenter"}>
            <div className={"pdf-controls"}>

                <span className={"pdf-action me-2"} onClick={() => zoom("out")} style={{backgroundImage : ImageUtil.background(iconZoomOut)}} />
                <span className={"pdf-action"} onClick={() => setScale(initialScale)} style={{backgroundImage : ImageUtil.background(iconZoomReset)}} />
                <span className={"pdf-action ms-2"} onClick={() => zoom("in")} style={{backgroundImage : ImageUtil.background(iconZoomIn)}} />

                <span className={"fill-space"} />

                {prevPageButton}
                <span className={"page-count"}>{Rosetta.string("pdf.page", { page, pageCount })}</span>
                {nextPageButton}

            </div>

            <div className={"pdf-wrapper mt-2"}>
                <PDFDocViewer
                    url={url}
                    page={page}
                    scale={scale}
                    rotation={rotation}
                    pins={CommonUtil.getOrDefault(options, "pins", undefined)}
                    callback={handleCallback} />
            </div>
        </div>
    )

}