import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import {ImageUtil} from "../../../../util/ImageUtil";
import {DataManager} from "../../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {UrlUtil} from "../../../../util/UrlUtil";
import {HealthSafety} from "../../../../util/HealthSafety";

import "./HealthSafetyVideoEditorModal.css";
import {Toast} from "../../../toast/TokyoToaster";
import Validator from "../../../../util/Validator";

export const HealthSafetyVideoEditorModal = (props) => {

    const {shown} = props;
    const {videoID} = props;
    const {typeID} = props; // If set videoTypeID is overridden, do NOT show video type selection
    const {defaultOrdering} = props;

    const videoInput = useRef();
    const thumbInput = useRef();

    const [videoTypeID, setVideoTypeID] = useState(typeID);
    const [videoFilePath, setVideoFilePath] = useState(undefined);
    const [videoTitle, setVideoTitle] = useState("");
    const [videoDescription, setVideoDescription] = useState("");
    const [videoThumbPath, setVideoThumbPath] = useState("");
    const [videoOrdering, setVideoOrdering] = useState(defaultOrdering);
    const [videoError, setVideoError] = useState(null);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [videoFile, setVideoFile] = useState(undefined);
    const [thumbFile, setThumbFile] = useState(undefined);

    const [videoNetworkInFlight, setVideoNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (shown) {
            setVideoTypeID(typeID);
            setVideoOrdering(defaultOrdering);

            if (videoID) {
                fetchVideoFromNetwork();
            }
        } else {
            setVideoNetworkInFlight(false);
            setSubmitNetworkInFlight(false);
            setVideoTypeID(undefined);
            setVideoFilePath(undefined);
            setVideoTitle("");
            setVideoDescription("");
            setVideoThumbPath(undefined);
            setVideoOrdering(defaultOrdering);
            setForceDismiss(false);
            setUploadProgress(0);

            setVideoFile(undefined);
            setThumbFile(undefined);
            setVideoError(null);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function videoInputDidChange(e) {
        if (e.target.files.length > 0) {
            let video = e.target.files[0];
            setVideoFile(video);
            setVideoFilePath(video.name);
        }
    }

    function thumbInputDidChange(e) {
        if (e.target.files.length > 0) {
            let image = e.target.files[0];
            setThumbFile(image);

            let reader = new FileReader();
            reader.onload = () => {
                setVideoThumbPath(reader.result);
            };
            reader.readAsDataURL(image);
        }
    }

    function populateVideoIntoState(video) {
        setVideoTypeID(video.projectHealthSafetyVideoTypeID);
        setVideoFilePath(UrlUtil.getLastPathPart(video.filePath));
        setVideoTitle(video.title);
        setVideoDescription(video.description);
        setVideoThumbPath(video.thumbPath);
    }

    function validateForm() {
        let rules = [
            Validator.rule("videoTypeID", "int", Rosetta.string("health_safety.video_editor_type"), "projectHealthSafetyVideoTypeID"),
            Validator.rule("videoTitle", "string", Rosetta.string("health_safety.video_editor_title"), "title"),
            Validator.rule("videoDescription", "string", Rosetta.string("health_safety.video_editor_description"), "description")
        ];

        if (!videoID) {
            rules.push(Validator.rule("videoFile", "file", Rosetta.string("health_safety.video_editor_file"), "file"));
        }

        const validationResult = Validator.validateCreateFormData({
            videoTypeID,
            videoTitle,
            videoDescription,
            videoFile
        }, rules);

        if (!validationResult.success) {
            setVideoError(validationResult.error);
            return false;
        }

        setVideoError(null);
        return true;
    }

    function submissionDidComplete(video) {
        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string("health_safety.video_editor_success"),
            Toast.SUCCESS,
            Toast.LONG
        );

        handleCallback("close", video);
    }

    function fetchVideoFromNetwork() {
        if (videoNetworkInFlight) return;
        setVideoNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            id : videoID,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectHealthSafety.getProjectHealthSafetyVideos, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.videos.length > 0) {
                        populateVideoIntoState(resp.data.videos[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setVideoNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setVideoNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "HSVE1000C"}));
            });
    }

    function submitVideoOverNetwork() {
        if (submitNetworkInFlight) return;

        if (!validateForm()) {
            return;
        }

        setSubmitNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = new FormData();
        if (videoID) {
            formData.append("id", videoID);
        }
        formData.append("projectID", (project ? project.id : undefined));
        formData.append("projectHealthSafetyVideoTypeID", videoTypeID);
        formData.append("title", videoTitle);
        formData.append("description", videoDescription);

        if (videoFile) {
            formData.append("file", videoFile);
        }

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                if (thumbFile) {
                    // If there is a thumb file, leave a bit at the end of the progress bar to show that file upload progress
                    progress /= 0.75;
                }

                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.projectHealthSafety.submitHealthSafetyVideo, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (thumbFile) {
                        submitVideoThumbnailOverNetwork(resp.data.video.id);
                    } else {
                        submissionDidComplete(resp.data.video);
                    }
                } else {
                    showError(API.formatError(resp));
                }
                if (!thumbFile) {
                    setSubmitNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_uncoded"));
            });
    }

    function submitVideoThumbnailOverNetwork(videoID) {
        const project = DataManager.getSelectedProject();

        let formData = new FormData();
        formData.append("projectID", (project ? project.id : undefined));
        formData.append("healthSafetyVideoID", videoID);
        formData.append("file", thumbFile);

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                if (thumbFile) {
                    progress += 75;
                }

                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.projectHealthSafety.submitHealthSafetyVideoThumbnail, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    submissionDidComplete(resp.data.video);
                } else {
                    showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_uncoded"));
                setSubmitNetworkInFlight(false);
            });
    }

    function showError(message) {
        setVideoError(message);
    }

    if (!shown) return [];

    let videoTypeSelect = [];
    if (!typeID) {
        videoTypeSelect = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("health_safety.video_editor_type")}</label>
                    <select className={"form-select"} value={videoTypeID} onChange={(e) => setVideoTypeID(parseInt(e.target.value))}>
                        <option value={HealthSafety.videoType.TOOLBOX}>{Rosetta.string("health_safety.video_editor_type_toolbox")}</option>
                    </select>
                </div>
            </div>
        )
    }

    let submitProgress = [];
    if (submitNetworkInFlight) {
        submitProgress = (
            <div className={"row mt-2"}>
                <div className={"progress-bar"}>
                    <div className={"progress"} style={{width : uploadProgress + "%"}} />
                </div>
            </div>
        )
    } else if (!videoNetworkInFlight) {
        submitProgress = (
            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => submitVideoOverNetwork()}>{Rosetta.string("common.submit")}</button>
                </div>
            </div>
        );
    }

    let errorElem = [];
    if (videoError) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{videoError}</div>
                </div>
            </div>
        );
    }
    
    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("health_safety.video_editor_modal_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"health-safety-video-editor"}>

                {videoTypeSelect}

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.video_editor_file")}</label>
                        <div className={"file-hide"}><input type={"file"} accept={"video/mp4"} onChange={videoInputDidChange} ref={videoInput} /></div>
                        <div className={"file-selection-area"}>
                            <span className={"button-area"}>
                                <button className={"btn btn-outline-primary"} onClick={() => videoInput.current.click()}>{Rosetta.string("health_safety.video_editor_file_pick")}</button>
                            </span>

                            <div className={"info-area"}>
                                <div className={"filepath"}>{(videoFilePath ? videoFilePath : Rosetta.string("health_safety.video_editor_file_none"))}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.video_editor_title")}</label>
                        <input type={"text"} className={"form-control"} value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.video_editor_description")}</label>
                        <textarea className={"form-control"} value={videoDescription} onChange={(e) => setVideoDescription(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.video_editor_thumbnail")}</label>
                        <div className={"file-hide"}><input type={"file"} onChange={thumbInputDidChange} ref={thumbInput} /></div>
                        <div className={"ratio ratio-16x9 image-thumbnail"} style={{backgroundImage : ImageUtil.background(videoThumbPath)}} />
                        <div className={"mt-1 text-center"}>
                            <button className={"btn btn-outline-primary"} onClick={() => thumbInput.current.click()}>{Rosetta.string("health_safety.video_editor_thumbnail_pick")}</button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <hr />
                    </div>
                </div>

                {submitProgress}

                {errorElem}

            </div>

        </Offcanvas>
    )

}