import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {useEffect, useState} from "react";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {PaymentFormComponent} from "../common/PaymentFormComponent";
import {UIBlocker} from "../../loading/UIBlocker";
import {Toast} from "../../toast/TokyoToaster";
import {Navigator} from "../../../util/Navigator";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";

// Wrapper for Stripe Elements
export const SubscriptionEditorSettingsScreen = (props) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
        <Elements stripe={stripePromise}>
            <SubscriptionEditorSettingsScreenInternal {...props} />
        </Elements>
    )
}

// The actual screen
export const SubscriptionEditorSettingsScreenInternal = (props) => {

    const [details, setDetails] = useState(null);
    const [options, setOptions] = useState(null);
    const [optionNetworkInFlight, setOptionNetworkInFlight] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedSku, setSelectedSku] = useState(null);
    const [slots, setSlots] = useState(0);

    const [blocked, setBlocked] = useState(false);
    const [blockedMessage, setBlockedMessage] = useState("");

    const [paymentData, setPaymentData] = useState(null);
    const [paymentNetworkInFlight, setPaymentNetworkInFlight] = useState(false);

    const [awaitingPayment, setAwaitingPayment] = useState(false);
    const [awaitKey, setAwaitKey] = useState();

    const stripe = useStripe();

    useEffect(() => {
        getSubscriptionOptionsFromNetwork();
    }, []);

    useEffect(() => {
        fetchSubscriptionActionFromNetwork();
    }, [selectedPlan, selectedSku, slots]);

    useEffect(() => {
        if (awaitingPayment) {
            setAwaitKey(Math.random());
        }
    }, [awaitingPayment])

    useEffect(() => {
        checkPaymentComplete();
    }, [awaitKey])

    function paymentFormDidCallback(action, data) {
        if (action === "submit") {
            // TODO Handle address
            fetchSubscriptionActionFromNetwork(true, data.paymentMethodID);
        }
    }

    function handleSlotChange(slot) {
        if (slot > 0) {
            setSlots(slot);
        }
    }

    function getSubscriptionOptionsFromNetwork() {
        if (optionNetworkInFlight) return;
        setOptionNetworkInFlight(true);

        Axios.get(ENDPOINTS.subscription.getUpgradeOptions)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.blockModification !== undefined && resp.data.blockModification) {
                        // Block modification!
                        setBlockedMessage(resp.data.blockMessage);
                        setBlocked(true);
                    } else {
                        setBlocked(false);
                        setDetails(resp.data.subscription);
                        setOptions(resp.data.options);

                        if (resp.data.subscription) {
                            // Set up individual data now
                            setSelectedPlan(resp.data.subscription.id);
                            setSelectedSku(resp.data.subscription.sku);
                            setSlots(Math.max(1, resp.data.subscription.subscriptionSlots));
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setOptionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setOptionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBO1000C" }));
            })
    }

    function fetchSubscriptionActionFromNetwork(commit, paymentMethodID) {
        if (!selectedPlan || !selectedSku) return;
        if (paymentNetworkInFlight) return;
        setPaymentNetworkInFlight(true);

        let data = {
            paymentDigestID : paymentData ? paymentData.paymentDigestID : undefined,
            paymentMethodID,
            subscriptionTypeID : selectedPlan,
            subscriptionSku : selectedSku,
            subscriptionSlots : Math.max(1, slots)
        };

        if (commit) {
            data.commit = 1;
        }

        Axios.post(ENDPOINTS.subscription.getSubscriptionAction, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (commit) {
                        if (resp.data.actionRequired) {
                            // Pop 3DS confirmation to complete transaction
                            stripe.handleCardAction(resp.data.clientSecret)
                                .then((r) => {
                                    console.log(r);
                                    if (!r.error) {
                                        setAwaitingPayment(true);
                                    } else {
                                        AlertModal.showError(r.error);
                                    }
                                    setPaymentNetworkInFlight(false);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setPaymentNetworkInFlight(false);
                                    AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBP2103C" }));
                                });
                        } else if (resp.data.complete) {
                            if (resp.data.awaitPayment) {
                                setAwaitingPayment(true);
                            } else {
                                onActionComplete();
                            }
                        }
                    } else {
                        setPaymentData(resp.data);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPaymentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPaymentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBU3000C" }));
            })
    }

    function checkPaymentComplete() {
        if (!awaitingPayment) return;

        console.log("AWAIT PAYMENT: " + awaitKey);

        let data = {
            paymentDigestID : paymentData.paymentDigestID
        };

        Axios.post(ENDPOINTS.subscription.checkPaymentComplete, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("PAYMENT COMPLETE!");
                    onActionComplete();
                } else {
                    console.log("PAYMENT NOT YET COMPLETE");
                    setTimeout(() => {
                        setAwaitKey(Math.random());
                    }, 2000);
                }
            })
            .catch((e) => {
                console.log(e);

                setTimeout(() => {
                    setAwaitKey(Math.random());
                }, 2000);
            });
    }

    function onActionComplete() {
        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string("subscription_detail.modify_submit_success"),
            Toast.SUCCESS,
            Toast.LONG
        );
        Navigator.navigate("/settings/subscription-details/")
    }

    // RENDER

    let mainContent = [];

    if (awaitingPayment) {
        mainContent = (
            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-10 col-lg-8"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <h3>{Rosetta.string("subscription.payment_waiting_message")}</h3>
                                </div>

                                <div className={"col-12 mt-4 text-center"}>
                                    <LoadingSpinner inline={true} small={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (!optionNetworkInFlight) {
        if (blocked) {
            mainContent = (
                <div className={"row justify-content-center mt-4"}>
                    <div className={"col-12 col-md-10 col-lg-8 text-center"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <h4>{blockedMessage}</h4>

                                <div className={"mt-4"}>
                                    <button className={"btn btn-primary"} onClick={() => Navigator.goBack()}>{Rosetta.string("subscription_detail.modify_go_back")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            let planElem = [];
            let skus = [];
            if (options && options.length > 0) {
                options.forEach((type) => {
                    let isSelected = false;
                    if (parseInt(type.id) === parseInt(selectedPlan)) {
                        isSelected = true;
                        skus = type.skus;
                    }

                    let button;
                    if (isSelected) {
                        button = <button className={"btn btn-primary"}>{Rosetta.string("subscription_detail.modify_selected")}</button>
                    } else {
                        button = <button className={"btn btn-outline-primary"} onClick={() => setSelectedPlan(type.id)}>{Rosetta.string("subscription_detail.modify_select")}</button>
                    }

                    planElem.push(
                        <div className={"col-6 col-md-4 col-md-3"}>
                            <div className={"card text-center"}>
                                <div className={"card-header justify-content-center"}>{type.name}</div>
                                <div className={"card-footer justify-content-center"}>
                                    {button}
                                </div>
                            </div>
                        </div>
                    )
                });

                planElem = (
                    <>
                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <h3>{Rosetta.string("subscription_detail.modify_tiers")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2 justify-content-center"}>
                            {planElem}
                        </div>
                    </>
                )
            }

            let skuElem = [];
            if (skus.length > 0) {
                skus.forEach((sku) => {
                    let isSelected = selectedSku === sku.sku;

                    let button;
                    if (isSelected) {
                        button = <button className={"btn btn-primary"}>{Rosetta.string("subscription_detail.modify_selected")}</button>
                    } else {
                        button = <button className={"btn btn-outline-primary"} onClick={() => setSelectedSku(sku.sku)}>{Rosetta.string("subscription_detail.modify_select")}</button>
                    }

                    skuElem.push(
                        <div className={"col-6 col-md-4 col-lg-3"}>
                            <div className={"card text-center"}>
                                <div className={"card-header justify-content-center"}>{sku.name}</div>
                                <div className={"card-body"}>
                                    <div>&pound;{sku.price ? (sku.price / 100).toFixed(2) : 0}</div>
                                    <div>{Rosetta.string("subscription_detail.modify_sku_per_user")}</div>
                                </div>
                                <div className={"card-footer justify-content-center"}>
                                    {button}
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            if (skuElem.length > 0) {
                skuElem = (
                    <>
                        <div className={"row mt-4"}>
                            <div className={"col-12 text-center"}>
                                <h3>{Rosetta.string("subscription_detail.modify_sku")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2 justify-content-center"}>
                            {skuElem}
                        </div>
                    </>
                )
            }

            let paymentElem = [];
            if (paymentData !== null) {
                if (paymentData.actionRequired) {
                    if (paymentData.paymentRequired) {
                        let paymentMethods = [];
                        if (paymentData.paymentMethods !== undefined) {
                            paymentMethods = paymentData.paymentMethods;
                        }

                        // Display payment form
                        paymentElem = (
                            <div className={"row mt-4 justify-content-center"}>
                                <div className={"col-12 col-md-10 col-lg-8"}>
                                    <PaymentFormComponent
                                        title={Rosetta.string("subscription_detail.modify_summary_pay_now")}
                                        price={"£" + (paymentData.total / 100).toFixed(2)}
                                        paymentMethods={paymentMethods}
                                        callback={paymentFormDidCallback} />
                                </div>
                            </div>
                        )
                    } else {
                        // Display summary for next billing cycle
                        paymentElem = (
                            <div className={"row mt-4 justify-content-center"}>
                                <div className={"col-12 col-md-10 col-lg-8"}>
                                    <div className={"card"}>
                                        <div className={"card-body text-center"}>
                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <strong>{Rosetta.string("subscription_detail.modify_summary_pay_later")}</strong>
                                                </div>
                                            </div>

                                            <div className={"row"}>
                                                <div className={"col-12"}>
                                                    <h3>&pound;{ (paymentData.total / 100).toFixed(2) }</h3>
                                                </div>
                                            </div>

                                            <div className={"row mt-4"}>
                                                <div className={"col-12"}>
                                                    <button className={"btn btn-primary"} onClick={() => fetchSubscriptionActionFromNetwork(true)}>{Rosetta.string("subscription_detail.modify_submit_no_pay")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            }

            let slotElem = [];
            if (parseInt(selectedPlan) !== SubscriptionUtil.TYPES.FREE) {
                slotElem = (
                    <>
                        <div className={"row mt-4 justify-content-center"}>
                            <div className={"col-12 col-md-10 col-lg-8 text-center"}>
                                <h3>{Rosetta.string("subscription_detail.modify_slots")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-2 justify-content-center"}>
                            <div className={"col-12 col-md-10 col-lg-8"}>
                                <div className={"card"}>
                                     <div className={"card-body"}>
                                         <label>{Rosetta.string("subscription_detail.modify_slot_label")}</label>
                                         <input type={"number"} className={"form-control"} value={slots} onChange={(e) => handleSlotChange(e.target.value)} />
                                     </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            mainContent = (
                <div className={"animate-screen-content"}>
                    {planElem}
                    {skuElem}
                    {slotElem}
                    {paymentElem}
                </div>
            )
        }

    } else {
        mainContent = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>
            </div>
        )
    }

    return (
        <div className={"app-screen subscription-editor-settings-screen"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("subscription_detail.modify_title")} />
                </div>
            </div>

            {mainContent}

            <UIBlocker shown={paymentNetworkInFlight} />

        </div>
    )

}