import {useEffect, useState} from "react";
import {BaseModal} from "../../alertmodal/BaseModal";
import Rosetta from "../../../rosetta/Rosetta";
import WindowUtil from "../../../util/WindowUtil";

export const LoginInformationModal = (props) => {

    const {shown} = props;
    const {callback} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function goToSubscriptionPage() {
        window.open("https://constructapps.co.uk/pricing/");
        setForceDismiss(true);
    }

    // RENDER

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"alert-modal-dialog-title"}>
                {Rosetta.string("login.user_info_title")}
            </div>

            <div className={"alert-modal-dialog-message"}>
                {Rosetta.string("login.user_info_subscription_message")}
            </div>

            <div className={"alert-modal-dialog-buttons mb-4"}>
                <button className={"alert-dialog-button success"} onClick={() => goToSubscriptionPage()}>
                    {Rosetta.string("login.user_info_subscription_action")}
                </button>
            </div>

            <div className={"alert-modal-dialog-title mt-4"}>
                {Rosetta.string("login.new_user_title")}
            </div>

            <div className={"alert-modal-dialog-message"}>
                {Rosetta.string("login.new_user_message")}
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <button className={"alert-dialog-button"} onClick={() => setForceDismiss(true)}>
                    {Rosetta.string("common.close")}
                </button>
            </div>

        </BaseModal>
    );

}