import Axios from "axios";
import {API, ENDPOINTS} from "../network/API";
import {DataManager} from "../data/DataManager";

export const AppCounts = {
    refreshAppCounts : (projectID) => {
        Axios.post(ENDPOINTS.project.getAppCounts, { projectID })
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setAppCounts(resp.data);
                } else {
                    console.log(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
            })
    },
    getAppCounts : (projectID) => {
        return DataManager.getAppCounts(projectID);
    }
}