import "./EmergingButton.css";

export const EmergingButton = (props) => {

    let classExtra = "";

    if (props.className !== undefined) {
        classExtra = " " + props.className;
    }

    if (props.pill && props.pill === true) {
        classExtra += " pill";
    }

    return (
        <div className={"tokyo-emerging-button" + classExtra}>
            <span className={"content-wrapper"}>
                {props.children}
            </span>
        </div>
    )

}