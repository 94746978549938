import {DataManager} from "../data/DataManager";
import {Navigator} from "./Navigator";

export const Bouncer = {
    restrictScreen : (minPrivLevel) => {
        let userPrivLevel = 0;
        if (DataManager.getUser() !== undefined) {
            userPrivLevel = DataManager.getUser().cmsPrivLevel;
        }

        if (userPrivLevel < minPrivLevel) {
            console.log("BOUNCER: No access. User priv level: " + userPrivLevel + " is lower than required level: " + minPrivLevel);
            Navigator.navigate("/");
        }
    },
    checkProjectSelected : () => {
        const project = DataManager.getSelectedProject();
        if (!project) {
            Navigator.navigate("/select-project");
        }
    }
}