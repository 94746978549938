import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";
import {FilterText} from "../../filter/FilterText";

export const FormsListScreen = (props) => {

    const {date} = props;
    const {showTitle} = props;

    const [filterFormTypeID, setFilterFormTypeID] = useState(-1);
    const [filterKeyword, setFilterKeyword] = useState("");
    const [filterDate, setFilterDate] = useState(null);

    const [forms, setForms] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [formCount, setFormCount] = useState(0);

    const [formsNetworkInFlight, setFormsNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchFormsFromNetwork();
    }, []);

    useEffect(() => {
        fetchFormsFromNetwork();
    }, [currentPage, filterFormTypeID, filterDate, date, filterKeyword]);

    function tableWasClicked(row) {
        if (row) {
            Navigator.navigate("/forms/" + row.formSubmissionID);
        }
    }

    function fetchFormsFromNetwork() {
        if (formsNetworkInFlight) return;
        setFormsNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            fetchForm : false,
            requireSubmissions : true
        };

        if (filterFormTypeID > 0) {
            formData.projectFormTypeID = filterFormTypeID;
        }

        if (filterKeyword !== "") {
            formData.keyword = filterKeyword;
        }

        if (date) {
            let chronosDate =  Chronos.with(date);
            if (chronosDate && chronosDate.isValid()) {
                formData.startDate = chronosDate.startOfDay().seconds();
                formData.endDate = chronosDate.endOfDay().seconds();
            }
        } else if (filterDate) {
            let chronosDate = Chronos.parse(filterDate);
            if (chronosDate && chronosDate.isValid()) {
                formData.startDate = chronosDate.startOfDay().seconds();
                formData.endDate = chronosDate.endOfDay().seconds();
            }
        }

        Axios.post(ENDPOINTS.projectForm.getProjectFormsAdmin, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setForms(resp.data.forms);
                    setFormCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
                setFormsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PFG1000C"}));
                setFormsNetworkInFlight(false);
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    // If a Date has been provided, do not allow the user to filter manually here.
    // Also rearrange filter controls to format better for the missing element
    let dateFilterElem = [];
    let controlClass = "col-12 col-md-6";
    if (date === undefined) {
        controlClass = "col-12 col-md-4";
        dateFilterElem = (
            <div className={controlClass}>
                <input type={"date"} className={"form-control"} value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
            </div>
        );
    }

    let screenTitleElem = [];
    if (showTitle === undefined || showTitle) {
        screenTitleElem = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("forms.title")} />
                </div>
            </div>
        );
    }

    return (
        <div className={"app-screen forms-list-screen"}>
            {screenTitleElem}

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={controlClass}>
                        <select className={"form-select"} value={filterFormTypeID} onChange={(e) => { setFilterFormTypeID(parseInt(e.target.value)) }}>
                            <option value={-1}>{Rosetta.string("forms.filter_all")}</option>
                            <option value={1}>{Rosetta.string("forms.filter_form")}</option>
                            <option value={2}>{Rosetta.string("forms.filter_inspections")}</option>
                            <option value={3}>{Rosetta.string("forms.filter_permits")}</option>
                        </select>
                    </div>

                    <div className={controlClass}>
                        <FilterText callback={setFilterKeyword} />
                    </div>

                    {dateFilterElem}
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>

                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={forms}
                                placeholderMode={formsNetworkInFlight}
                                placeholerRows={5}
                                onRowClick={tableWasClicked}>

                                <Column name={"dateCreated"} title={Rosetta.string("forms.table_date")} render={(data, row) => (
                                    <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                                )} />
                                <Column name={"formType"} title={Rosetta.string("forms.table_form_type")} className={"text-center"} />
                                <Column name={"title"} title={Rosetta.string("forms.table_form_subtype")} className={"text-center"} />
                                <Column name={"firstName"} title={Rosetta.string("forms.table_author")} className={"text-center"} render={(data, row) => (
                                    <>{Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.surname })}</>
                                )} />
                                <Column name={"expires"} title={Rosetta.string("forms.table_expires")} className={"text-center"} render={(data, row) => (
                                    <>{data ? Chronos.withTimestampSeconds(data).format("dd/MM/yyyy") : "---"}</>
                                )} />
                                <Column name={"id"} className={"text-center"} render={(data, row) => (
                                    <button className={"btn btn-primary"}>{Rosetta.string("common.view")}</button>
                                )} />

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={currentPage}
                                totalCount={formCount}
                                pageSize={20}
                                onClick={(page) => setCurrentPage(page)} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}