import {BaseModal} from "../../alertmodal/BaseModal";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import {Chronos} from "../../../chronos/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";

export const OrganiserNoteScheduleModal = (props) => {

    const {shown} = props;
    const {note} = props;

    const [date, setDate] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);
    const [error, setError] = useState(null);

    const [scheduleNetworkInFlight, setScheduleNetworkInFlight] = useState(false);

    useEffect(() => {
        if (!shown) {
            setDate(null);
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    function submitScheduleOverNetwork() {
        if (scheduleNetworkInFlight) return;

        setError(null);

        let chronosDate = Chronos.with(new Date(date));

        if (date === null || !chronosDate.isValid()) {
            showError(Rosetta.string("organiser.note_schedule_date"));
            return;
        }

        setScheduleNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            title : (note ? note.title : "---"),
            description : (note ? note.text : "---"),
            eventDate : chronosDate.seconds()
        };

        Axios.post(ENDPOINTS.schedule.submitUserCalendarEvent, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("organiser.note_schedule_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    showError(API.formatError(resp));
                }
                setScheduleNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", { error_code : "PNSE1000C" }));
                setScheduleNetworkInFlight(false);
            });
    }

    function showError(message) {
        setError(message);
    }

    if (!shown) return [];

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h4>{Rosetta.string("organiser.note_schedule_title")}</h4>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("organiser.note_schedule_message")}
                </div>
            </div>

            <div className={"row mt-2 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6 text-center"}>
                    <label>{Rosetta.string("organiser.note_schedule_date")}</label>
                    <input type={"date"} className={"form-control"} value={date} onChange={(e) => setDate(e.target.value)} />
                </div>
            </div>

            {errorElem}

            <div className={"row mt-2"}>
                <div className={"col-12 text-center alert-modal-dialog-buttons"}>
                    <button className={"alert-dialog-button success"} onClick={() => submitScheduleOverNetwork()}>{Rosetta.string("organiser.note_schedule_submit")}</button>
                    <button className={"alert-dialog-button"} onClick={() => setForceDismiss(true)}>{Rosetta.string("common.cancel")}</button>
                </div>
            </div>

        </BaseModal>
    )

}