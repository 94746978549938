import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";

import "./SubscriptionPaymentComponent.css";
import {CommonUtil} from "../../../util/CommonUtil";

export const SubscriptionPaymentComponent = (props) => {

    const {formData} = props;
    const {step} = props;

    const [digestPrice, setDigestPrice] = useState(0);
    const [paymentDigestID, setPaymentDigestID] = useState();
    const [idempotency, setIdempotency] = useState();
    const [paymentNetworkInFlight, setPaymentNetworkInFlight] = useState(false);
    const [awaitConfirmation, setAwaitConfirmation] = useState(false);
    const [awaitKey, setAwaitKey] = useState(null);

    const [billingName, setBillingName] = useState("");
    const [billingAddressSame, setBillingAddressSame] = useState(false);
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingAddress2, setBillingAddress2] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCounty, setBillingCounty] = useState("");
    const [billingPostcode, setBillingPostcode] = useState("");

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        getPaymentDetailsOverNetwork()
    }, []);

    useEffect(() => {
        if (!paymentNetworkInFlight && !awaitConfirmation) {
            if (billingAddressSame) {
                setBillingAddress1(CommonUtil.getOrDefault(formData, "companyAddress1", ""));
                setBillingAddress2(CommonUtil.getOrDefault(formData, "companyAddress2", ""));
                setBillingCity(CommonUtil.getOrDefault(formData, "companyCity", ""));
                setBillingCounty(CommonUtil.getOrDefault(formData, "companyCounty", ""));
                setBillingPostcode(CommonUtil.getOrDefault(formData, "companyPhone", ""));
            } else {
                setBillingAddress1("");
                setBillingAddress2("");
                setBillingCity("");
                setBillingCounty("");
                setBillingPostcode("");
            }
        }
    }, [billingAddressSame])

    useEffect(() => {
        if (awaitConfirmation) {
            setAwaitKey(Math.random());
        }
    }, [awaitConfirmation])

    useEffect(() => {
        checkPaymentComplete();
    }, [awaitKey])

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function getPaymentDetailsOverNetwork() {
        if (paymentNetworkInFlight) return;
        setPaymentNetworkInFlight(true);

        let data = {
            paymentDigestID,
            subscriptionSku : formData ? formData.subscriptionSku.sku : "",
            quantity : formData ? formData.numUsers : 0,
            name : formData.companyName,
            emailAddress : formData.userEmailAddress
        };

        Axios.post(ENDPOINTS.subscription.getPaymentDetails, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setPaymentDigestID(resp.data.paymentDigestID)
                    setDigestPrice(resp.data.total);
                    setIdempotency(resp.data.idempotency);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setPaymentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setPaymentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "SUBP1000C"}));
            })
    }

    async function submitPayment(e, skipInFlightCheck) {
        if (skipInFlightCheck === undefined) {
            skipInFlightCheck = false;
        }

        e.preventDefault();
        e.stopPropagation();

        const requiredFields = [
            { keyName : "paymentName", data : billingName, label : Rosetta.string("subscription.payment_card_name_on_card") },
            { keyName : "paymentAddressLine1", data : billingAddress1, label : Rosetta.string("subscription.company_address_line1") },
            { keyName : "paymentAddressLine2", data : billingAddress2, label : Rosetta.string("subscription.company_address_line2") },
            { keyName : "paymentAddressCity", data : billingCity, label : Rosetta.string("subscription.company_address_city") },
            { keyName : "paymentAddressCounty", data : billingCounty, label : Rosetta.string("subscription.company_address_county") },
            { keyName : "paymentAddressPostcode", data : billingPostcode, label : Rosetta.string("subscription.company_address_postcode") }
        ];

        let newFormData = CommonUtil.cloneObject(formData);

        for (let i = 0; i < requiredFields.length; i++) {
            let field = requiredFields[i];

            if (field.data.trim() === "") {
                // Uh oh, return now.
                AlertModal.showError(Rosetta.string("validation.missing_property", { label : field.label }));
                return;
            }

            newFormData[field.keyName] = field.data;
        }

        if (!skipInFlightCheck) {
            if (paymentNetworkInFlight) return;
            setPaymentNetworkInFlight(true);
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
                type : "card",
                card : elements.getElement(CardElement),
                billing_details : {
                    name : billingName,
                    address : CommonUtil.implode([billingAddress1, billingAddress2, billingCity, billingCounty, billingPostcode])
                }
            });

        if (paymentMethod) {
            SubscriptionUtil.submitSubscription(newFormData, paymentDigestID, paymentMethod)
                .then((r) => {
                    const resp = API.parse(r);
                    if (resp.success) {
                        if (resp.data.requiresAction) {
                            // Pop 3DS confirmation to complete transaction
                            stripe.handleCardAction(resp.data.clientSecret)
                                .then((r) => {
                                    if (!r.error) {
                                        setAwaitConfirmation(true);
                                    } else {
                                        AlertModal.showError()
                                    }
                                    setPaymentNetworkInFlight(false);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setPaymentNetworkInFlight(false);
                                    AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBP2103C" }));
                                });
                        } else if (resp.data.complete) {
                            console.log("PAYMENT COMPLETE");
                            setAwaitConfirmation(true);
                        } else {
                            // TODO Uhhh???
                        }
                    } else {
                        AlertModal.showError(API.formatError(resp));
                        setPaymentNetworkInFlight(false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    setPaymentNetworkInFlight(false);
                    AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBP2100C" }));
                })
        } else {
            let errorMessage;
            if (error) {
                errorMessage = error.message
            } else {
                errorMessage = Rosetta.string("common.error_common_unknown", { error_code : "SUBP2001C" });
            }

            AlertModal.showError(errorMessage);
            setPaymentNetworkInFlight(false);
        }
    }

    function checkPaymentComplete() {
        if (!awaitConfirmation) return;

        console.log("AWAIT PAYMENT: " + awaitKey);

        let data = {
            paymentDigestID
        };

        Axios.post(ENDPOINTS.subscription.checkPaymentComplete, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    console.log("PAYMENT COMPLETE!");
                    handleCallback("submit", {
                        step,
                        formData
                    });
                } else {
                    console.log("PAYMENT NOT YET COMPLETE");
                    setTimeout(() => {
                        setAwaitKey(Math.random());
                    }, 2000);
                }
            })
            .catch((e) => {
                console.log(e);

                setTimeout(() => {
                    setAwaitKey(Math.random());
                }, 2000);
            });
    }

    let subDuration = "";
    if (formData.subscriptionSku !== undefined) {
        subDuration = SubscriptionUtil.parseDuration(formData.subscriptionSku.cycleDuration);
    }

    let mainContent = (
        <form onSubmit={submitPayment}>
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h3>{Rosetta.string("subscription.payment_title")}</h3>
                    <span>{Rosetta.string("subscription.payment_message", { duration : subDuration })}</span>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6 text-center"}>
                    <h3>{Rosetta.string("subscription.payment_pay_today", { price : "£" + (digestPrice / 100).toFixed(2) })}</h3>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <label>{Rosetta.string("subscription.payment_card_details")}</label>
                    <div className={"form-control card-container"}>
                        <CardElement />
                    </div>
                </div>
            </div>

            <div className={"row mt-2 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <label>{Rosetta.string("subscription.payment_card_name_on_card")}</label>
                    <input type={"text"} className={"form-control"} value={billingName} onChange={(e) => setBillingName(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.payment_card_billing_address")}</label>
                                    <label><input type={"checkbox"} value={billingAddressSame} onChange={(e) => setBillingAddressSame(e.target.checked)} /> {Rosetta.string("subscription.payment_card_billing_address_same")}</label>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.company_address_line1")}</label>
                                    <input type={"text"} className={"form-control"} value={billingAddress1} disabled={billingAddressSame} onChange={(e) => setBillingAddress1(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.company_address_line2")}</label>
                                    <input type={"text"} className={"form-control"} value={billingAddress2} disabled={billingAddressSame} onChange={(e) => setBillingAddress2(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.company_address_city")}</label>
                                    <input type={"text"} className={"form-control"} value={billingCity} disabled={billingAddressSame} onChange={(e) => setBillingCity(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.company_address_county")}</label>
                                    <input type={"text"} className={"form-control"} value={billingCounty} disabled={billingAddressSame} onChange={(e) => setBillingCounty(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("subscription.company_address_postcode")}</label>
                                    <input type={"text"} className={"form-control"} value={billingPostcode} disabled={billingAddressSame} onChange={(e) => setBillingPostcode(e.target.value)} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} disabled={paymentNetworkInFlight || !stripe || !paymentDigestID}>{Rosetta.string("subscription.payment_submit")}</button>
                </div>
            </div>
        </form>
    );

    if (awaitConfirmation) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>

                <div className={"col-12 text-center mt-4"}>
                    {Rosetta.string("subscription.payment_waiting_message")}
                </div>
            </div>
        )
    }

    return (
        <div className={"animate-screen-content subscription-payment-component"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            {mainContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}