import Rosetta from "../../../rosetta/Rosetta";

import "./ToolboxVideoListComponent.css";

import iconPlay from "../../../assets/images/play.svg";
import iconEdit from "../../../assets/images/edit.svg";
import iconDelete from "../../../assets/images/delete.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import {HealthSafety} from "../../../util/HealthSafety";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {HealthSafetyVideoEditorModal} from "./video/HealthSafetyVideoEditorModal";
import {Toast} from "../../toast/TokyoToaster";
import {VideoPlayerModal} from "../../videoplayer/VideoPlayerModal";
import {EventUtil} from "../../../util/EventUtil";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const ToolboxVideoListComponent = () => {

    const [videos, setVideos] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [videoEditorShown, setVideoEditorShown] = useState(false);
    const [videoEditorVideoID, setVideoEditorVideoID] = useState(undefined);

    const [page, setPage] = useState(1);
    const [videosNetworkInFlight, setVideosNetworkInFlight] = useState(false);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    const [videoModalShown, setVideoModalShown] = useState(false);
    const [videoModalPath, setVideoModalPath] = useState(null);

    useEffect(() => {
        fetchVideosFromNetwork();
    }, []);

    useEffect(() => {
        fetchVideosFromNetwork();
    }, [page]);

    function showVideoEditor(id) {
        setVideoEditorVideoID(id);
        setVideoEditorShown(true);
    }

    function showVideoPlayer(video) {
        setVideoModalPath(video.filePath);
        setVideoModalShown(true);
    }

    function videoEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setVideoEditorShown(false);
            if (data) {
                // replaceVideo(data);
                invalidateList();
            }
        }
    }

    function requestVideoDeletion(videoID) {
        AlertModal.showModal(
            Rosetta.string("health_safety.video_delete_prompt_title"),
            Rosetta.string("health_safety.video_delete_prompt_message"),
            [
                AlertModal.button(Rosetta.string("common.delete"), () => {
                    AlertModal.dismissModal();
                    deleteVideoOverNetwork(videoID);
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function deleteVideoOverNetwork(videoID) {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            healthSafetyVideoID : videoID
        }

        Axios.post(ENDPOINTS.projectHealthSafety.deleteHealthSafetyVideo, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("health_safety.video_delete_prompt_confirm"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    invalidateList();
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "HSVD1000C"}));
                setDeleteNetworkInFlight(false);
            });
    }

    function replaceVideo(video) {
        let videoArray = [...videos];
        for (let i = 0; i < videoArray.length; i++) {
            if (parseInt(videoArray[i].id) === parseInt(video.id)) {
                videoArray[i] = video;
                break;
            }
        }
        setVideos(videoArray);
    }

    function invalidateList() {
        setVideos([]);
        setPage(1);
        setTotalCount(0);
        fetchVideosFromNetwork(true);
    }

    function videoModalDidCallback(action, data) {
        if (action === "dismiss") {
            setVideoModalShown(false);
        }
    }

    function fetchVideosFromNetwork(resetList) {
        if (!resetList) resetList = false;
        if (videosNetworkInFlight) return;
        setVideosNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            videoTypeID : [HealthSafety.videoType.TOOLBOX],
            page,
            pageSize : 20
        };

        Axios.post(ENDPOINTS.projectHealthSafety.getProjectHealthSafetyVideos, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let curVideos = [];
                    if (!resetList) {
                        curVideos = [...videos];
                    }
                    for (let i = 0; i < resp.data.videos.length; i++) {
                        curVideos.push(resp.data.videos[i]);
                    }
                    setVideos(curVideos);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setVideosNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setVideosNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "HSTV1000C"}));
            });
    }

    let videoElems = [];
    let moreButton = [];

    if (videos.length > 0) {
        videoElems = videos.map((video) => (
            <div className={"col-6 col-md-4 col-lg-3 video-item mt-2"}>
                <div className={"ratio ratio-16x9 video-thumbnail"} style={{backgroundImage : ImageUtil.background(video.thumbPath)}}>
                    <div className={"action-container"} onClick={() => showVideoPlayer(video)} style={{backgroundImage : ImageUtil.background(iconPlay)}}>

                        <div className={"action-stack"}>
                            <div className={"action-button"} style={{backgroundImage : ImageUtil.background(iconEdit)}} onClick={(e) => { EventUtil.cancel(e);  showVideoEditor(video.id); }} />
                            <div className={"action-button danger"} style={{backgroundImage : ImageUtil.background(iconDelete)}} onClick={(e) => { EventUtil.cancel(e); requestVideoDeletion(video.id); }} />
                        </div>

                    </div>
                </div>

                <div className={"video-info"}>
                    <div className={"video-title"}>{video.title}</div>
                    <div className={"video-subtitle"}>{video.description}</div>
                </div>
            </div>
        ))
    } else if (!videosNetworkInFlight) {
        videoElems = (
            <div className={"col-12"}>
                <div className={"empty-message"}>
                    {Rosetta.string("health_safety.video_empty")}
                </div>
            </div>
        )
    }

    if (videosNetworkInFlight) {
        for (let i = 0; i < 4; i++) {
            videoElems.push(
                <div className={"col-6 col-md-4 col-lg-3 video-item mt-2"}>
                    <div className={"ratio ratio-16x9 video-thumbnail"} />

                    <div className={"video-info"}>
                        <div className={"video-title mt-1"}>
                            <PlaceholderText />
                        </div>
                        <div className={"video-subtitle mt-1"}>
                            <PlaceholderText />
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        if (videos.length < totalCount) {
            moreButton = (
                <div className={"col-12 mt-4 text-center"}>
                    <div className={"btn btn-outline-primary"} onClick={() => setPage(page + 1)}>{Rosetta.string("common.load_more")}</div>
                </div>
            )
        }
    }

    return (
        <>
            <div className={"animate-screen-content toolbox-video-list-component"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8"}>
                        <h3>{Rosetta.string("health_safety.tab_toolbox_talks")}</h3>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showVideoEditor(undefined)}>{Rosetta.string("health_safety.video_upload")}</button>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    {videoElems}
                    {moreButton}
                </div>
            </div>

            <HealthSafetyVideoEditorModal
                shown={videoEditorShown}
                videoID={videoEditorVideoID}
                typeID={HealthSafety.videoType.TOOLBOX}
                callback={videoEditorDidCallback} />

            <VideoPlayerModal
                shown={videoModalShown}
                src={videoModalPath}
                callback={videoModalDidCallback} />
        </>
    )

}