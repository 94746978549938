import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {Navigator} from "../../../util/Navigator";
import {Toast} from "../../toast/TokyoToaster";
import {FilterText} from "../../filter/FilterText";

export const SnagsListComponent = (props) => {

    const {mode} = props;

    const [snags, setSnags] = useState([]);
    const [snagCount, setSnagCount] = useState(0);

    const [filterKeyword, setFilterKeyword] = useState("");
    const [filterDate, setFilterDate] = useState(null);

    const [page, setPage] = useState(1);
    const [snagNetworkInFlight, setSnagNetworkInFlight] = useState(false);

    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchSnagsFromNetwork();
    }, []);

    useEffect(() => {
        setSnags([]);
        setSnagCount(0);
        setPage(1);
        setSnagNetworkInFlight(false);

        fetchSnagsFromNetwork();
    }, [mode]);

    useEffect(() => {
        fetchSnagsFromNetwork()
    }, [page, filterKeyword, filterDate]);

    function tableRowWasClicked(row) {
        if (row !== undefined) {
            Navigator.navigate("/snagging/snag/" + row.id);
        }
    }

    function fetchSnagsFromNetwork() {
        if (snagNetworkInFlight) return;
        setSnagNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const formData = {
            projectID : (project ? project.id : undefined),
            mode,
            page,
            pageSize : 20
        };

        if (filterDate != null) {
            const filterChronos = Chronos.parse(filterDate);

            if (filterChronos.isValid()) {
                formData.startDate = filterChronos.startOfDay().seconds();
                formData.endDate = filterChronos.endOfDay().seconds();
            }
        }

        if (filterKeyword && filterKeyword !== "") {
            formData.keyword = filterKeyword;
        }

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawingSnags, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSnags(resp.data.snags);
                    setSnagCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
                setSnagNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSnagNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PJST1000C"}));
            });
    }

    function exportSnagsOverNetwork() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const formData = {
            projectID : (project ? project.id : undefined),
            mode
        };

        Axios.post(ENDPOINTS.projectDrawing.exportProjectDrawingSnags, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.downloadUri) {
                        window.location.href = resp.data.downloadUri;
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("document_collection.export_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    } else {
                        AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "SLC5001C"}));
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "SLC5000C"}));
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    let exportButton = (<button className={"btn btn-primary"} onClick={() => exportSnagsOverNetwork()}>{Rosetta.string("snagging.action_export")}</button>);
    if (exportNetworkInFlight) {
        exportButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("snagging.action_exporting")}</button>);
    }

    return (
        <div>
            <div className={"row mt-4"}>
                <div className={"col-12 col-md-4"}>
                    <input type={"date"} className={"form-control"} value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
                </div>
                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>

                <div className={"col-12 col-md-4 text-end"}>
                    {exportButton}
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={snags}
                        placeholderMode={snagNetworkInFlight}
                        placeholderRows={5}
                        onRowClick={tableRowWasClicked}>

                            <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                            <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                            <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                            )} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={page}
                        totalCount={snagCount}
                        pageSize={20}
                        onClick={(page) => setPage(page)} />
                </div>
            </div>
        </div>
    )

}