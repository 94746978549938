import React from 'react';

import "./SegmentTabBar.css";

export class SegmentTab extends React.Component {}

export const SegmentTabBar = (props) => {

    function handleCallback(id) {
        if (props.callback !== undefined) {
            props.callback("select", id);
        }
    }

    // TODO Convert to children?
    let tabs = [];

    if (props.children !== undefined) {
        React.Children.forEach(props.children, (child) => {
            console.log("TAB: " + child.type + " :: " + (child.type === SegmentTab ? "Y" : "N"));
            if (child.type === SegmentTab) {
                let classExtra = "";
                let badgeClass = " bg-warning";

                if (child.props.id !== undefined && props.selected !== undefined) {
                    if (child.props.id === props.selected) {
                        classExtra = " active";
                        badgeClass = " bg-dark";
                    }
                }

                let badgeElem = [];
                if (child.props.hasOwnProperty("badge") && child.props.badge > 0) {
                    badgeElem = (
                        <span className={"badge" + badgeClass}>{child.props.badge}</span>
                    )
                }

                tabs.push(
                    <span className={"segment-tab" + classExtra} onClick={() => handleCallback(child.props.id)}>
                        {child.props.label} {badgeElem}
                    </span>
                );
            }
        });
    }

    return (
        <div className={"segment-tab-bar"}>
            <div className={"segment-container"}>
                {tabs}
            </div>
        </div>
    )


}