import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";

export const ForgottenPasswordModal = (props) => {

    const {shown} = props;

    const [emailAddress, setEmailAddress] = useState("");
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (!shown) {
            setErrorMessage("");
            setSubmitNetworkInFlight(false);
            setForceDismiss(false);
            setErrorMessage(null);
        }
    }, [shown])

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function submitForgottenPasswordOverNetwork() {
        if (submitNetworkInFlight) return;

        if (emailAddress === "") {
            setErrorMessage(Rosetta.string("login.forgotten_password_error_validation"));
            return;
        }

        setSubmitNetworkInFlight(true);
        setErrorMessage(null);

        let formData = {
            emailAddress
        };

        Axios.post(ENDPOINTS.auth.requestUserPasswordReset, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForceDismiss(true);
                    AlertModal.showModal(
                        Rosetta.string("login.forgotten_password"),
                        resp.data.message
                    );
                } else {
                    setErrorMessage(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                setErrorMessage(Rosetta.string("common.error_common_unknown", {error_code : "FPR10001"}));
            });
    }

    if (!shown) return [];

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitForgottenPasswordOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (submitNetworkInFlight) {
        submitButton = (<LoadingSpinner small={true} inline={true} />);
    }

    let errorElem = [];
    if (errorMessage !== null) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{errorMessage}</div>
                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("login.forgotten_password")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("login.forgotten_password_message")}
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("login.forgotten_password_email")}</label>
                    <input type={"text"} className={"form-control"} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

            {errorElem}

        </Offcanvas>
    )

}