import {useEffect, useState} from "react";
import WindowUtil from "../../util/WindowUtil";
import {ImageUtil} from "../../util/ImageUtil";

import "./Lightbox.css";

import closeIcon from "../../assets/images/close.svg";

export const LightboxImage = (imageUrl, thumbUrl, caption) => {
    return {
        imageUrl,
        thumbUrl,
        caption
    };
}

export const Lightbox = (props) => {

    const {shown} = props;
    const {images} = props;
    const {initialImage} = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDismissing, setIsDismissing] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (initialImage !== undefined && images !== undefined) {
                let matchedIndex = getIndexForImage(initialImage);
                if (matchedIndex >= 0) {
                    setCurrentIndex(matchedIndex);
                }
            }
        } else {
            WindowUtil.unlockBodyScroll();
            setCurrentIndex(0);
        }
    }, [shown]);

    useEffect(() => {
        let dismissTimeout = undefined;
        if (isDismissing) {
            dismissTimeout = setTimeout(() => {
                handleCallback("close");
                setIsDismissing(false);
            }, 400);
        }

        return () => {
            clearTimeout(dismissTimeout);
        }
    }, [isDismissing]);

    function requestDismiss() {
        if (!isDismissing) {
            setIsDismissing(true);
        }
    }

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    function thumbnailWasClicked(imageUrl) {
        let index = getIndexForImage(imageUrl);
        setCurrentIndex(index);
    }

    function getIndexForImage(image) {
        for (let i = 0; i < images.length; i++) {
            if (images[i] === image || (images[i].hasOwnProperty("imageUrl") && images[i].imageUrl === image)) {
                return i;
            }
        }
        return -1;
    }

    if (!shown) return [];

    let currentImagePath = null;
    let thumbnails = [];

    if (images !== undefined && images !== null) {
        if (currentIndex < images.length) {
            if (images[currentIndex].hasOwnProperty("imageUrl")) {
                currentImagePath = images[currentIndex].imageUrl;
            } else if (images[currentImagePath]) {
                currentImagePath = images[currentImagePath];
            }
        }

        for (let i = 0; i < images.length; i++) {
            let classExtra = "";
            if (i === currentIndex) {
                classExtra = " active";
            }

            let imageUrl = images[i];
            if (images[i].hasOwnProperty("thumbUrl") && images[i].thumbUrl) {
                imageUrl = images[i].thumbUrl;
            } else if (images[i].hasOwnProperty("imageUrl") && images[i].imageUrl) {
                imageUrl = images[i].imageUrl;
            }

            thumbnails.push(
                <span className={"image-thumbnail thumbnail" + classExtra} onClick={() => thumbnailWasClicked(images[i])} style={{backgroundImage : ImageUtil.background(imageUrl)}} />
            )
        }
    }

    let lightboxKey = "tlb_o";
    let lightboxClass = "";
    if (isDismissing) {
        lightboxKey = "tlb_d";
        lightboxClass = " dismissing";
    }

    return (
        <div className={"tokyo-lightbox" + lightboxClass} key={lightboxKey}>
            <div className={"image-display"} key={"image_" + currentIndex} style={{backgroundImage : ImageUtil.background(currentImagePath)}} />
            <div className={"image-thumbnails"}>
                {thumbnails}
            </div>

            <div className={"close"} onClick={requestDismiss} style={{backgroundImage : ImageUtil.background(closeIcon)}} />
        </div>
    )
}