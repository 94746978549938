import {FilterText} from "../../filter/FilterText";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {StringUtil} from "../../../util/StringUtil";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";

import "./ReportListComponent.css";

export const ReportListComponent = () => {

    const REPORT_TYPE_LIVE = "live";
    const REPORT_TYPE_RESOLVED = "resolved";

    const [liveReports, setLiveReports] = useState([]);
    const [liveTotal, setLiveTotal] = useState(0);

    const [resolvedReports, setResolvedReports] = useState([]);
    const [resolvedTotal, setResolvedTotal] = useState(0);

    const [liveNetworkInFlight, setLiveNetworkInFlight] = useState(false);
    const [resolvedNetworkInFlight, setResolvedNetworkInFlight] = useState(false);

    const [livePage, setLivePage] = useState(1);
    const [resolvedPage, setResolvedPage] = useState(1);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchReportsFromNetwork(REPORT_TYPE_LIVE);
        fetchReportsFromNetwork(REPORT_TYPE_RESOLVED);
    }, []);

    useEffect(() => {
        setLivePage(1);
        setResolvedPage(1);
        fetchReportsFromNetwork(REPORT_TYPE_LIVE);
        fetchReportsFromNetwork(REPORT_TYPE_RESOLVED);
    }, [filterKeyword]);

    useEffect(() => {
        fetchReportsFromNetwork(REPORT_TYPE_LIVE);
    }, [livePage]);

    useEffect(() => {
        fetchReportsFromNetwork(REPORT_TYPE_RESOLVED);
    }, [resolvedPage]);

    function onRowClicked(row) {
        if (row) {
            Navigator.navigate("/healthSafety/report/" + row.id);
        }
    }

    function fetchReportsFromNetwork(type) {
        if (type === REPORT_TYPE_LIVE) {
            if (liveNetworkInFlight) return;
            setLiveNetworkInFlight(true);
        } else {
            if (resolvedNetworkInFlight) return;
            setResolvedNetworkInFlight(true);
        }

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            unapprovedOnly : (type === REPORT_TYPE_LIVE),
            page : (type === REPORT_TYPE_LIVE ? livePage : resolvedPage),
            pageSize : 20
        };

        Axios.post(ENDPOINTS.projectHealthSafety.getReports, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (type === REPORT_TYPE_LIVE) {
                        setLiveReports(resp.data.reports);
                        setLiveTotal(resp.data.count);
                    } else {
                        setResolvedReports(resp.data.reports);
                        setResolvedTotal(resp.data.count);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }

                if (type === REPORT_TYPE_LIVE) {
                    setLiveNetworkInFlight(false);
                } else {
                    setResolvedNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "RL1000C"}));
                if (type === REPORT_TYPE_LIVE) {
                    setLiveNetworkInFlight(false);
                } else {
                    setResolvedNetworkInFlight(false);
                }
            })
    }

    const sharedTabs = [
        <Column name={"firstName"} title={Rosetta.string("health_safety.report_table_author")} render={(data, row) => (
            <>{Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.surname })}</>
        )} />,
        <Column name={"dateCreated"} title={Rosetta.string("health_safety.report_table_date")} render={(data, row) => (
            <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
        )} />,
        <Column name={"projectLocationName"} title={Rosetta.string("health_safety.report_table_location")} />,
        <Column name={"description"} title={Rosetta.string("health_safety.report_table_description")} render={(data, row) => (
            <>{StringUtil.ellipsise(data, 40)}</>
        )} />
    ]

    return (
        <div className={"report-list-screen mt-4"}>
            <div className={"animate-screen-contents"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-8"}>
                        <h3>{Rosetta.string("health_safety.tab_reported_issues")}</h3>
                    </div>
                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterKeyword} />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("health_safety.report_open_title")}</h3>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                data={liveReports}
                                className={"clickable"}
                                placeholderMode={liveNetworkInFlight}
                                placeholderRows={5}
                                onRowClick={onRowClicked}>

                                {sharedTabs}

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={livePage}
                                totalCount={liveTotal}
                                pageSize={20}
                                onClick={setLivePage} />
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("health_safety.report_closed_title")}</h3>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                data={resolvedReports}
                                className={"clickable"}
                                placeholderMode={resolvedNetworkInFlight}
                                placeholderRows={5}
                                onRowClick={onRowClicked}>

                                {sharedTabs}

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={resolvedPage}
                                totalCount={resolvedTotal}
                                pageSize={20}
                                onClick={setResolvedPage} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )

}