import logoGraphic from "../../assets/images/logo_graphic_2.svg";
import {ImageUtil} from "../../util/ImageUtil";

import "./LoadingSpinner.css";

export const LoadingSpinner = (props) => {

    let classExtra = "";
    if (props.inline && props.inline === true) {
        classExtra = " inline";
    }

    if (props.small && props.small === true) {
        classExtra += " small";
    }

    return (
        <div className={"loading-spinner" + classExtra}>
            <div className={"spinner-spin"} style={{backgroundImage : ImageUtil.background(logoGraphic)}} />
        </div>
    )

}