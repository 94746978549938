import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../../rosetta/Rosetta";
import {DataManager} from "../../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";

import {Toast} from "../../../toast/TokyoToaster";
import Validator from "../../../../util/Validator";

import "./InductionFileUploadModal.css";
import WindowUtil from "../../../../util/WindowUtil";

export const InductionFileUploadTypes = {
    DOCUMENT : 1,
    VIDEO : 2
}

export const InductionFileUploadModal = (props) => {

    const {shown} = props;
    const {type} = props;

    const videoInput = useRef();

    const [FilePath, setFilePath] = useState(undefined);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(null);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [uploadFile, setUploadFile] = useState(undefined);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            if (!type) {
                console.log("WARNING! TYPE NOT SET!");
            }

            setSubmitNetworkInFlight(false);
            setFilePath(undefined);
            setTitle("");
            setDescription("");
            setForceDismiss(false);
            setUploadProgress(0);

            setUploadFile(undefined);
            setError(null);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function videoInputDidChange(e) {
        if (e.target.files.length > 0) {
            let video = e.target.files[0];
            setUploadFile(video);
            setFilePath(video.name);
        }
    }

    function validateForm() {
        let rules = [
            Validator.rule("type", "int", "X", "type"),
            Validator.rule("title", "string", Rosetta.string("health_safety.video_editor_title"), "title"),
            Validator.rule("description", "string", Rosetta.string("health_safety.video_editor_description"), "description"),
            Validator.rule("uploadFile", "file", Rosetta.string("health_safety.video_editor_file"), "file")
        ];

        const validationResult = Validator.validateCreateFormData({
            title,
            type,
            description,
            uploadFile
        }, rules);

        if (!validationResult.success) {
            setError(validationResult.error);
            return false;
        }

        setError(null);
        return true;
    }

    function submissionDidComplete() {
        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string(""),
            Toast.SUCCESS,
            Toast.LONG
        );

        handleCallback("close", true);
    }

    function submitVideoOverNetwork() {
        if (submitNetworkInFlight) return;

        if (!validateForm()) {
            return;
        }

        setSubmitNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = new FormData();
        formData.append("type", type);
        formData.append("projectID", (project ? project.id : undefined));
        formData.append("name", title);
        formData.append("description", description);
        formData.append("file", uploadFile);

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.projectInduction.uploadInductionFile, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    submissionDidComplete();
                } else {
                    showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_uncoded"));
            });
    }

    function showError(message) {
        setError(message);
    }

    if (!shown) return [];

    let submitProgress = [];
    if (submitNetworkInFlight) {
        submitProgress = (
            <div className={"row mt-2"}>
                <div className={"progress-bar"}>
                    <div className={"progress"} style={{width : uploadProgress + "%"}} />
                </div>
            </div>
        )
    } else {
        submitProgress = (
            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => submitVideoOverNetwork()}>{Rosetta.string("common.submit")}</button>
                </div>
            </div>
        );
    }

    let errorElem = [];
    if (error) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>{error}</div>
                </div>
            </div>
        );
    }

    let offcanvasTitle = "health_safety.induction_file_upload_document";
    let inputAccepts = "application/pdf";
    if (type === InductionFileUploadTypes.VIDEO) {
        offcanvasTitle = "health_safety.induction_file_upload_video";
        inputAccepts = "video/mp4";
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string(offcanvasTitle)}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"health-safety-video-editor"}>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.induction_file_upload_file")}</label>
                        <div className={"file-hide"}><input type={"file"} accept={inputAccepts} onChange={videoInputDidChange} ref={videoInput} /></div>
                        <div className={"file-selection-area"}>
                            <span className={"button-area"}>
                                <button className={"btn btn-outline-primary"} onClick={() => videoInput.current.click()}>{Rosetta.string("health_safety.induction_file_upload_select")}</button>
                            </span>

                            <div className={"info-area"}>
                                <div className={"filepath"}>{(FilePath ? FilePath : Rosetta.string("health_safety.video_editor_file_none"))}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.induction_file_upload_title")}</label>
                        <input type={"text"} className={"form-control"} value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("health_safety.induction_file_upload_description")}</label>
                        <textarea className={"form-control"} value={description} onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <hr />
                    </div>
                </div>

                {submitProgress}

                {errorElem}

            </div>

        </Offcanvas>
    )

}