import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";
import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import {Toast} from "../../toast/TokyoToaster";
import {EventUtil} from "../../../util/EventUtil";

export const ProjectListScreen = (props) => {

    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [projectsCount, setProjectsCount] = useState(0);
    const [projectNetworkInFlight, setProjectNetworkInFlight] = useState(false);

    const [mode, setMode] = useState(1);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, []);

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, [filterKeyword, page, mode]);

    function moveToEditor(id) {
        if (!id) {
            id = "new";
        }
        Navigator.navigate("/project/" + id);
    }

    function tableRowWasClicked(row) {
        if (row) {
            moveToEditor(row.id);
        }
    }

    function promptProjectArchive(project, status) {
        let title = "project.action_archive_title";
        let message = "project.action_archive_message";
        let buttonLabel = "project.action_archive";
        let buttonClass = "danger";
        if (status === 1) {
            title = "project.action_activate_title";
            message = "project.action_activate_message";
            buttonLabel = "project.action_activate";
            buttonClass = "success";
        }

        AlertModal.showModal(
            Rosetta.string(title),
            Rosetta.string(message, { project_name : project.project_name }),
            [
                AlertModal.button(Rosetta.string(buttonLabel), () => {
                    submitProjectActiveOverNetwork(project.id, status);
                    AlertModal.dismissModal();
                }, buttonClass),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        )
    }

    function fetchProjectsFromNetwork() {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            keyword : filterKeyword,
            page,
            mode
        };

        Axios.post(ENDPOINTS.project.getProjects, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setProjects(resp.data.projects);
                    setProjectsCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setProjectNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "UP1000C"}));
            });
    }

    function submitProjectActiveOverNetwork(projectID, status) {
        let formData = {
            projectID,
            status
        };

        Axios.post(ENDPOINTS.project.setProjectArchiveStatus, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let message = "project.action_archive_success";
                    if (status === 1) {
                        message = "project.action_activate_success";
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(message),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchProjectsFromNetwork();
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "PAS1000C"}));
            });
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("system_settings.option_projects")} />
                </div>

                <div className={"col-12 col-md-4 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => moveToEditor()}>{Rosetta.string("project.add_project")}</button>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-2 align-items-center"}>

                    <div className={"col-12 col-xl-4 mt-2"}>
                        <FilterText callback={setFilterKeyword} />
                    </div>

                    <div className={"col-12 col-xl-4"} />

                    <div className={"col-12 col-xl-4 mt-2 text-end"}>
                        <SegmentTabBar selected={mode} callback={(action, data) => setMode(data)}>
                            <SegmentTab id={1} label={Rosetta.string("project.mode_active")} />
                            <SegmentTab id={0} label={Rosetta.string("project.mode_archived")} />
                        </SegmentTabBar>
                    </div>

                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={projects}
                                placeholderMode={projectNetworkInFlight}
                                placeholderRows={10}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"project_name"} title={Rosetta.string("project.table_name")} />
                                <Column name={"project_code"} title={Rosetta.string("project.table_code")} />
                                <Column name={"address1"} title={Rosetta.string("project.table_address")} />
                                <Column name={"town"} title={Rosetta.string("project.table_town")} />
                                <Column name={"postcode"} title={Rosetta.string("project.table_postcode")} />
                                <Column name={"active"} title={""} className={"text-end"} render={(data, row) => {
                                    let buttons = [
                                        <button className={"btn btn-outline-primary me-1"}>{Rosetta.string("common.view")}</button>
                                    ];

                                    if (parseInt(row.active) === 1) {
                                        buttons.push(
                                            <button className={"btn btn-primary"} onClick={(e) => { EventUtil.cancel(e); promptProjectArchive(row, 0) }}>{Rosetta.string("project.action_archive")}</button>
                                        )
                                    } else {
                                        buttons.push(
                                            <button className={"btn btn-primary"} onClick={(e) => { EventUtil.cancel(e); promptProjectArchive(row, 1) }}>{Rosetta.string("project.action_activate")}</button>
                                        )
                                    }

                                    return buttons;
                                }} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={projectsCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}