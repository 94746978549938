import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {Chronos} from "../../../chronos/Chronos";

import "./ProjectDocumentHistoryModal.css";
import WindowUtil from "../../../util/WindowUtil";

export const ProjectDocumentHistoryModal = (props) => {

    const [history, setHistory] = useState([]);
    const [historyNetworkInFlight, setHistoryNetworkInFlight] = useState(false);

    useEffect(() => {
        if (props.documentTypeID !== null) {
            fetchHistoryFromNetwork();
            WindowUtil.lockBodyScroll();
        } else {
            setHistory([]);
            WindowUtil.unlockBodyScroll();
        }
    }, [props.documentTypeID]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    function fetchHistoryFromNetwork() {
        if (historyNetworkInFlight) return;
        setHistoryNetworkInFlight(true);

        let formData = {
            projectID : props.projectID,
            documentTypeID : props.documentTypeID
        }

        Axios.post(ENDPOINTS.project.getProjectDocumentHistory, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setHistory(resp.data.history);
                }
                setHistoryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setHistoryNetworkInFlight(false);
            });
    }

    function formatDate(date) {
        return Chronos.withTimestampSeconds(date).format("yyyy, MMMM dd");
    }

    if (props.documentTypeID === null) return[];

    let listItems = [];
    if (!historyNetworkInFlight) {
        if (history.length > 0) {
            listItems = history.map((item) => (
                <div className={"list-item"}>
                    <div className={"item-date"}>{formatDate(item.uploadDate)}</div>
                    <a className={"item-title"} href={item.fileURL} target={"_blank"}>{item.fileURL}</a>
                </div>
            ))
        } else {
            listItems = (<div className={"empty-message"}>{Rosetta.string("site_information.document_history_empty")}</div>)
        }
    } else {
        for (let i = 0; i < 5; i++) {
            listItems.push(
                <div className={"list-item"}>
                    <div className={"item-date"}>
                        <PlaceholderText />
                    </div>
                    <div className={"item-title"}>
                        <PlaceholderText />
                    </div>
                </div>
            );
        }
    }

    return (
        <Offcanvas
            title={Rosetta.string("site_information.document_history_title")}
            gravity={OffcanvasGravity.END}
            shown={true}
            callback={handleCallback}>

            <div className={"project-document-history-modal"}>
                <div className={"history-list"}>
                    {listItems}
                </div>
            </div>

        </Offcanvas>
    );

}