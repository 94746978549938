import {FilterText} from "../../filter/FilterText";
import {useEffect, useState} from "react";
import {OrganiserNoteWidget} from "../organiser/OrganiserNoteWidget";
import {DataManager} from "../../../data/DataManager";
import {Chronos} from "../../../chronos/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import Rosetta from "../../../rosetta/Rosetta";

export const DiaryNotesComponent = (props) => {

    const {date} = props;

    const [notes, setNotes] = useState([]);
    const [noteNetworkInFlight, setNoteNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchNotesFromNetwork();
    }, []);

    useEffect(() => {
        fetchNotesFromNetwork();
    }, [date, filterKeyword]);

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    function fetchNotesFromNetwork() {
        if (noteNetworkInFlight) return;
        setNoteNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const chronosDate = Chronos.with(date);
        let startDate = null;
        let endDate = null;
        if (chronosDate && chronosDate.isValid()) {
            startDate = chronosDate.startOfDay().seconds();
            endDate = chronosDate.endOfDay().seconds();
        }

        let formData = {
            projectID : (project ? project.id : undefined),
            keyword : filterKeyword,
            startDate,
            endDate
        };

        Axios.post(ENDPOINTS.personal.getUserNotes, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setNotes(resp.data.notes);
                } else {
                    showError(API.formatError(resp));
                }
                setNoteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "DPN1000C"}));
            });
    }

    let noteElems = [];
    if (!noteNetworkInFlight) {
        if (notes.length > 0) {
            noteElems = notes.map((note) => (
                <div className={"col-12 col-md-6 mt-2"}>
                    <OrganiserNoteWidget
                        note={note}
                        showActions={false} />
                </div>
            ))
        } else {
            noteElems = (
                <div className={"col-12 mt-4"}>
                    <div className={"empty-message"}>No Notes to display</div>
                </div>
            )
        }
    } else {
        for (let i = 0; i < 5; i++) {
            noteElems.push(
                <div className={"col-12 col-md-6 mt-2"}>
                    <OrganiserNoteWidget />
                </div>
            );
        }
    }

    return (
        <div className={"animate-screen-content diary-health-safety-component"}>
            <div className={"row mt-4"}>

                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                {noteElems}
            </div>

        </div>
    )

}