import {CommonUtil} from "../../../util/CommonUtil";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {PlaceholderBlock} from "../../placeholder/PlaceholderBlock";
import Rosetta from "../../../rosetta/Rosetta";

import "./DocumentWidget.css";

import fileIcon from "../../../assets/images/file.svg";
import {ImageUtil} from "../../../util/ImageUtil";

export const DocumentWidget = (props) => {

    let document = null;
    if (props.document !== undefined) {
        document = props.document;
    }

    let filename = "";
    if (document !== null) {
        let filenameSplit = document.fileURL.split("/");
        filename = filenameSplit[filenameSplit.length - 1];
    }

    return (
        <div className={"document-widget"}>
            <div className={"widget-title"}>
                <ContentOrPlaceholder showContent={document !== null}>
                    {CommonUtil.getOrDefault(document, "title", "")}
                </ContentOrPlaceholder>
            </div>

            <ContentOrPlaceholder showContent={document !== null} placeholder={<PlaceholderBlock className={"inline widget-icon"} />}>
                <div className={"widget-icon"} style={{backgroundImage : ImageUtil.background(fileIcon)}} />
            </ContentOrPlaceholder>

            <div className={"widget-subtitle"}>
                <ContentOrPlaceholder showContent={document !== null}>
                    {filename}
                </ContentOrPlaceholder>
            </div>

            <a className={"btn btn-primary"} href={CommonUtil.getOrDefault(document, "fileURL", "#")}>
                <ContentOrPlaceholder showContent={document !== null}>
                    {Rosetta.string("common.view")}
                </ContentOrPlaceholder>
            </a>

        </div>
    );

}