import {LoadingSpinner} from "./LoadingSpinner";

import "./UIBlocker.css";

export const UIBlocker = (props) => {

    if (!props.shown) {
        return [];
    }

    return (
        <div className={"ui-blocker"}>
            <LoadingSpinner />
        </div>
    )

}