import {useParams} from "react-router-dom";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {useEffect, useState} from "react";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {ImageUtil} from "../../../util/ImageUtil";

import fileIcon from "../../../assets/images/file.svg";
import {Lightbox, LightboxImage} from "../../lightbox/Lightbox";
import {Toast} from "../../toast/TokyoToaster";

export const DocumentCaptureDetailScreen = (props) => {

    const {id} = useParams();

    const [capture, setCapture] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);

    useEffect(() => {
        getCaptureDetailFromNetwork();
    }, []);

    function onImageClicked(image) {
        if (ImageUtil.isMimeADisplayableImage(image.mime)) {
            // Launch the lightbox
            let imageCollection = [];
            for (let i = 0; i < capture.files.length; i++) {
                if (ImageUtil.isMimeADisplayableImage(capture.files[i].mime)) {
                    imageCollection.push(LightboxImage(capture.files[i].path));
                }
            }

            setSelectedImage(image.path);
            setLightboxImages(imageCollection);
            setLightboxShown(true);
        } else {
            // Not a displayable image. Open in new window.
            window.open(image.path);
        }
    }

    function lightboxDidCallback(action) {
        if (action === "close") {
            setLightboxShown(false);
        }
    }

    function printWasRequested() {
        exportDocumentOverNetwork();
    }

    function getCaptureDetailFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : null),
            documentID : id
        };

        Axios.post(ENDPOINTS.projectCollectionDocument.getCollectionDocument, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setCapture(resp.data.document);
                } else {
                    showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PDC2000C"}));
                setNetworkInFlight(false);
            });
    }

    function exportDocumentOverNetwork() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            documentID : id
        };

        Axios.post(ENDPOINTS.projectCollectionDocument.getCollectionDocumentExport, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.downloadUri) {
                        window.location.href = resp.data.downloadUri;
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("document_collection.export_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    } else {
                        AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDC8101C" }));
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDC8100C" }));
            })
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    let imageElem = [];
    if (capture && capture.files !== undefined) {
        if (capture.files.length > 0) {
            let images = [];

            for (let i = 0; i < capture.files.length; i++) {
                let file = capture.files[i];

                let imageUrl = fileIcon;

                console.log(file);

                if (file.mime === null) {
                    file.mime = ImageUtil.getMimeFromPath(file.path);
                }

                if (ImageUtil.isMimeADisplayableImage(file.mime)) {
                    imageUrl = file.path;
                }

                images.push(
                    <div className={"col-6 col-md-4 col-lg-3 mb-4"}>
                        <div
                            className="ratio ratio-1x1 image-thumbnail"
                            onClick={() => onImageClicked(file)}
                            style={{backgroundImage : ImageUtil.background(imageUrl)}} />
                    </div>
                )
            }

            imageElem = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}><h4>{Rosetta.string("document_collection.detail_images")}</h4></div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    {images}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    let exportButton = (<button className={"btn btn-primary"} onClick={() => printWasRequested()}>{Rosetta.string("document_collection.detail_print")}</button>);
    if (exportNetworkInFlight) {
        exportButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("document_collection.detail_exporting")}</button>)
    }

    return (
        <div className={"app-screen document-capture-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("document_collection.detail_title")} />
                </div>
                <div className={"col-12 col-md-4 text-end"}>
                    {exportButton}
                </div>
            </div>

            <div className={"mt-4 animate-screen-content"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}><h4>{Rosetta.string("document_collection.detail_details_title")}</h4></div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"mt-2"}>
                                            <label>{Rosetta.string("document_collection.detail_date")}</label>
                                            <ContentOrPlaceholder showContent={!networkInFlight}>
                                                <div>{(capture ? Chronos.parse(capture.dateCreated).format("dd/MM/yyyy HH:mm") : "")}</div>
                                            </ContentOrPlaceholder>
                                        </div>

                                        <div className={"mt-2"}>
                                            <label>{Rosetta.string("document_collection.detail_author")}</label>
                                            <ContentOrPlaceholder showContent={!networkInFlight}>
                                                <div>{(capture ? Rosetta.string("common.name_format", {given_name : capture.firstName, family_name : capture.surname }) : "")}</div>
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"col-12 col-md-6"}>
                                        <div className={"mt-2"}>
                                            <label>{Rosetta.string("document_collection.detail_notes")}</label>
                                            <ContentOrPlaceholder showContent={!networkInFlight}>
                                                <div>{(capture ? capture.notes : "")}</div>
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {imageElem}

            </div>

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={selectedImage}
                callback={lightboxDidCallback} />
        </div>
    )

}