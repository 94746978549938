import BaseComponent from "../../../../BaseComponent";
import Rosetta from "../../../../../rosetta/Rosetta";

export default class FormSubmissionModalComponent extends BaseComponent {

    static MODE_PROMPT = 1;
    static MODE_WAIT = 2;
    static MODE_COMPLETE = 3;
    static MODE_ERROR = 4;

    static CALLBACK_TYPE_DRAFT = "draft";
    static CALLBACK_TYPE_PUBLISH = "publish";
    static CALLBACK_TYPE_DISMISS = "dismiss";

    constructor(props, context) {
        super(props, context);

        this.initState({

        });
    }

    dispatchCallback = (type, data) => {
        if (this.props.callback !== undefined) {
            this.props.callback(type, data);
        }
    }

    render() {
        if (!this.props.shown) return [];

        let modalContent = (
            <div>
                <div className={"alert-modal-dialog-title"}>{Rosetta.string("form_save.draft_title")}</div>
                <div className={"alert-modal-dialog-message"}>{Rosetta.string("form_save.draft_message")}</div>

                <div className={"alert-modal-dialog-buttons"}>
                    <div className={"alert-dialog-button success"} onClick={() => this.dispatchCallback(FormSubmissionModalComponent.CALLBACK_TYPE_DRAFT)}>{Rosetta.string("form_save.draft_submit")}</div>
                </div>

                <hr />

                <div className={"alert-modal-dialog-title"}>{Rosetta.string("form_save.publish_title")}</div>
                <div className={"alert-modal-dialog-message"}>{Rosetta.string("form_save.publish_message")}</div>

                <div className={"alert-modal-dialog-buttons"}>
                    <div className={"alert-dialog-button success"} onClick={() => this.dispatchCallback(FormSubmissionModalComponent.CALLBACK_TYPE_PUBLISH)}>{Rosetta.string("form_save.publish_submit")}</div>
                </div>

                <hr />

                <div className={"alert-modal-dialog-buttons"}>
                    <div className={"alert-dialog-button"} onClick={() => this.dispatchCallback(FormSubmissionModalComponent.CALLBACK_TYPE_DISMISS)}>{Rosetta.string("common.cancel")}</div>
                </div>

            </div>
        );

        if (this.props.mode === FormSubmissionModalComponent.MODE_WAIT) {
            modalContent = (
                <div>
                    <div className={"alert-modal-dialog-title"}>{Rosetta.string("form_save.title")}</div>

                    <div className={"text-center mt-4"}>
                        Loading...
                    </div>

                    <div className={"alert-modal-dialog-message"}>{Rosetta.string("form_save.please_wait")}</div>
                </div>
            )
        } else if (this.props.mode === FormSubmissionModalComponent.MODE_COMPLETE) {
            let messageRes = "complete_draft";
            if (this.props.isPublishing === true) {
                messageRes = "complete_publish";
            }

            modalContent = (
                <div>
                    <div className={"alert-modal-dialog-title"}>{Rosetta.string("form_save.title")}</div>

                    <div className={"alert-modal-dialog-message"}>{Rosetta.string("form_save." + messageRes)}</div>

                    <div className={"alert-modal-dialog-buttons"}>
                        <div className={"alert-dialog-button"} onClick={() => this.dispatchCallback(FormSubmissionModalComponent.CALLBACK_TYPE_DISMISS)}>{Rosetta.string("form_save.continue")}</div>
                    </div>
                </div>
            )
        } else if (this.props.mode === FormSubmissionModalComponent.MODE_ERROR) {
            modalContent = (
                <div>
                    <div className={"alert-modal-dialog-title"}>{Rosetta.string("form_save.title")}</div>

                    <div className={"alert-modal-dialog-message"}>{this.props.error}</div>

                    <div className={"alert-modal-dialog-buttons"}>
                        <div className={"alert-dialog-button"} onClick={() => this.dispatchCallback(FormSubmissionModalComponent.CALLBACK_TYPE_DISMISS)}>{Rosetta.string("common.ok")}</div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"form-submission-modal-component"}>
                <div className={"alert-modal"}>
                    <div className={"spacer"} />

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"hidden-xs col-md-3 col-lg-4"} />
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <div className={"alert-modal-dialog"}>

                                    {modalContent}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />
                </div>
            </div>
        )
    }
}