import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import AlertModal from "../../alertmodal/AlertModal";
import {API, ENDPOINTS} from "../../../network/API";
import {CommonUtil} from "../../../util/CommonUtil";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";
import Axios from "axios";

export const SubscriptionUserDetailsComponent = (props) => {

    const {step} = props;
    const {nextLabel} = props;
    const {formData} = props;

    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberSame, setPhoneNumberSame] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordRetype, setPasswordRetype] = useState("");

    const [checkEmailNetworkInFlight, setCheckEmailNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    useEffect(() => {
        if (phoneNumberSame) {
            if (formData.companyPhone !== undefined) {
                setPhoneNumber(formData.companyPhone);
            }
        } else {
            setPhoneNumber("");
        }
    }, [phoneNumberSame])

    function handleCallback(action, submit) {
        if (props.callback !== undefined) {
            props.callback(action, submit);
        }
    }

    function submitForm(passedEmailCheck) {
        if (passedEmailCheck === undefined) {
            passedEmailCheck = false;
        }

        if (!passedEmailCheck) {
            checkEmailInUseOverNetwork();
            return;
        }

        const requiredFields = [
            { keyName : "userFirstName", data : firstName, label : Rosetta.string("subscription.subscription_user_first_name" )},
            { keyName : "userSurname", data : surname, label : Rosetta.string("subscription.subscription_user_surname" )},
            { keyName : "userEmailAddress", data : emailAddress, label : Rosetta.string("subscription.subscription_user_email") },
            { keyName : "userPhoneNumber", data : phoneNumber, label : Rosetta.string("subscription.subscription_user_phone_number" )},
            { keyName : "userPassword", data : password, label : Rosetta.string("subscription.subscription_user_password" )},
            { keyName : "userPasswordRetype", data : passwordRetype, label : Rosetta.string("subscription.subscription_user_password_retype" )},
        ];

        let outData = {
            step
        };

        for (let i = 0; i < requiredFields.length; i++) {
            const field = requiredFields[i];
            if (field.data !== "") {
                outData[field.keyName] = field.data;
            } else {
                AlertModal.showError(Rosetta.string("validation.missing_property", {label : field.label}));
                return;
            }
        }

        if (password !== passwordRetype) {
            AlertModal.showError(Rosetta.string("subscription.success_error_password_mismatch"));
            return;
        }

        if (formData.subscriptionSku === undefined) {
            submitRegistrationOverNetwork(outData);
        } else {
            handleCallback("submit", outData);
        }
    }

    function checkEmailInUseOverNetwork() {
        if (checkEmailNetworkInFlight) return;
        setCheckEmailNetworkInFlight(true);

        const data = {
            emailAddress
        };

        console.log(ENDPOINTS.subscription.checkEmailInUse);
        Axios.post(ENDPOINTS.subscription.checkEmailInUse, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (!resp.data.emailInUse) {
                        // We're ok! Now submit the form again.
                        submitForm(true);
                    } else {
                        AlertModal.showError(Rosetta.string("subscription.email_in_use"));
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setCheckEmailNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setCheckEmailNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.common_error_unknown", { error_code : "CHKEMAIL1000C" }));
            })
    }

    function submitRegistrationOverNetwork(outData) {
        if (submitNetworkInFlight) return;
        setSubmitNetworkInFlight(true);

        let newFormData = CommonUtil.mergeObjects(formData, outData);

        SubscriptionUtil.submitSubscription(newFormData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    handleCallback("submit", outData);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBD1000C" }));
            })
    }

    return (
        <div className={"animate-screen-content subscription-company-details-component"}>
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_first_name")}</label>
                                    <input type={"text"} className={"form-control"} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_surname")}</label>
                                    <input type={"text"} className={"form-control"} value={surname} onChange={(e) => setSurname(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_email")}</label>
                                    <input type={"text"} className={"form-control"} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_phone_number")}</label>
                                    <input type={"text"} className={"form-control"} name={"phone-number"} value={phoneNumber} disabled={phoneNumberSame} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    <div><label>&nbsp;</label></div>
                                    <label><input type={"checkbox"} checked={phoneNumberSame} onChange={(e) => setPhoneNumberSame(e.target.checked)} /> {Rosetta.string("subscription.subscription_user_phone_number_same")}</label>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_password")}</label>
                                    <input type={"password"} className={"form-control"} name={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    <label>{Rosetta.string("subscription.subscription_user_password_retype")}</label>
                                    <input type={"password"} className={"form-control"} name={"passwordRetype"} value={passwordRetype} onChange={(e) => setPasswordRetype(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col-12 text-center"}>
                                    <button className={"btn btn-primary"} disabled={submitNetworkInFlight} onClick={() => submitForm()}>{(!submitNetworkInFlight) ? nextLabel : Rosetta.string("common.please_wait") }</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}