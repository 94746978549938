import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {useEffect, useState} from "react";
import {Chronos} from "../../../../chronos/Chronos";
import {ImageUtil} from "../../../../util/ImageUtil";
import {Lightbox, LightboxImage} from "../../../lightbox/Lightbox";
import {DataManager} from "../../../../data/DataManager";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";

import "./ReportDetailScreen.css";
import {TextInputModal, TextInputModalActions} from "../../../alertmodal/TextInputModal";
import {Toast} from "../../../toast/TokyoToaster";
import {AppCounts} from "../../../../util/AppCounts";

export const ReportDetailScreen = (props) => {

    const {id} = useParams();

    const [report, setReport] = useState(null);
    const [reportNetworkInFlight, setReportNetworkInFlight] = useState(false);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [textInputShown, setTextInputShown] = useState(false);

    useEffect(() => {
        fetchReportFromNetwork();
    }, []);

    function launchLightbox(image) {
        let images = [];
        if (report && report.hasOwnProperty("images") && report.images.length > 0) {
            for (let i = 0; i < report.images.length; i++) {
                let image = report.images[i];
                images.push(LightboxImage(image.imagePath, image.thumbPath));
            }
        }
        setLightboxImages(images);
        setSelectedImage(image);
        setLightboxShown(true);
    }

    function lightboxDidCallback(action, data) {
        if (action === "close" || action === "dismiss") {
            setLightboxShown(false);
        }
    }

    function textInputDidCallback(action, data) {
        if (action === TextInputModalActions.SUBMIT) {
            approveReportOverNetwork(data);
            setTextInputShown(false);
        } else if (action === TextInputModalActions.DISMISS) {
            setTextInputShown(false);
        }
    }

    function fetchReportFromNetwork() {
        if (reportNetworkInFlight) return;
        setReportNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            id,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectHealthSafety.getReports, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.reports.length > 0) {
                        setReport(resp.data.reports[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setReportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setReportNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "RDV1000C"}));
            });
    }

    function approveReportOverNetwork(comments) {
        if (reportNetworkInFlight) return;
        setReportNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectReportID : id,
            approvalComments : comments
        };

        Axios.post(ENDPOINTS.projectHealthSafety.approveReport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setReport(resp.data.report);
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("health_safety.report_approval_prompt_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    AppCounts.refreshAppCounts(project.id);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setReportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setReportNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "HSRA1000C"}));
            });
    }

    // SIGN OFF

    let signOffElem = [];
    let approvalButton = (<button className={"btn btn-primary"} onClick={() => setTextInputShown(true)}>{Rosetta.string("health_safety.report_detail_action_resolve")}</button>);

    if (report && report.hasOwnProperty("approvalDate") && report.approvalDate !== null) {
        // Hide Approval Button
        approvalButton = [];

        signOffElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h3>{Rosetta.string("health_safety.report_approval_title")}</h3>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.report_approval_date")}</label>
                                    <div>{(report ? Chronos.withTimestampSeconds(report.approvalDate).format("dd/MM/yyyy HH:mm") : "")}</div>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("health_safety.report_approval_author")}</label>
                                    <div>{(report ? Rosetta.string("common.name_format", {given_name : report.firstName, family_name : report.surname}) : "")}</div>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("health_safety.report_approval_comments")}</label>
                                    <div>{(report ? report.approvalComments : "")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // PHOTO ELEMS

    let photosElem = [];
    if (report && report.hasOwnProperty("images") && report.images.length > 0) {
        for (let i = 0; i < report.images.length; i++) {
            let photo = report.images[i];

            photosElem.push(
                <div className={"col-6 col-md-4 col-lg-3"}>
                    <div className={"ratio ratio-1x1 image-thumbnail clickable"} onClick={() => launchLightbox(photo.imagePath)} style={{backgroundImage : ImageUtil.background(photo.imagePath)}} />
                </div>
            )
        }

        if (photosElem.length > 0) {
            photosElem = (
                <div className={"row mt-4"}>

                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("health_safety.report_detail_photos")}</label>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    {photosElem}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"app-screen report-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("health_safety.report_detail_title")} />
                </div>
                <div className={"col-12 col-md-4 text-end"}>
                    {approvalButton}
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>

                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h3>{Rosetta.string("health_safety.report_detail_description_title")}</h3>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("health_safety.report_detail_date")}</label>
                                        <ContentOrPlaceholder showContent={!reportNetworkInFlight}>
                                            {(report ? Chronos.withTimestampSeconds(report.dateCreated).format("dd/MM/yyyy HH:mm") : "" )}
                                        </ContentOrPlaceholder>
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("health_safety.report_detail_author")}</label>
                                        <ContentOrPlaceholder showContent={!reportNetworkInFlight}>
                                            {(report ? Rosetta.string("common.name_format", {given_name : report.firstName, family_name : report.surname}) : "")}
                                        </ContentOrPlaceholder>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("health_safety.report_detail_location")}</label>
                                        <ContentOrPlaceholder showContent={!reportNetworkInFlight}>
                                            {(report ? report.projectLocationName : "" )}
                                        </ContentOrPlaceholder>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("health_safety.report_detail_description")}</label>
                                        <ContentOrPlaceholder showContent={!reportNetworkInFlight}>
                                            {(report ? report.description : "")}
                                        </ContentOrPlaceholder>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {signOffElem}

                {photosElem}
            </div>

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={selectedImage}
                callback={lightboxDidCallback} />

            <TextInputModal
                shown={textInputShown}
                title={Rosetta.string("health_safety.report_approval_prompt_title")}
                message={Rosetta.string("health_safety.report_approval_prompt_message")}
                callback={textInputDidCallback} />

        </div>
    )

}