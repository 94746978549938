import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Navigator} from "../../../util/Navigator";
import {OrganiserTodoComponent} from "./OrganiserTodoComponent";
import {OrganiserNotesComponent} from "./OrganiserNotesComponent";

export const OrganiserHomeScreen = () => {

    const TAB_TODO = 1;
    const TAB_NOTES = 2;

    const location = useLocation();

    const HASH_TODO = "todo";
    const HASH_NOTES = "notes";

    const [selectedTab, setSelectedTab] = useState(TAB_TODO);

    useEffect(() => {
        let tabHash = Navigator.getHashParam("t", null);
        if (tabHash !== null) {
            let id = lookupID(tabHash);
            if (id) {
                setSelectedTab(id);
            }
        }
    }, [location]);

    function onTabSelected(action, tab) {
        if (action === "select" || action === "change") {
            setSelectedTab(tab);
            Navigator.navigate("/organiser#t=" + lookupHash(tab));
        }
    }

    function lookupHash(tabID) {
        switch (tabID) {
            case TAB_TODO: return HASH_TODO;
            case TAB_NOTES: return HASH_NOTES;
            default: return null;
        }
    }

    function lookupID(hash) {
        switch (hash) {
            case HASH_TODO: return TAB_TODO;
            case HASH_NOTES: return TAB_NOTES;
            default: return null;
        }
    }

    let tabContent = [];
    switch (selectedTab) {
        case TAB_TODO: tabContent = <OrganiserTodoComponent />; break;
        case TAB_NOTES: tabContent = <OrganiserNotesComponent />; break;
        default: console.log("Unknown tab: " + selectedTab);
    }

    return (
        <div className={"app-screen organiser-home-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("organiser.title")} />
                </div>
            </div>

            <div className={"row mt-4 animate-screen-content"}>
                <div className={"col-12"}>
                    <SegmentTabBar selected={selectedTab} callback={onTabSelected}>
                        <SegmentTab id={TAB_TODO} label={Rosetta.string("organiser.tab_todo")} />
                        <SegmentTab id={TAB_NOTES} label={Rosetta.string("organiser.tab_notes")} />
                    </SegmentTabBar>
                </div>
            </div>

            {tabContent}

        </div>
    )

}