import Rosetta from "../rosetta/Rosetta";

export const Form = {

    DATA_TYPE : {
        LABEL : 1,
        TEXT : 2,
        NUMBER : 3,
        DATE_TIME : 4,
        RADIO : 5,
        SIGNATURE : 6,
        PHOTO_SELECTOR : 7,
        FORM_IN_FORM : 8,
        // NOT USED ----
        RATING_BAR : 9,
        DROPDOWN : 10,
        BOOLEAN : 11,
        TRINARY : 12,
    },

    DATA_TYPES : () => {
        return [
            { id : Form.DATA_TYPE.LABEL, label : Rosetta.string("dataType.label") },
            { id : Form.DATA_TYPE.TEXT, label : Rosetta.string("dataType.text") },
            { id : Form.DATA_TYPE.NUMBER, label : Rosetta.string("dataType.number") },
            { id : Form.DATA_TYPE.DATE_TIME, label : Rosetta.string("dataType.datetime") },
            { id : Form.DATA_TYPE.RADIO, label : Rosetta.string("dataType.radio") },
            { id : Form.DATA_TYPE.SIGNATURE, label : Rosetta.string("dataType.signature") },
            { id : Form.DATA_TYPE.PHOTO_SELECTOR, label : Rosetta.string("dataType.photo_selector") },
            //{ id : Form.DATA_TYPE.FORM_IN_FORM, label : Rosetta.string("dataType.form_in_form") }
        ];

        /*
        { id : Form.DATA_TYPE.RATING_BAR, label : Rosetta.string("dataType.rating_bar") },
        { id : Form.DATA_TYPE.DROPDOWN, label : Rosetta.string("dataType.dropdown") },
        { id : Form.DATA_TYPE.BOOLEAN, label : Rosetta.string("dataType.boolean") },
        { id : Form.DATA_TYPE.TRINARY, label : Rosetta.string("dataType.trinary") },
         */
    }

}