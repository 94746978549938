import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {UrlUtil} from "../../../util/UrlUtil";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {ImageUtil} from "../../../util/ImageUtil";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {InductionFileUploadModal, InductionFileUploadTypes} from "./induction/InductionFileUploadModal";
import {Toast} from "../../toast/TokyoToaster";
import {Navigator} from "../../../util/Navigator";
import {VideoPlayerModal} from "../../videoplayer/VideoPlayerModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const InductionKitComponent = (props) => {

    const FILTER_AWAITING_INDUCTION = 1;
    const FILTER_AWAITING_CONFIRMATION = 2;
    const FILTER_COMPLETE_INDUCTIONS = 3;

    const [documents, setDocuments] = useState([]);
    const [videos, setVideos] = useState([]);

    const [infoNetworkInFlight, setInfoNetworkInFlight] = useState(false);

    const [inductions, setInductions] = useState([]);
    const [inductionCount, setInductionCount] = useState(0);
    const [inductionPage, setInductionPage] = useState(1);
    const [inductionNetworkInFlight, setInductionNetworkInFlight] = useState(false);

    const [filterInductionType, setFilterInductionType] = useState(FILTER_AWAITING_CONFIRMATION);

    const [inductionUploadShown, setInductionUploadShown] = useState(false);
    const [inductionUploadType, setInductionUploadType] = useState(null);

    const [documentDeleteNetworkInFlight, setDocumentDeleteNetworkInFlight] = useState(false);

    const [videoPlayerShown, setVideoPlayerShown] = useState(false);
    const [videoPlayerUri, setVideoPlayerUri] = useState(null);

    useEffect(() => {
        fetchInductionDataFromNetwork();
        fetchInductionsFromNetwork();
    }, []);

    useEffect(() => {
        fetchInductionsFromNetwork();
    }, [inductionPage]);

    useEffect(() => {
        fetchInductionsFromNetwork(1);
        setInductionPage(1);
    }, [filterInductionType])

    function inductionRowClicked(row) {
        if (row) {
            if (row.id !== null) {
                Navigator.navigate("/healthSafety/induction/" + row.id);
            } else {
                AlertModal.showModal(
                    Rosetta.string("health_safety.induction_list_title"),
                    Rosetta.string("health_safety.induction_no_record_notice", {name : Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.surname })})
                );
            }
        }
    }

    function launchDocumentUploadModal(type) {
        setInductionUploadType(type);
        setInductionUploadShown(true);
    }

    function inductionUploadDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setInductionUploadShown(false);

            if (data) {
                fetchInductionDataFromNetwork();
            }
        }
    }

    function promptDocumentRemoval(document) {
        AlertModal.showModal(
            Rosetta.string("health_safety.induction_file_remove_prompt_title"),
            Rosetta.string("health_safety.induction_file_remove_prompt_message", {
                filename : UrlUtil.getLastPathPart(document.filePath)
            }),
            [
                AlertModal.button(Rosetta.string("common.remove"), () => {
                    deleteDocumentOverNetwork(document.id);
                    AlertModal.dismissModal();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function launchVideoPlayer(videoUri) {
        setVideoPlayerUri(videoUri);
        setVideoPlayerShown(true);
    }

    function videoPlayerDidCallback(action, data) {
        if (action === "dismiss") {
            setVideoPlayerShown(false);
        }
    }

    function promptVideoRemoval() {
        AlertModal.showModal(
            Rosetta.string("health_safety.induction_video_delete"),
            Rosetta.string("health_safety.induction_video_delete_prompt"),
            [
                AlertModal.button(
                    Rosetta.string("health_safety.induction_video_delete"),
                    () => {
                        removeVideoOverNetwork();
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchInductionDataFromNetwork() {
        if (infoNetworkInFlight) return;
        setInfoNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined)
        };

        Axios.post(ENDPOINTS.projectInduction.getProjectInductionData, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setDocuments(resp.data.documents);
                    setVideos(resp.data.videos);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setInfoNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInfoNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.common_error_unknown", {error_code : "HSID1000C"}));
            });
    }

    function fetchInductionsFromNetwork(forcePage) {
        if (inductionNetworkInFlight) return;
        setInductionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            page : (forcePage ? forcePage : inductionPage),
            pageSize : 20
        };

        if (filterInductionType === FILTER_AWAITING_INDUCTION) {
            formData.awaitingInduction = true;
        } else {
            formData.awaitingApproval = filterInductionType === FILTER_AWAITING_CONFIRMATION;
        }

        Axios.post(ENDPOINTS.projectInduction.getProjectInductions, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setInductions(resp.data.inductions);
                    setInductionCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setInductionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "IDL1000C"}));
                setInductionNetworkInFlight(false);
            })
    }

    function deleteDocumentOverNetwork(documentID) {
        if (documentDeleteNetworkInFlight) return;
        setDocumentDeleteNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            documentID : documentID
        };

        Axios.post(ENDPOINTS.projectInduction.deleteProjectInductionDocument, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    fetchInductionDataFromNetwork();
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("health_safety.induction_file_remove_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDocumentDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "IDL1000C"}));
                setDocumentDeleteNetworkInFlight(false);
            })
    }

    function removeVideoOverNetwork() {
        if (inductionNetworkInFlight || !videos || videos.length === 0) return;
        setInductionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const formData = {
            projectID : (project ? project.id : undefined),
            projectInductionVideoID : videos[0].id
        };

        Axios.post(ENDPOINTS.projectInduction.removeProjectInductionVideo, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("health_safety.induction_video_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchInductionDataFromNetwork();
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setInductionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setInductionNetworkInFlight(false);
            });
    }

    // RENDER

    let documentElem = [];
    if (!infoNetworkInFlight) {
        documentElem = documents.map((document) => (
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{document.name}</div>
                    <a href={document.filePath} target={"_blank"} rel={"noreferrer"}>{UrlUtil.getLastPathPart(document.filePath)}</a>
                </div>
                <span className="badge bg-danger rounded-pill clickable" onClick={() => promptDocumentRemoval(document)}>{Rosetta.string("common.remove")}</span>
            </li>
        ));
    } else {
        for (let i = 0; i < 2; i++) {
            documentElem.push(
                <li className="list-group-item">
                    <div className="fw-bold mb-1"><PlaceholderText /></div>
                    <PlaceholderText />
                </li>
            );
        }
    }

    if (documentElem.length > 0) {
        documentElem = (
            <ul className="list-group">
                {documentElem}
            </ul>
        );
    } else {
        documentElem = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        )
    }

    let videosElem = [];
    let videoDeleteButtonElem = [];
    if (!infoNetworkInFlight) {
        if (videos.length > 0) {
            videosElem = videos.map((video) => (
                <div className={"row mt-2 justify-content-center"} onClick={() => launchVideoPlayer(video.filePath)}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"card clickable"}>
                            <div className={"card-body"}>
                                <div className={"mt-1 text-center"}>
                                    {video.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ));

            videoDeleteButtonElem = (
                <button className={"btn btn-outline-danger ms-1 mt-1"} onClick={() => promptVideoRemoval()}>{Rosetta.string("health_safety.induction_video_delete")}</button>
            )
        }
    } else {
        videosElem = (
            <div className={"row justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <div className={"ratio ratio-16x9 image-thumbnail"} />

                    <div className={"mt-1"}>
                        <PlaceholderText />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"induction-kit-component"}>
            <div className={"animate-screen-content"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h4>{Rosetta.string("health_safety.induction_documents")}</h4>
                                    </div>
                                </div>

                                {documentElem}

                                <div className={"text-center mt-2"}>
                                    <button className={"btn btn-outline-primary"} onClick={() => launchDocumentUploadModal(InductionFileUploadTypes.DOCUMENT)}>{Rosetta.string("health_safety.induction_documents_upload")}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h4>{Rosetta.string("health_safety.induction_video")}</h4>
                                    </div>
                                </div>

                                {videosElem}

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        <button className={"btn btn-outline-primary mt-1"} onClick={() => launchDocumentUploadModal(InductionFileUploadTypes.VIDEO)}>{Rosetta.string("health_safety.induction_video_change")}</button>
                                        {videoDeleteButtonElem}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8"}>
                        <h4>{Rosetta.string("health_safety.induction_list_title")}</h4>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <select className={"form-select"} value={filterInductionType} onChange={(e) => setFilterInductionType(parseInt(e.target.value))}>
                            <option value={FILTER_AWAITING_CONFIRMATION}>{Rosetta.string("health_safety.induction_type_confirm")}</option>
                            <option value={FILTER_COMPLETE_INDUCTIONS}>{Rosetta.string("health_safety.induction_type_completed")}</option>
                            <option value={FILTER_AWAITING_INDUCTION}>{Rosetta.string("health_safety.induction_type_awaiting")}</option>
                        </select>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                data={inductions}
                                className={"clickable"}
                                placeholderMode={inductionNetworkInFlight}
                                placeholderRows={5}
                                onRowClick={inductionRowClicked}>

                                <Column name={"firstName"} title={Rosetta.string("health_safety.induction_table_first_name")} />
                                <Column name={"surname"} title={Rosetta.string("health_safety.induction_table_last_name")} />
                                <Column name={"userRoleName"} title={Rosetta.string("health_safety.induction_table_user_type")} />
                                <Column name={"jobTitle"} title={Rosetta.string("health_safety.induction_table_job_title")} />
                                <Column name={"emailAddress"} title={Rosetta.string("health_safety.induction_table_email")} />
                                <Column name={"company"} title={Rosetta.string("health_safety.induction_table_company")} />

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={inductionPage}
                                totalCount={inductionCount}
                                pageSize={20}
                                onClick={setInductionPage} />
                        </div>
                    </div>
                </div>
            </div>

            <InductionFileUploadModal
                shown={inductionUploadShown}
                type={inductionUploadType}
                callback={inductionUploadDidCallback} />

            <VideoPlayerModal
                shown={videoPlayerShown}
                src={videoPlayerUri}
                callback={videoPlayerDidCallback} />

        </div>
    )

}