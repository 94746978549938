import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {CommonUtil} from "../../../util/CommonUtil";
import {Chronos} from "../../../chronos/Chronos";
import {ImageUtil} from "../../../util/ImageUtil";
import {PlaceholderText} from "../../placeholder/PlaceholderText";

import "./SubscriptionSettingsScreen.css";

import backArrow from "../../../assets/images/arrow_left.svg";
import forwardArrow from "../../../assets/images/arrow_right.svg";
import warningIcon from "../../../assets/images/warning.svg";
import addIcon from "../../../assets/images/add.svg";
import deleteIcon from "../../../assets/images/delete.svg";

import {UserSelectionModal} from "../common/UserSelectionModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {UserRoleSelectionModal} from "../common/UserRoleSelectionModal";
import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import {Toast} from "../../toast/TokyoToaster";
import {TextInputModal} from "../../alertmodal/TextInputModal";
import {Navigator} from "../../../util/Navigator";
import {EmergingButton} from "../../button/EmergingButton";
import {SubscriptionPaymentMethodsModal} from "./SubscriptionPaymentMethodsModal";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {StringUtil} from "../../../util/StringUtil";
import {DataManager} from "../../../data/DataManager";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import WindowUtil from "../../../util/WindowUtil";

export const SubscriptionSettingsScreen = (props) => {

    const [details, setDetails] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [billingAddress, setBillingAddress] = useState(null);
    const [detailsNetworkInFlight, setDetailsNetworkInFlight] = useState(false);
    const [updateNetworkInFlight, setUpdateNetworkInFlight] = useState(false);

    const [userSelectionShown, setUserSelectionShown] = useState(false);
    const [userSelectionContext, setUserSelectionContext] = useState(null);

    const [assignUser, setAssignUser] = useState(null);
    const [assignSlotNo, setAssignSlotNo] = useState(0);
    const [assignNetworkInFlight, setAssignNetworkInFlight] = useState(false);

    const [roleAssignShown, setRoleAssignShown] = useState(false);
    const [roleAssignMessage, setRoleAssignMessage] = useState("");

    const [paymentMethodEditorShown, setPaymentMethodEditorShown] = useState(false);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const defaultCardIsValid = useRef(false);
    const [defaultCard, setDefaultCard] = useState(null);

    const [renewNetworkInFlight, setRenewNetworkInFlight] = useState(false);
    const [renewShouldCheckPayment, setRenewShouldCheckPayment] = useState(false);
    const [renewalDidSucceed, setRenewalDidSucceed] = useState(false);
    const renewPaymentDigestID = useRef();
    const renewalTimeout = useRef();
    const [renewalCheckTrigger, setRenewalCheckTrigger] = useState();

    useEffect(() => {
        getSubscriptionDetailsOverNetwork();
    }, []);

    useEffect(() => {
        if (renewNetworkInFlight) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
        }
    }, [renewNetworkInFlight]);

    useEffect(() => {
        checkPaymentOverNetwork();
    }, [renewalCheckTrigger]);

    function goToEditor() {
        Navigator.navigate("/settings/subscription-details/modify/");
    }

    function showPaymentMethodEditor() {
        setPaymentMethodEditorShown(true)
    }

    function showAutoRenewalChangePrompt() {
        if (!defaultCardIsValid.current) {
            AlertModal.showError(Rosetta.string("subscription.subscription_auto_renewal_invalid_card"));
        } else if (details) {
            let title = Rosetta.string("subscription.subscription_auto_renewal_turn_on");
            let message = Rosetta.string("subscription.subscription_auto_renewal_turn_on_explain");
            let buttonClass = "success";
            let submitValue = 1;
            if (parseInt(details.autoRenewSubscription) === 1) {
                title = Rosetta.string("subscription.subscription_auto_renewal_turn_off");
                message = Rosetta.string("subscription.subscription_auto_renewal_turn_off_explain");
                buttonClass = "danger";
                submitValue = 0;
            }

            AlertModal.showModal(
                title,
                message,
                [
                    AlertModal.button(Rosetta.string("common.yes"), () => {
                        submitAutoRenewalOverNetwork(submitValue);
                        AlertModal.dismissModal();
                    }, buttonClass),
                    AlertModal.button(Rosetta.string("common.no"), () => {
                        AlertModal.dismissModal();
                    })
                ]
            )
        }
    }

    function userSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (userSelectionContext !== null) {
                if (userSelectionContext.fillEmptySlot) {
                    // Assign user to a slot
                    assignSlotOverNetwork(data.id);
                }
            }
            setUserSelectionShown(false);
        }
    }

    function paymentMethodEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                getSubscriptionDetailsOverNetwork();
            }
            setPaymentMethodEditorShown(false);
        }
    }

    function useRoleModalDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            if (data && assignUser) {
                assignSlotOverNetwork(assignUser, assignSlotNo, true, data);
            }
            setRoleAssignShown(false);
        }
    }

    function showUserSelection(context) {
        setUserSelectionContext(context);
        setUserSelectionShown(true);
    }

    function unassignSubscriptionSlot(slotId) {
        AlertModal.showModal(
            Rosetta.string("subscription_detail.user_slot_unassign_title"),
            Rosetta.string("subscription_detail.user_slot_unassign_prompt"),
            [
                AlertModal.button(Rosetta.string("subscription_detail.user_slot_unassign_title"), () => {
                    unassignSubscriptionSlotOverNetwork(slotId);
                    AlertModal.dismissModal();
                }, "success"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function createUserSlotElement(index, maxSlots, userSlot) {
        let titleElems = [];
        let userElems = [];
        let footerElems = null;

        let baseClassExtra = "";

        const showContent = !detailsNetworkInFlight && details;

        if (showContent) {
            if (userSlot) {
                let titleIcon = null;
                let titleIconMessage = null;
                if (parseInt(details.subscriptionSlots) < (index + 1)) {
                    // Slot is currently inactive
                    baseClassExtra = " danger";
                    titleIcon = warningIcon;
                    titleIconMessage = Rosetta.string("subscription_detail.user_slots_expired");
                } else if (parseInt(details.nextSubscriptionSlots) < (index + 1)) {
                    // Slot will become inactive in the next billing period
                    baseClassExtra = " warning";
                    titleIcon = warningIcon;
                    titleIconMessage = Rosetta.string("subscription_detail.user_slots_will_expire");
                }

                titleElems = (
                    <div className={"header-container"}>
                        <div className={"title-area"}>
                            {Rosetta.string("subscription_detail.user_slots_slot_no", { number : (index + 1) })}
                        </div>
                        <div className={"icon-area"} style={{backgroundImage : ImageUtil.background(titleIcon)}} onClick={() => AlertModal.showModal(Rosetta.string("subscription_detail.user_slots_title"), titleIconMessage)} />
                    </div>
                )

                let deleteButton = (<span className={"user-content-icon"} onClick={() => unassignSubscriptionSlot(userSlot.id)} style={{ backgroundImage : ImageUtil.background(deleteIcon) }} />);
                if (index === 0) {
                    // TODO Check if slot is the same user ID as the currently logged in user
                    deleteButton = [];
                }

                userElems = (
                    <div className={"user-content"}>
                        <div className={"user-content-main"}>
                            <div><strong>{Rosetta.string("common.name_format", { given_name : userSlot.firstName, family_name : userSlot.surname })}</strong></div>
                            <div>{userSlot.userRoleName}</div>
                        </div>

                        {deleteButton}
                    </div>
                )

                let downButton = [];
                let upButton = [];

                if (index > 0) {
                    downButton = (<div className={"arrow-button"} style={{backgroundImage : ImageUtil.background(backArrow)}} />);
                }

                if (index < (maxSlots - 1)) {
                    upButton = (<div className={"arrow-button"} style={{backgroundImage : ImageUtil.background(forwardArrow)}} />);
                }

                footerElems = (
                    <div className={"footer-arrows"}>
                        {downButton}
                        <div className={"space"} />
                        {upButton}
                    </div>
                )
            } else {
                baseClassExtra = " unfilled";

                titleElems = (
                    <div className={"header-container"}>
                        <div className={"title-area"}>
                            {Rosetta.string("subscription_detail.user_slots_slot_no", { number : (index + 1) })}
                        </div>
                    </div>
                );

                userElems = (
                    <div className={"add-target"} onClick={() => showUserSelection({ fillEmptySlot : true })}>
                        <div className={"add-icon"} style={{backgroundImage : ImageUtil.background(addIcon)}} />
                        <div>{Rosetta.string("subscription_detail.user_slots_assign")}</div>
                    </div>
                )
            }
        } else {
            titleElems = <PlaceholderText />;
            userElems = <PlaceholderText />;
            footerElems = <PlaceholderText />;
        }

        if (footerElems !== null) {
            footerElems = (
                <div className={"card-footer"}>
                    {footerElems}
                </div>
            )
        }

        return (
            <div className={"col-6 col-md-4 col-md-3 mt-2"}>
                <div className={"card subscription-slot" + baseClassExtra}>
                    <div className={"card-header"}>
                        {titleElems}
                    </div>
                    <div className={"card-body"}>
                        {userElems}
                    </div>
                    {footerElems}
                </div>
            </div>
        )
    }

    function getSubscriptionDetailsOverNetwork() {
        if (detailsNetworkInFlight) return;
        setDetailsNetworkInFlight(true);

        Axios.get(ENDPOINTS.subscription.getSubscription)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setDetails(resp.data.subscription);
                    setPaymentMethods(resp.data.paymentMethods);
                    setBillingAddress(resp.data.billingAddress);

                    for (let i = 0; i < resp.data.paymentMethods.length; i++) {
                        if (resp.data.paymentMethods[i].id === resp.data.subscription.defaultPaymentMethodID) {
                            setDefaultCard(resp.data.paymentMethods[i]);
                            break;
                        }
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDetailsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDetailsNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBI1000C"}));
            })
    }

    function assignSlotOverNetwork(userID, slotNo, acknowledge, userRoleID) {
        if (assignNetworkInFlight) return;
        setAssignNetworkInFlight(true);

        const data = {
            userID
        };

        if (slotNo) {
            data.slotNumber = slotNo + 1;
        }

        if (acknowledge) {
            data.acknowledgeAdvisory = 1;
        }

        if (userRoleID !== undefined) {
            data.newUserRoleID = userRoleID;
        }

        Axios.post(ENDPOINTS.subscription.assignSubscriptionSlot, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let wasSuccess = false;

                    if (resp.data.user_role_advisory !== undefined && resp.data.user_role_advisory) {
                        // Show user assignment
                        setAssignUser(userID);
                        setAssignSlotNo(slotNo);
                        setRoleAssignMessage(resp.data.user_role_advisory_message);
                        setRoleAssignShown(true);
                    } else if (resp.data.subscription !== undefined) {
                        setDetails(resp.data.subscription);
                        wasSuccess = true;
                    } else {
                        getSubscriptionDetailsOverNetwork();
                        wasSuccess = true;
                    }

                    if (wasSuccess) {
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("subscription_detail.user_slot_assign_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setAssignNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAssignNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.generic_common_unknown", { error_code : "SUBA1000C" }));
            })
    }

    function unassignSubscriptionSlotOverNetwork(id) {
        if (assignNetworkInFlight) return;
        setAssignNetworkInFlight(true);

        let data = {
            subscriptionSlotID : id
        };

        Axios.post(ENDPOINTS.subscription.unassignSubscriptionSlot, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setDetails(resp.data.subscription);
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("subscription_detail.user_slot_unassign_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setAssignNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAssignNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_generic_unknown", { "error_code" : "SUBU1000C" }));
            })
    }

    function submitAutoRenewalOverNetwork(value) {
        if (updateNetworkInFlight) return;
        setUpdateNetworkInFlight(true);

        let data = {
            autoRenewSubscription : value
        };

        Axios.post(ENDPOINTS.subscription.setSubscriptionAutoRenewal, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    getSubscriptionDetailsOverNetwork();

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("subscription.subscription_auto_renewal_update_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUpdateNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUpdateNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_generic_unknown", { "error_code" : "SUBA1000C" }));
            });
    }

    function enqueuePaymentCheck() {
        renewalTimeout.current = setTimeout(() => {
            setRenewalCheckTrigger(Math.random());
        }, 4000);
    }

    function attemptPaymentOverNetwork() {
        if (renewNetworkInFlight) return;
        setRenewNetworkInFlight(true);

        Axios.post(ENDPOINTS.subscription.attemptSubscriptionRenewal, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.paymentDigestID) {
                        renewPaymentDigestID.current = resp.data.paymentDigestID;
                        setRenewShouldCheckPayment(true);

                        enqueuePaymentCheck();
                    } else {
                        setRenewNetworkInFlight(false);
                        setRenewShouldCheckPayment(false);

                        AlertModal.showError(API.defaultError("SRA1001C"));
                    }
                } else {
                    setRenewNetworkInFlight(false);
                    AlertModal.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                setRenewNetworkInFlight(false);
                AlertModal.showError(API.defaultError("SRA1000C"));
            })
    }

    function checkPaymentOverNetwork() {
        if (!renewShouldCheckPayment || !renewNetworkInFlight) return;

        clearTimeout(renewalTimeout.current);

        const data = {
            paymentDigestID : renewPaymentDigestID.current
        };

        Axios.post(ENDPOINTS.subscription.checkPaymentComplete, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    // Clear block now.
                    if (resp.hasOwnProperty("data")) {
                        if (resp.data.hasOwnProperty("subscriptionActive")) {
                            if (resp.data.subscriptionActive) {
                                Toast.show(
                                    Rosetta.string("common.success"),
                                    Rosetta.string("client.subscription_renew_success"),
                                    Toast.SUCCESS,
                                    Toast.LONG
                                );
                            }

                            DataManager.setSubscriptionActive(resp.data.subscriptionActive);
                        }
                    }

                    setRenewShouldCheckPayment(false);
                    setRenewNetworkInFlight(false);
                    setRenewalDidSucceed(true);
                } else {
                    enqueuePaymentCheck();
                }
            })
            .catch((e) => {
                console.log(e);
                enqueuePaymentCheck();
            });
    }

    // RENDER

    let nextBillingDate = "";
    if (details) {
        let billDateChronos = Chronos.withTimestampSeconds(details.renewalDate);
        if (billDateChronos && billDateChronos.isValid()) {
            nextBillingDate = billDateChronos.format("dd MMMM yyyy");
        }
    }

    const showContent = !detailsNetworkInFlight || !details;

    let userSlotElems = [];
    if (showContent) {
        if (details) {
            if (details.userSlots.length > 0) {
                userSlotElems = details.userSlots.map((slot, index) => createUserSlotElement(index, details.userSlots.length, slot));
            }

            let leftoverSlots = details.subscriptionSlots - details.userSlots.length;
            if (leftoverSlots > 0) {
                for (let i = 0; i < leftoverSlots; i++) {
                    userSlotElems.push(createUserSlotElement(userSlotElems.length, details.userSlots.length, undefined));
                }
            }
        }
    } else {
        for (let i = 0; i < 3; i++) {
            userSlotElems.push(createUserSlotElement(0, 0, undefined));
        }
    }

    let subscriptionChangeNoticeElem = [];
    if (details && (details.nextSubscriptionSlots !== null || details.nextSubscriptionTypeID !== null || details.nextSubscriptionSkuID !== null)) {
        subscriptionChangeNoticeElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-warning"} style={{marginBottom : "0"}}>
                        <strong>
                            {Rosetta.string("subscription_detail.billing_change_notification", {
                                date : nextBillingDate,
                                type : details.nextSubscriptionTypeName,
                                sku : details.nextSubscriptionSkuName,
                                slots : details.nextSubscriptionSlots
                            })}
                        </strong>
                    </div>
                </div>
            </div>
        )
    }

    let subscriptionTypeLabel = CommonUtil.getOrDefault(details, "name", "-");
    if (details && details.skuName !== null) {
        subscriptionTypeLabel = Rosetta.string("subscription_detail.type_format", { type : CommonUtil.getOrDefault(details, "name", "-"), sku : CommonUtil.getOrDefault(details, "skuName", "-") });
    }

    let paymentElements = [];

    if (details && details.autoRenewSubscription) {
        let autoRenewalBadge;
        if (parseInt(details.autoRenewSubscription) === 1) {
            autoRenewalBadge = (<span className={"badge bg-primary"}>{Rosetta.string("common.on")}</span>);
        } else {
            autoRenewalBadge = (<span className={"badge bg-secondary"}>{Rosetta.string("common.off")}</span>);
        }

        paymentElements.push(
            <li className={"list-group-item  d-flex justify-content-between align-items-center clickable"} onClick={() => showAutoRenewalChangePrompt()}>
                {Rosetta.string("subscription.subscription_auto_renew")} {autoRenewalBadge}
            </li>
        );
    }

    if (details && details.defaultPaymentMethodID && paymentMethods && paymentMethods.length > 0) {
        if (defaultCard !== null) {
            let cardExpiry = Chronos.parse(defaultCard.expiryYear + "-" + StringUtil.leftPad(defaultCard.expiryMonth, 2, "0") + "-01");
            let expiryDate = (cardExpiry ? cardExpiry.format("MM/yy") : "?");

            cardExpiry.add(1, Chronos.MONTHS);

            let expiryClass = "";
            if (Chronos.now().isAfter(cardExpiry.getDate())) {
                expiryClass = "text-danger";
            } else {
                defaultCardIsValid.current = true;
            }

            paymentElements.push(
                <li className={"list-group-item d-flex justify-content-between align-items-center clickable"} onClick={showPaymentMethodEditor}>
                    <div>
                        <div><strong>{Rosetta.string("subscription.subscription_default_card")}</strong></div>
                        <div>{Rosetta.string("subscription.subscription_default_card_ending_in", { lastFour : defaultCard.lastFour })}</div>
                        <div className={expiryClass}>{Rosetta.string("subscription.subscription_default_card_expires", { expiry : expiryDate })}</div>
                    </div>
                    <span className={"badge bg-primary"}>{Rosetta.string("subscription.subscription_default_card_change")}</span>
                </li>
            )
        }
    }

    if (!detailsNetworkInFlight && paymentElements.length > 0) {
        paymentElements = (
            <ul className={"list-group"}>
                {paymentElements}
            </ul>
        );
    } else {
        paymentElements = [];
    }

    let expireMessageElem = [];
    if (!DataManager.isSubscriptionActive()) {
        let renewButtonElem = [];
        if (defaultCard !== null) {
            renewButtonElem = (
                <div className={"mt-2"}>
                    <button className={"btn btn-outline-light"} onClick={() => attemptPaymentOverNetwork()}>{Rosetta.string("client.subscription_renew_now")}</button>
                </div>
            );
        }

        expireMessageElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body text-bg-danger"}>
                            {Rosetta.string("client.subscription_expired_message")}
                            {renewButtonElem}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (renewalDidSucceed) {
        expireMessageElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body text-bg-success"}>
                            {Rosetta.string("client.subscription_renew_success")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let renewPaymentBlockerElem = [];
    if (renewNetworkInFlight) {
        renewPaymentBlockerElem = (
            <BaseModal
                key={"payment_renew_blocker"}
                shown={true}>

                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h5>{Rosetta.string("common.please_wait")}</h5>
                        <div>{Rosetta.string("subscription.payment_waiting_message")}</div>
                        <div className={"mt-2"}><LoadingSpinner inline={true} small={true} /></div>
                    </div>
                </div>

            </BaseModal>
        )
    }

    return (
        <Elements stripe={stripePromise}>
            <div className={"app-screen subscription-settings-screen"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitle title={Rosetta.string("system_settings.option_subscription")} />
                    </div>
                </div>

                <div className={"animate-screen-content"}>

                    {expireMessageElem}

                    <div className={"row"}>
                        <div className={"col-12 col-md-6 mt-4"}>
                            <div className={"card subscription-info"}>
                                <div className={"card-body text-center"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("subscription_detail.type_title")}</label>
                                            <span className={"subscription-title"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                <span className={"value"}>{subscriptionTypeLabel}</span>
                                            </ContentOrPlaceholder>
                                        </span>
                                        </div>
                                    </div>


                                    <div className={"row mt-2"}>
                                        <div className={"col-12"}>
                                            <button className={"btn btn-primary"} onClick={() => goToEditor()}>{Rosetta.string("common.edit")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-12 col-md-6 mt-4"}>
                            <div className={"card subscription-info"}>
                                <div className={"card-body text-center"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("subscription_detail.slots_title")}</label>
                                            <span className={"subscription-slots"}>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                <div className={"value"}>{CommonUtil.getOrDefault(details, "subscriptionSlots", "1")}</div>
                                            </ContentOrPlaceholder>
                                        </span>
                                        </div>
                                    </div>

                                    <div className={"row mt-2"}>
                                        <div className={"col-12"}>
                                            <button className={"btn btn-primary"} onClick={() => goToEditor()}>{Rosetta.string("common.edit")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <label>{Rosetta.string("subscription_detail.billing_date")}</label>
                                            <ContentOrPlaceholder showContent={showContent}>
                                                <div>{nextBillingDate}</div>
                                            </ContentOrPlaceholder>
                                        </div>

                                        <div className={"col-12 col-md-6"} >
                                            {paymentElements}
                                        </div>
                                    </div>

                                    {subscriptionChangeNoticeElem}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <h3>{Rosetta.string("subscription_detail.user_slots_title")}</h3>
                        </div>

                        {userSlotElems}
                    </div>

                </div>

                <UserSelectionModal
                    shown={userSelectionShown}
                    callback={userSelectionDidCallback} />

                <UserRoleSelectionModal
                    shown={roleAssignShown}
                    message={roleAssignMessage}
                    callback={useRoleModalDidCallback} />

                <SubscriptionPaymentMethodsModal
                    shown={paymentMethodEditorShown}
                    paymentMethods={paymentMethods}
                    defaultPaymentMethodID={(defaultCard ? defaultCard.id : undefined)}
                    address={billingAddress}
                    callback={paymentMethodEditorDidCallback} />

                {renewPaymentBlockerElem}

            </div>
        </Elements>
    )

}