import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {FormViewComponent} from "./FormViewComponent";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {Lightbox} from "../../lightbox/Lightbox";

export const FormDetailScreen = (props) => {

    const {id} = useParams();

    const [form, setForm] = useState(null);
    const [formNetworkInFlight, setFormNetworkInFlight] = useState(false);

    const [showLightbox, setShowLightbox] = useState(false);
    const [lightboxImage, setLightboxImage] = useState(null);
    const [lightboxImages, setLightboxImages] = useState([]);

    useEffect(() => {
        fetchFormFromNetwork();
    }, []);

    function handleFormCallback(action, data) {
        if (action === "image") {
            setLightboxImage(data.imageUrl);
            setLightboxImages(data.images);
            setShowLightbox(true);
        }
    }

    function lightboxDidCallback(action) {
        if (action === "close") {
            setShowLightbox(false);
        }
    }

    function fetchFormFromNetwork() {
        if (formNetworkInFlight || !id) return;
        setFormNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : null),
            page : 1,
            pageSize : 1,
            formSubmissionID : id
        };

        Axios.post(ENDPOINTS.projectForm.getProjectFormsAdmin, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.forms.length > 0) {
                        setForm(resp.data.forms[0]);
                    }
                } else {
                    showError(API.formatError(resp));
                }
                setFormNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PFD1000C"}));
                setFormNetworkInFlight(false);
            })
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    let formStructure = null;
    if (form) {
        if (form.hasOwnProperty("form")) {
            formStructure = form.form;
        }
    }

    return (
        <div className={"app-screen form-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={"Form Detail"} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-sm-8 col-md-6 col-lg-4"}>
                        <FormViewComponent
                            callback={handleFormCallback}
                            placeholderMode={formNetworkInFlight}
                            form={formStructure} />
                    </div>
                </div>
            </div>

            <Lightbox
                shown={showLightbox}
                initialImage={lightboxImage}
                images={lightboxImages}
                callback={lightboxDidCallback} />
        </div>
    )

}