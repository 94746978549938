import {VideoPlayer} from "./VideoPlayer";

import closeIcon from "../../assets/images/close.svg";

import "./VideoPlayer.css";
import {ImageUtil} from "../../util/ImageUtil";
import {useEffect, useState} from "react";

export const VideoPlayerModal = (props) => {

    const {shown} = props;

    const [dismissing, setDismissing] = useState(false);

    useEffect(() => {
        if (!shown) {
            setDismissing(false);
        }
    }, [shown]);

    useEffect(() => {
        if (dismissing) {
            setTimeout(() => {
                handleCallback("dismiss");
            }, 400);
        }
    }, [dismissing]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    if (!shown) return [];

    let key = "video_modal";
    let className = "";
    if (dismissing) {
        key = "video_modal_dismiss";
        className = " dismissing";
    }

    return (
        <div className={"video-player-modal" + className} key={key}>
            <div className={"aligner"} />

            <div className={"container"}>
                <div className={"modal-body"}>
                    <VideoPlayer src={props.src} />
                </div>
            </div>

            <div className={"aligner"} />

            <div className={"close"} onClick={() => setDismissing(true)} style={{backgroundImage : ImageUtil.background(closeIcon) }} />
        </div>
    )

}