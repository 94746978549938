import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";
import {FilterText} from "../../filter/FilterText";
import {Chronos} from "../../../chronos/Chronos";

export const SnagsDrawingComponent = (props) => {

    const [drawings, setDrawings] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [drawingNetworkInFlight, setDrawingNetworkInFlight] = useState(false);

    const [filterDate, setFilterDate] = useState(null);
    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchDrawingsFromNetwork();
    }, []);

    useEffect(() => {
        fetchDrawingsFromNetwork();
    }, [currentPage, filterDate, filterKeyword])

    function tableRowWasClicked(row) {
        if (row !== undefined) {
            Navigator.navigate("/snagging/drawing/" + row.id);
        }
    }

    function fetchDrawingsFromNetwork() {
        if (drawingNetworkInFlight) return;
        setDrawingNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            page : currentPage,
            pageSize : 20
        };

        if (filterDate != null) {
            const filterChronos = Chronos.parse(filterDate);

            if (filterChronos.isValid()) {
                formData.startDate = filterChronos.startOfDay().seconds();
                formData.endDate = filterChronos.endOfDay().seconds();
            }
        }

        if (filterKeyword && filterKeyword !== "") {
            formData.keyword = filterKeyword;
        }

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawings, formData)
            .then((r) => {
                let resp = API.parse(r);

                if (resp.success) {
                    setDrawings(resp.data.drawings);
                    setTotalCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
                setDrawingNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PSD1000C"}));
                setDrawingNetworkInFlight(false);
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    return (
        <div>
            <div className={"row mt-4"}>
                <div className={"col-12 col-md-4"}>
                    <input type={"date"} className={"form-control"} value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
                </div>
                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={drawings}
                        placeholderMode={drawingNetworkInFlight}
                        placeholderRows={5}
                        onRowClick={tableRowWasClicked}>

                        <Column name={"name"} title={Rosetta.string("snagging.drawing_name")} />
                        <Column name={"snagCount"} title={Rosetta.string("snagging.drawing_count")} className={"text-center"} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={currentPage}
                        totalCount={totalCount}
                        pageSize={20}
                        onClick={(page) => setCurrentPage(page)} />
                </div>
            </div>
        </div>
    )

}