import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {useEffect, useState} from "react";

export const DiaryRegisterComponent = (props) => {

    const {date} = props;

    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [page, setPage] = useState(1);
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchSiteRegisterFromNetwork();
    }, []);

    useEffect(() => {
        fetchSiteRegisterFromNetwork();
    }, [page, date, filterKeyword]);

    function formatTableDate(date) {
        console.log("FORMAT DATE: " + date);
        if (date !== null) {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yy - HH:mm");
        }
        return "---";
    }

    function fetchSiteRegisterFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const chronosDate = Chronos.with(date);
        let useDate = null;
        if (chronosDate && chronosDate.isValid()) {
            useDate = chronosDate.seconds();
        }

        let formData = {
            projectID : project ? project.id : undefined,
            date : useDate,
            keyword : filterKeyword,
            page,
            pageSize : 20
        };

        Axios.post(ENDPOINTS.project.getSiteRegisterTable, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.users);
                    setUserCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PSRL1000C" }));
            });
    }

    return (
        <div className={"animate-screen-content diary-register-component"}>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>

                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterKeyword} />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                data={users}
                                placeholderMode={userNetworkInFlight}
                                placeholderRows={10}>

                                <Column name={"id"} title={""} />
                                <Column name={"firstName"} title={Rosetta.string("register.table_first_name")} />
                                <Column name={"surname"} title={Rosetta.string("register.table_last_name")} />
                                <Column name={"jobTitle"} title={Rosetta.string("register.table_job_title")} />
                                <Column name={"company"} title={Rosetta.string("register.table_company")} />
                                <Column name={"userRoleName"} title={Rosetta.string("register.table_user_type")} />
                                <Column name={"signInDate"} title={Rosetta.string("register.table_signed_in")} className={"text-center"} render={(data, row) => (
                                    <>{formatTableDate(data)}</>
                                )} />
                                <Column name={"signOutDate"} title={Rosetta.string("register.table_signed_out")} className={"text-center"} render={(data, row) => (
                                    <>{formatTableDate(data)}</>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={userCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}