import Rosetta from "../../../rosetta/Rosetta";
import {OrganiserNoteEditorModal} from "./OrganiserNoteEditorModal";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import ArrayUtil from "../../../util/ArrayUtil";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {Chronos} from "../../../chronos/Chronos";
import {Toast} from "../../toast/TokyoToaster";
import {OrganiserNoteWidget} from "./OrganiserNoteWidget";

import "./OrganiserNotesComponent.css";

export const OrganiserNotesComponent = (props) => {

    const [notes, setNotes] = useState([]);
    const [notesCount, setNotesCount] = useState(0);
    const [page, setPage] = useState(1);
    const [noteNetworkInFlight, setNoteNetworkInFlight] = useState(false);

    const [completeNetworkInFlight, setCompleteNetworkInFlight] = useState(false);

    const [noteEditorShown, setNoteEditorShown] = useState(false);
    const [noteEditorID, setNoteEditorID] = useState();

    useEffect(() => {
        fetchNotesFromNetwork();
    }, []);

    useEffect(() => {
        fetchNotesFromNetwork();
    }, [page]);

    function noteDidCallback(action, data) {
        if (action === "show") {
            showNoteEditor(data.id);
        } else if (action === "delete") {
            promptToDeleteNote(data);
        }
    }

    function showNoteEditor(id) {
        setNoteEditorID(id);
        setNoteEditorShown(true);
    }

    function noteEditorDidCallback(action, data) {
        if (action === "close" || action === "dismiss") {
            setNoteEditorShown(false);

            if (data) {
                let didUpdate = false;
                let curNotes = [...notes];
                for (let i = 0; i < curNotes.length; i++) {
                    if (parseInt(curNotes[i].id) === parseInt(data.id)) {
                        curNotes[i] = data;
                        didUpdate = true;
                        break;
                    }
                }

                if (!didUpdate) {
                    curNotes.unshift(data);
                }

                setNotes(curNotes);
            }
        }
    }

    function promptToDeleteNote(note) {
        AlertModal.showModal(
            Rosetta.string("organiser.note_deletion_prompt_title"),
            Rosetta.string("organiser.note_deletion_prompt_message", { title : note.title }),
            [
                AlertModal.button(Rosetta.string("organiser.note_deletion_prompt_title"), () => {
                    deleteNoteOverNetwork(note.id);
                    AlertModal.dismissModal();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function fetchNotesFromNetwork() {
        if (noteNetworkInFlight) return;
        setNoteNetworkInFlight(true);

        let formData = {
            noteTypeID : 1,
            page,
            pageSize : 20
        };

        Axios.post(ENDPOINTS.personal.getUserNotes, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setNotes(ArrayUtil.appendArray(notes, resp.data.notes, true));
                    setNotesCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNoteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNoteNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PNN1000C" }));
            })
    }

    function deleteNoteOverNetwork(id) {
        if (completeNetworkInFlight) return;
        setCompleteNetworkInFlight(true);

        let formData = {
            id
        };

        Axios.post(ENDPOINTS.personal.deleteUserNote, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("organiser.note_deletion_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    let curNotes = [...notes];
                    for (let i = 0; i < curNotes.length; i++) {
                        if (parseInt(curNotes[i].id) === parseInt(id)) {
                            curNotes.splice(i, 1);
                            break;
                        }
                    }
                    setNotes(curNotes);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setCompleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "OTDC3000C" }));
                setCompleteNetworkInFlight(false);
            });
    }

    let noteElems = [];
    if (!noteNetworkInFlight) {
        noteElems = notes.map((note) => (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <OrganiserNoteWidget
                        note={note}
                        showActions={true}
                        callback={noteDidCallback} />
                </div>
            </div>
        ))
    } else {
        for (let i = 0; i < 3; i++) {
            noteElems.push(
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <OrganiserNoteWidget />
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className={"animate-screen-content organiser-notes-component"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8"}>
                        <h3>{Rosetta.string("organiser.tab_notes")}</h3>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showNoteEditor()}>{Rosetta.string("organiser.todos_action_add")}</button>
                    </div>
                </div>

                {noteElems}
            </div>

            <OrganiserNoteEditorModal
                shown={noteEditorShown}
                id={noteEditorID}
                noteTypeID={1}
                callback={noteEditorDidCallback} />
        </>

    )

}