import Rosetta from "../../../rosetta/Rosetta";
import {useSearchParams} from "react-router-dom";
import {Navigator} from "../../../util/Navigator";

import "./WelcomeUserScreen.css";

import constructAppsLogo from "../../../assets/images/header_logo_2.svg";
import appStoreButton from "../../../assets/images/app-store-badge.svg";
import playStoreBadge from "../../../assets/images/google-play-badge_small.png";
import portalPreview from "../../../assets/images/welcome_portal_preview.png";
import appPreview from "../../../assets/images/welcome_app_preview.png";
import {ImageUtil} from "../../../util/ImageUtil";


export const WelcomeUserScreen = (props) => {

    const [params] = useSearchParams()

    function goToPortal() {
        Navigator.navigate("/");
    }

    // RENDER

    let portalElem = [];
    if (params.get("type") === "admin") {
        portalElem = (
            <div className={"col-12 col-md-6 mt-4"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        <div className={"row text-center"}>
                            <div className={"col-12"}>
                                <h4>{Rosetta.string("welcome_screen.next_step_portal_title")}</h4>
                                <p>{Rosetta.string("welcome_screen.next_step_portal_description")}</p>
                            </div>
                        </div>

                        <div className={"row justify-content-center mt-2"}>
                            <div className={"col-12 col-md-10 col-lg-8"}>
                                <div className={"ratio ratio-4x3 preview-image"} style={{backgroundImage : ImageUtil.background(portalPreview)}}>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12 text-center alignment-container"}>
                                <button className={"btn btn-primary"} onClick={() => goToPortal()}>{Rosetta.string("welcome_screen.next_step_portal_action")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={"app-screen welcome-user-screen"}>
            <div className={"container-fluid header-block"}>
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h1>{Rosetta.string("welcome_screen.title")}</h1>
                    </div>
                </div>

                <div className={"row mt-4 mb-4 justify-content-center"}>
                    <div className={"col-8 col-md-6 col-lg-5 col-xl-3"}>
                        <img src={constructAppsLogo} alt={Rosetta.string("welcome_screen.title_logo")}/>
                    </div>
                </div>

            </div>

            <div className={"container animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <h2>{Rosetta.string("welcome_screen.subtitle")}</h2>
                    </div>
                </div>

                <div className={"row justify-content-center"}>
                    <div className={"col-12 col-md-6 mt-4"}>
                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12 text-center"}>
                                        <h4>{Rosetta.string("welcome_screen.next_step_app_title")}</h4>
                                        <p>{Rosetta.string("welcome_screen.next_step_app_description")}</p>
                                    </div>
                                </div>

                                <div className={"row justify-content-center mt-2"}>
                                    <div className={"col-12 col-md-10 col-lg-8"}>
                                        <div className={"ratio ratio-4x3 preview-image"}
                                             style={{backgroundImage: ImageUtil.background(appPreview)}}>

                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        <a href={"https://apps.apple.com/gb/app/constructapps/id6457261153"} className={"me-1"}>
                                            <img src={appStoreButton} className={"app-store-badge"}
                                                 alt={Rosetta.string("welcome_screen.next_step_app_app_store")}/>
                                        </a>

                                        <a href={"https://play.google.com/store/apps/details?id=com.constructionapps.constructionapp"}>
                                            <img src={playStoreBadge} className={"app-store-badge"}
                                                 alt={Rosetta.string("welcome_screen.next_step_app_play_store")}/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {portalElem}
                </div>
            </div>
        </div>
    )

}