import "./WeatherWidgetComponent.css";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";

export const WeatherWidgetComponent = (props) => {

    const [weather, setWeather] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchWeatherFromNetwork();
    }, []);

    function fetchWeatherFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : project ? project.id : undefined
        };

        Axios.post(ENDPOINTS.project.getWeather, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setWeather(resp.data);
                } else {

                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
            });
    }

    let locationName = "";
    let weatherIcon = null;
    let weatherName = "";
    let tempMain = "---";
    let tempHigh = "---";
    let tempLow = "---";
    if (weather !== null) {
        if (weather.hasOwnProperty("location")) {
            locationName = weather.location.name + ", " + weather.location.country;
        }

        if (weather.hasOwnProperty("weatherData")) {
            if (weather.weatherData.hasOwnProperty("weather")) {
                weatherIcon = weather.weatherData.weather.icon;
                weatherName = weather.weatherData.weather.name;
            }

            if (weather.weatherData.hasOwnProperty("temperature")) {
                const tempUnit = weather.weatherData.temperature.unit;

                tempMain = Math.round(weather.weatherData.temperature.primary).toFixed(0) + tempUnit;
                tempHigh = Math.round(weather.weatherData.temperature.max).toFixed(0) + tempUnit;
                tempLow = Math.round(weather.weatherData.temperature.min).toFixed(0) + tempUnit;
            }
        }
    }

    return (
        <div className={"weather-widget-component"}>
            <div className={"row align-items-center"}>
                <div className={"col-12"}>
                    <div className={"weather-name"}>
                        <ContentOrPlaceholder showContent={!networkInFlight}>
                            {weatherName}
                        </ContentOrPlaceholder>
                    </div>
                </div>

                <div className={"col-4"}>
                    <div className={"weather-icon-container"}>
                        <div className={"weather-icon"} aria-label={weatherName} style={{ backgroundImage : ImageUtil.background(weatherIcon) }} />
                    </div>
                </div>
                <div className={"col-4 temperature-headline"}>
                    <ContentOrPlaceholder showContent={!networkInFlight}>
                        {tempMain}
                    </ContentOrPlaceholder>
                </div>
                <div className={"col-4 temperature-info"}>
                    <div>
                        <ContentOrPlaceholder showContent={!networkInFlight}>
                            {Rosetta.string("diary.weather_high", { temp : tempHigh })}
                        </ContentOrPlaceholder>
                    </div>

                    <div>
                        <ContentOrPlaceholder showContent={!networkInFlight}>
                            {Rosetta.string("diary.weather_low", { temp: tempLow })}
                        </ContentOrPlaceholder>
                    </div>
                </div>

                <div className={"col-12"}>
                    <div className={"weather-name"}>
                        <ContentOrPlaceholder showContent={!networkInFlight}>
                            {locationName}
                        </ContentOrPlaceholder>
                    </div>
                </div>
            </div>
        </div>
    )

}