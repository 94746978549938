import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {CommonUtil} from "../../../util/CommonUtil";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";

import "./SnagDrawingSnagDetailScreen.css";
import {ImageUtil} from "../../../util/ImageUtil";
import TableComponent, {Column} from "../../table/TableComponent";
import {Lightbox} from "../../lightbox/Lightbox";

import clock from "../../../assets/images/clock.svg";
import tick from "../../../assets/images/tick_circle.svg";
import {Navigator} from "../../../util/Navigator";

export const SnagDrawingSnagDetailScreen = (props) => {

    const {snagID} = useParams();

    const [snag, setSnag] = useState(null);
    const [snagNetworkInFlight, setSnagNetworkInFlight] = useState(false);

    const [lightboxShown, setLightboxShown] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        fetchDrawingSnagFromNetwork();
    }, []);

    function navigateToDrawing() {
        if (snag) {
            if (snag.projectDrawingID) {
                Navigator.navigate("/drawing/view/" + snag.projectDrawingID);
            }
        }
    }

    function requestLightbox(imagePath) {
        let images = [];
        if (snag !== null && snag.images !== undefined) {
            for (let i = 0; i < snag.images.length; i++) {
                images.push({
                    imageUrl : snag.images[i].imagePath,
                    thumbUrl : snag.images[i].thumbPath
                });
            }
        }

        setSelectedImage(imagePath);
        setLightboxImages(images);
        setLightboxShown(true)
    }

    function lightboxDidCallback(action, data) {
        if (action === "close") {
            setLightboxShown(false);
        }
    }

    function fetchDrawingSnagFromNetwork() {
        if (snagNetworkInFlight) return;
        setSnagNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectDrawingSnagID : snagID,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawingSnags, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.snags.length > 0) {
                        setSnag(resp.data.snags[0]);
                    } else {
                        // TODO Error
                        showError("No Snag by given ID");
                    }
                } else {
                    showError(API.formatError(resp));
                }
                setSnagNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSnagNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PDSD1000C"}));
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    let statusElem = [];
    if (snag !== null) {
        let statusClass = "alert-warning";
        let statusIcon = null;
        let statusTitle = null;
        let statusSubtitle = null;

        if (snag.actionedDate === null) {
            // To be actioned
            statusIcon = clock;
            statusTitle = Rosetta.string("snagging.snag_status_actioned");
        } else if (snag.signOffDate === null) {
            // To be signed off
            statusIcon = clock;
            statusTitle = Rosetta.string("snagging.snag_status_sign_off");
        } else {
            // Complete
            statusClass = "alert-success";
            statusIcon = tick;
            statusTitle = Rosetta.string("snagging.snag_status_complete");
            statusSubtitle = Rosetta.string("snagging.snag_status_complete_subtitle", {
                name : Rosetta.string("common.name_format", { given_name : snag.signOffUserFirstName, family_name : snag.signOffUserSurname}),
                date : Chronos.withTimestampSeconds(snag.signOffDate).format("dd/MM/yyyy HH:mm")
            });
        }

        let subtitleElem = [];
        if (statusSubtitle !== null) {
            subtitleElem = (
                <div className={"status-subtitle"}>{statusSubtitle}</div>
            )
        }

        statusElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert mb-0 " + statusClass}>
                        <div className={"status-area"}>
                            <div className={"status-icon"} style={{backgroundImage : ImageUtil.background(statusIcon) }} />
                            <div className={"text-area"}>
                                <div className={"status-title"}>{statusTitle}</div>
                                {subtitleElem}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const showContent = !(snagNetworkInFlight || snag === null);

    let completeDateElem = [];
    if (snag !== null && snag.actionDeadline !== null) {
        completeDateElem = (
            <div className={"col-12 col-md-6 mt-2"}>
                <label>{Rosetta.string("snagging.snag_detail_complete_date")}</label>
                <ContentOrPlaceholder showContent={showContent}>
                    {Chronos.withTimestampSeconds(CommonUtil.getOrDefault(snag, "actionDeadline", 0)).format("dd/MM/yyyy HH:mm")}
                </ContentOrPlaceholder>
            </div>
        )
    }

    let actionDateElem = [];
    let actionedByElem = [];
    if (snag !== null && snag.actionedDate !== null) {
        actionDateElem = (
            <div className={"col-12 col-md-6 mt-2"}>
                <label>{Rosetta.string("snagging.snag_detail_actioned_date")}</label>
                <ContentOrPlaceholder showContent={showContent}>
                    {Chronos.withTimestampSeconds(CommonUtil.getOrDefault(snag, "actionedDate", 0)).format("dd/MM/yyyy HH:mm")}
                </ContentOrPlaceholder>
            </div>
        )

        actionedByElem = (
            <div className={"col-12 col-md-6 mt-2"}>
                <label>{Rosetta.string("snagging.snag_detail_actioned_by")}</label>
                <ContentOrPlaceholder showContent={showContent}>
                    {Rosetta.string("common.name_format", { given_name : CommonUtil.getOrDefault(snag, "actionedUserFirstName", ""), family_name : CommonUtil.getOrDefault(snag, "actionedUserSurname", "")}) }
                </ContentOrPlaceholder>
            </div>
        )
    }

    let photosElem = [];
    if (snag !== null && snag.images !== undefined && snag.images.length > 0) {
        let photoThumbs = [];
        for (let i = 0; i < snag.images.length; i++) {
            let image = snag.images[i];

            photoThumbs.push(
                <div className={"col-3 col-md-4 col-lg-3 col-xl-2"}>
                    <div
                        className={"ratio ratio-1x1 image-thumbnail clickable"}
                        style={{backgroundImage : ImageUtil.background(image.thumbPath)}}
                        onClick={(e) => requestLightbox(image.imagePath)} />
                </div>
            )
        }

        if (photoThumbs.length > 0) {
            photosElem = (
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("snagging.snag_detail_photos")}</label>
                    </div>
                    {photoThumbs}
                </div>
            )
        }
    }

    let usersTable = [];
    if (snag !== null && snag.users !== undefined && snag.users.length > 0) {
        usersTable = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <span className={"snag-title"}>{Rosetta.string("snagging.snag_detail_assigned_users")} <span className={"badge bg-primary"}>{snag.users.length}</span> </span>
                </div>
                <div className={"col-12 mt-1"}>
                    <div className={"table-container"}>
                        <TableComponent
                            data={snag.users}>

                            <Column name={"firstName"} title={Rosetta.string("user.first_name")} />
                            <Column name={"surname"} title={Rosetta.string("user.last_name")} />
                            <Column name={"userRoleName"} title={Rosetta.string("user.user_type")} />
                            <Column name={"emailAddress"} title={Rosetta.string("user.email")} />
                            <Column name={"company"} title={Rosetta.string("user.company")} />

                        </TableComponent>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"app-screen snag-drawing-snag-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("snagging.title")} />
                </div>
            </div>

            {statusElem}

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <div className={"snag-title"}>{Rosetta.string("snagging.snag_detail_title")}</div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("snagging.table_number")}</label>
                                    <ContentOrPlaceholder showContent={showContent}>
                                        {CommonUtil.getOrDefault(snag, "snagNumber", "")}
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("snagging.table_date_time")}</label>
                                    <ContentOrPlaceholder showContent={showContent}>
                                        {Chronos.withTimestampSeconds(CommonUtil.getOrDefault(snag, "dateCreated", 0)).format("dd/MM/yyyy HH:mm")}
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("snagging.table_location")}</label>
                                    <ContentOrPlaceholder showContent={showContent}>
                                        {CommonUtil.getOrDefault(snag, "locationName", "")}
                                    </ContentOrPlaceholder>
                                </div>

                                <div className={"col-12 col-md-6 mt-2"}>
                                    <label>{Rosetta.string("snagging.table_author")}</label>
                                    <ContentOrPlaceholder showContent={showContent}>
                                        {Rosetta.string("common.name_format", {
                                            given_name : CommonUtil.getOrDefault(snag, "userFirstName", ""),
                                            family_name : CommonUtil.getOrDefault(snag, "userFamilyName", "")
                                        })}
                                    </ContentOrPlaceholder>
                                </div>

                                {completeDateElem}

                                {actionDateElem}

                                {actionedByElem}

                                <div className={"col-12 mt-4"}>
                                    <button className={"btn btn-primary"} onClick={() => navigateToDrawing()}>{Rosetta.string("snagging.snag_drawing_view")}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <span className={"snag-title"}>{Rosetta.string("snagging.snag_detail_description")}</span>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <ContentOrPlaceholder showContent={showContent}>
                                        {CommonUtil.getOrDefault(snag, "description", "")}
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                            {photosElem}
                        </div>
                    </div>
                </div>
            </div>

            {usersTable}

            <Lightbox
                shown={lightboxShown}
                images={lightboxImages}
                initialImage={selectedImage}
                callback={lightboxDidCallback} />

        </div>
    )

}