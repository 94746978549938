import {ScreenTitle} from "../screenTitle/ScreenTitle";
import TableComponent, {Column} from "../../table/TableComponent";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {DataManager} from "../../../data/DataManager";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Chronos} from "../../../chronos/Chronos";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";

import "./SnagsDrawingSnagsScreen.css";
import {CommonUtil} from "../../../util/CommonUtil";
import {Navigator} from "../../../util/Navigator";

export const SnagsDrawingSnagsScreen = (props) => {

    const {drawingID} = useParams();

    const [drawing, setDrawing] = useState(null);
    const [drawingNetworkInFlight, setDrawingNetworkInFlight] = useState(false);

    const [actionedSnags, setActionedSnags] = useState([]);
    const [signOffSnags, setSignOffSnags] = useState([]);
    const [otherSnags, setOtherSnags] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchDrawingFromNetwork();
    }, []);

    function launchSnaggingDetail(snag) {
        if (snag) {
            Navigator.navigate("/snagging/snag/" + snag.id);
        }
    }

    function launchDrawing() {
        if (drawing) {
            Navigator.navigate("/drawing/view/" + drawing.id);
        }
    }

    function fetchDrawingFromNetwork() {
        if (drawingNetworkInFlight) return;
        setDrawingNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            id : drawingID,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawings, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.drawings.length > 0) {
                        setDrawing(resp.data.drawings[0]);
                        fetchSnagsFromNetwork();
                    } else {
                        showError("No Drawing by ID found.")
                    }
                } else {
                    showError(API.formatError(resp));
                }
                setDrawingNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDrawingNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown"));
            });
    }

    function fetchSnagsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectDrawingID : drawingID
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawingSnags, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let actioned = [];
                    let signOff = [];
                    let others = [];

                    for (let i = 0; i < resp.data.snags.length; i++) {
                        let snag = resp.data.snags[i];
                        if (snag.actionedDate === null) {
                            actioned.push(snag);
                        } else if (snag.signOffDate === null) {
                            signOff.push(snag);
                        } else {
                            signOff.push(snag);
                        }

                        setActionedSnags(actioned);
                        setSignOffSnags(signOff);
                        setOtherSnags(others);
                    }
                } else {
                    showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown"));
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    return (
        <div className={"app-screen snags-drawing-snags-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("snagging.snags_drawing_title")} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>

                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <div className={"drawing-title"}>
                                                <ContentOrPlaceholder showContent={drawing !== null}>
                                                    <>{CommonUtil.getOrDefault(drawing, "name", "")}</>
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>

                                        <div className={"col-12 col-md-6"}>
                                            <div className={"drawing-info mt-1"}>
                                                <ContentOrPlaceholder showContent={drawing !== null}>
                                                    <strong>{Rosetta.string("snagging.snag_drawing_date", {date : Chronos.withTimestampSeconds(CommonUtil.getOrDefault(drawing, "dateCreated", 0)).format("dd/MM/yyyy HH:mm")})}</strong>
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <div className={"drawing-info mt-1"}>
                                                <ContentOrPlaceholder showContent={drawing !== null}>
                                                    <>{Rosetta.string("snagging.snag_drawing_category", {category : CommonUtil.getOrDefault(drawing, "projectDrawingCategoryName", "")})}</>
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>

                                        <div className={"col-12 col-md-6"}>
                                            <div className={"drawing-info mt-1"}>
                                                <ContentOrPlaceholder showContent={drawing !== null}>
                                                    <>{Rosetta.string("snagging.snag_drawing_author", {name : Rosetta.string("common.name_format", {given_name : CommonUtil.getOrDefault(drawing, "userFirstName", ""), family_name : CommonUtil.getOrDefault(drawing, "userSurname", "")})})}</>
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div className={"drawing-info mt-2"}>
                                                <ContentOrPlaceholder showContent={drawing !== null}>
                                                    <>{CommonUtil.getOrDefault(drawing, "description", "")}</>
                                                </ContentOrPlaceholder>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div className={"drawing-info mt-2 text-center"}>
                                                <button className={"btn btn-primary"} onClick={() => launchDrawing()}>{Rosetta.string("snagging.snag_drawing_view")}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("snagging.snags_drawing_title_actioned")}</h3>
                </div>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            data={actionedSnags}
                            placeholderMode={drawingNetworkInFlight || networkInFlight}
                            placeholderRows={5}
                            onRowClick={launchSnaggingDetail}>

                            <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                            <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                            <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                            )} />

                        </TableComponent>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("snagging.snags_drawing_title_signed_off")}</h3>
                </div>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            data={signOffSnags}
                            placeholderMode={drawingNetworkInFlight || networkInFlight}
                            placeholderRows={5}
                            onRowClick={launchSnaggingDetail}>

                            <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                            <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                            <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                            )} />

                        </TableComponent>
                    </div>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("snagging.snags_drawing_title_others")}</h3>
                </div>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            data={otherSnags}
                            placeholderMode={drawingNetworkInFlight || networkInFlight}
                            placeholderRows={5}
                            onRowClick={launchSnaggingDetail}>

                            <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                            <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                            <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                            )} />

                        </TableComponent>
                    </div>
                </div>
            </div>

        </div>
    )

}