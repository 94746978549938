import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";
import { v4 as uuidv4 } from "uuid";
import {ProjectDocumentSectionEditorModal} from "./ProjectDocumentSectionEditorModal";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const ProjectDocumentSectionScreen = () => {

    let {id} = useParams();

    const [instance, setInstance] = useState(uuidv4());

    const [sections, setSections] = useState([]);
    const [sectionCount, setSectionCount] = useState(0);
    const [page, setPage] = useState(1);
    const [sectionNetworkInFlight, setSectionNetworkInFlight] = useState(false);

    const [documents, setDocuments] = useState([]);
    const [documentCount, setDocumentCount] = useState(0);
    const [documentPage, setDocumentPage] = useState(1);
    const [documentNetworkInFlight, setDocumentNetworkInFlight] = useState(false);

    const [sectionEditorShown, setSectionEditorShown] = useState(false);
    const [sectionEditorID, setSectionEditorID] = useState(undefined);


    useEffect(() => {
        getSectionsFromNetwork();
        getDocumentsFromNetwork();
    }, [])

    useEffect(() => {
        setInstance(uuidv4());
        getSectionsFromNetwork(1);
        getDocumentsFromNetwork(1);
    }, [id]);

    useEffect(() => {
        getSectionsFromNetwork();
    }, [page]);

    useEffect(() => {
        getDocumentsFromNetwork();
    }, [documentPage]);

    function onSectionTableRowClick(row) {
        if (row) {
            Navigator.navigate("/documents/" + row.id);
        }
    }

    function onDocumentTableRowClick(document) {
        if (document) {
            moveToDocumentEditor(document.id);
        }
    }

    function moveToDocumentEditor(docId) {
        if (docId === undefined) {
            docId = "new";
        }
        Navigator.navigate("/documents/view/" + id + "/" + docId);
    }

    function summonSectionEditor(id) {
        setSectionEditorID(id);
        setSectionEditorShown(true);
    }

    function sectionEditorDidCallback(action, data) {
        if (action === "close" || action === "dismiss") {
            setSectionEditorShown(false);
            getSectionsFromNetwork();
        }
    }

    function getSectionsFromNetwork(forcePage) {
        if (sectionNetworkInFlight) return;
        setSectionNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            fetchSections : false,
            page : (forcePage ? forcePage : page),
            pageSize : 20
        };

        if (id) {
            formData.parentSectionID = id;
        }

        Axios.post(ENDPOINTS.project.getSiteDocumentSections, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setSections(resp.data.sections);
                    setSectionCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDSS1000C" }));
            });
    }

    function getDocumentsFromNetwork(forcePage) {
        if (!id || documentNetworkInFlight) return;
        setDocumentNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            projectDocumentSectionID : id,
            page : forcePage ? forcePage : documentPage,
            pageSize : 20
        };

        Axios.post(ENDPOINTS.project.getProjectDocumentSection, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setDocuments(resp.data.documents);
                    setDocumentCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDSS2000C" }));
            });
    }

    // RENDER

    let documentTable = [];
    if (id) {
        documentTable = (
            <>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8"}>
                        <h4>{Rosetta.string("document_management.table_document_title")}</h4>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => moveToDocumentEditor()}>{Rosetta.string("document_management.action_add_document")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <TableComponent
                            data={documents}
                            className={"clickable"}
                            onRowClick={onDocumentTableRowClick}>

                            <Column name={"title"} title={Rosetta.string("document_management.table_document_name")} />
                            <Column name={"id"} title={Rosetta.string("document_management.table_document_versions")} className={"text-center"} render={(data, row) => (
                                <>{row.versions.length}</>
                            )} />
                            <Column name={"uploadDate"} title={Rosetta.string("document_management.table_document_created")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"id"} title={""} className={"text-end"} render={(data, row) => (
                                <button className={"btn btn-outline-primary"}>{Rosetta.string("common.view")}</button>
                            )} />

                        </TableComponent>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"app-screen project-document-section-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("document_management.title")} />
                </div>
            </div>

            <div className={"animate-screen-content row mt-4"} key={instance}>
                <div className={"row"}>
                    <div className={"col-12 col-md-8"}>
                        <h4>{Rosetta.string("document_management.table_section_title")}</h4>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => summonSectionEditor()}>{Rosetta.string("document_management.action_add_category")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={sections}
                                placeholderMode={sectionNetworkInFlight}
                                placeholderRows={5}
                                onRowClick={onSectionTableRowClick}>

                                <Column name={"title"} title={Rosetta.string("document_management.table_category")} />
                                <Column name={"sectionCount"} title={Rosetta.string("document_management.table_sections")} className={"text-center"} />
                                <Column name={"documentCount"} title={Rosetta.string("document_management.table_documents")} className={"text-center"} />
                                <Column name={"id"} title={""} className={"text-end"} render={(data, row) => (
                                    <button className={"btn btn-outline-primary"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); summonSectionEditor(row.id); } }>{Rosetta.string("common.edit")}</button>
                                )} />

                            </TableComponent>
                        </div>

                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={page}
                                totalCount={sectionCount}
                                pageSize={20}
                                onClick={setPage} />
                        </div>
                    </div>
                </div>

                {documentTable}
            </div>

            <ProjectDocumentSectionEditorModal
                shown={sectionEditorShown}
                sectionID={sectionEditorID}
                parentSectionID={id}
                callback={sectionEditorDidCallback} />
        </div>
    )

}