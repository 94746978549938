import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";

import "./CalendarEventModal.css";
import {useEffect, useState} from "react";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";

export const CalendarEventModal = (props) => {

    const {shown} = props;
    const {eventID} = props;
    const {forceDismiss} = props;

    const [event, setEvent] = useState();
    const [eventNetworkInFlight, setEventNetworkInFlight] = useState(false);

    useEffect(() => {
        if (shown) {
            fetchEventFromNetwork();
        } else {
            setEvent(null);
            setEventNetworkInFlight(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function fetchEventFromNetwork() {
        if (eventNetworkInFlight || !eventID) return;
        setEventNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            id : eventID,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.project.getUserCalendarEvents, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.events.length > 0) {
                        setEvent(resp.data.events[0]);
                    }
                } else {
                    showError(API.formatError(resp));
                }
                setEventNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setEventNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PCED1000C"}));
            })
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    if (!shown) return [];

    let userElem = [];
    if (event && event.hasOwnProperty("users") && event.users.length > 0) {
        userElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"mini-heading"}>{Rosetta.string("calendar.detail_users")}</div>
                    {event.users.map((user) => (
                        <span className={"user-widget"}>
                            {Rosetta.string("common.name_format", {given_name : user.firstName, family_name : user.surname})}
                        </span>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <Offcanvas
            shown={true}
            title={(event ? event.title : "")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"container calendar-event-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"mini-heading"}>{Rosetta.string("calendar.detail_date")}</div>
                        <div className={"date-container"}>
                            <div className={"text-container"}>
                                <ContentOrPlaceholder showContent={!eventNetworkInFlight}>
                                    {(event ? Chronos.withTimestampSeconds(event.eventDate).format("EEEE, dd MMMM yyyy") : "---")}
                                </ContentOrPlaceholder>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"mini-heading"}>{Rosetta.string("calendar.detail_title")}</div>
                        <div className={"date-container"}>
                            <div className={"text-container"}>
                                <ContentOrPlaceholder showContent={!eventNetworkInFlight}>
                                    {(event ? event.title : "")}
                                </ContentOrPlaceholder>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"mini-heading"}>{Rosetta.string("calendar.detail_description")}</div>
                        <div className={"text-container"}>
                            <ContentOrPlaceholder showContent={!eventNetworkInFlight}>
                                {(event ? event.description : "")}
                            </ContentOrPlaceholder>
                        </div>
                    </div>
                </div>

                {userElem}

            </div>

        </Offcanvas>
    )

}