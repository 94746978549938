import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import Validator from "../../../util/Validator";
import AlertModal from "../../alertmodal/AlertModal";

export const ProjectDocumentSelectionModal = (props) => {

    const TAB_UPLOAD = 1;
    const TAB_LINK = 2;

    const fileInput = useRef();

    const {projectID} = props;
    const {document} = props;

    const [documentTypes, setDocumentTypes] = useState([]);
    const [docTypesNetworkInFlight, setDocTypesNetworkInFlight] = useState(false);

    const [documentTypeID, setDocumentTypeID] = useState("-1");
    const [newDocumentTypeName, setNewDocumentTypeName] = useState("");
    const [active, setActive] = useState("1");

    const [selectedTab, setSelectedTab] = useState(TAB_UPLOAD);
    const [uploadFile, setUploadFile] = useState(undefined);
    const [fileUrl, setFileUrl] = useState("");
    const [uploadNetworkInFlight, setUploadNetworkInFlight] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(false);

    useEffect(() => {
        if (props.shown) {
            WindowUtil.lockBodyScroll();

            fetchDocumentTypesOverNetwork();

            if (document) {
                setDocumentTypeID(document.id);
                setActive(document.active);
            }
        } else {
            WindowUtil.unlockBodyScroll();

            // Reset state
            setSelectedTab(TAB_UPLOAD);
            setFileUrl("");
            setUploadFile(undefined);
            setErrorMessage(null);
            setForceDismiss(false);
            setCallbackData(false);

            setDocumentTypes([]);
            setDocumentTypeID(null);
            setNewDocumentTypeName("");
        }
    }, [props.shown]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action, callbackData);
        }
    }

    function onTabSelected(action, id) {
        if (action === "select") {
            setSelectedTab(id);
        }
    }

    function onFileChanged(e) {
        if (e.target.files !== undefined && e.target.files.length > 0) {
            setUploadFile(e.target.files[0]);
        }
    }

    function submitForm() {
        if (!document) {
            if (selectedTab === TAB_UPLOAD) {
                if (uploadFile !== undefined) {
                    uploadFileOverNetwork();
                }
            } else {
                if (fileUrl !== "") {
                    uploadFileOverNetwork();
                }
            }
        } else {
            uploadFileOverNetwork();
        }
    }

    function uploadFileOverNetwork() {
        if (uploadNetworkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            id : (document ? document.documentId : undefined),
            projectID, documentTypeID, newDocumentTypeName, active
        }, [
            Validator.rule("id", "int", "id", "id", true),
            Validator.rule("projectID", "int", "Project ID", "projectID"),
            Validator.rule("documentTypeID", "int", Rosetta.string("site_information.document_change_upload_type"), "documentTypeID"),
            Validator.rule("newDocumentTypeName", "string", Rosetta.string("site_information.document_change_upload_type_name"), "newDocumentTypeName", documentTypeID !== "-1"),
            //Validator.rule("active", "int", Rosetta.string("site_information.document_active"), "active", true)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setUploadNetworkInFlight(true);

        let formData = validationResult.formData;

        // let formData = new FormData();
        // formData.append("projectID", projectID);
        // formData.append("documentTypeID", documentTypeID);
        // formData.append("newDocumentTypeName", newDocumentTypeName);
        // formData.append("active", active);
        // if (document) {
        //     formData.append("id", document.documentId);
        // }

        if (selectedTab === TAB_UPLOAD) {
            formData.append("file", uploadFile);
        } else {
            formData.append("fileUrl", fileUrl);
        }

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);
                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.project.uploadProjectDocument, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("site_information.document_change_upload_successful_upload"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(true); // Let caller know whatever happened was a success
                    setForceDismiss(true); // Dismiss modal now
                } else {
                    setErrorMessage(API.formatError(resp));
                }
                setUploadNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setErrorMessage(Rosetta.string("common.error_common_unknown", {error_code : "PDU1000C"}));
                setUploadNetworkInFlight(false);
            });
    }

    function fetchDocumentTypesOverNetwork() {
        if (docTypesNetworkInFlight) return;
        setDocTypesNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let data = {
            clientID : project ? project.clientID : undefined
        };

        Axios.post(ENDPOINTS.project.getProjectDocumentTypes, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let types = resp.data.data;

                    setDocumentTypes(types);

                    if (!documentTypeID && types.length > 0) {
                        setDocumentTypeID(types[0].id);
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setDocTypesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocTypesNetworkInFlight(false);
            });
    }

    if (!props.shown) return [];

    let bodyContent = [];
    if (selectedTab === TAB_UPLOAD) {
        let filenameDisplay = Rosetta.string("site_information.document_change_upload_nothing_selected");
        if (uploadFile !== undefined) {
            let sizeMb = (uploadFile.size / 1024) / 1024;
            if (sizeMb < 1) {
                sizeMb = sizeMb.toFixed(2);
            } else {
                sizeMb = Math.round(sizeMb).toFixed(0);
            }

            filenameDisplay = uploadFile.name + " (" + sizeMb + "mb)";
        }

        let uploadArea = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={submitForm}>{Rosetta.string("site_information.document_change_upload")}</button>
                </div>
            </div>
        );

        if (uploadNetworkInFlight) {
            uploadArea = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"progress"}>
                            <div className={"progress-bar bg-warning"} style={{width : uploadProgress + "%"}} />
                        </div>
                    </div>
                </div>
            )
        }

        bodyContent = (
            <>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col-4 text-center"}>
                                        <button className={"btn btn-primary"} onClick={() => fileInput.current.click()}>{Rosetta.string("site_information.document_change_upload_select_file")}</button>
                                        <div className={"file-hide"}>
                                            <input type={"file"} ref={fileInput} accept=".pdf" onChange={onFileChanged} />
                                        </div>
                                    </div>
                                    <div className={"col-8"}>
                                        {filenameDisplay}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {uploadArea}
            </>

        )
    } else if (selectedTab === TAB_LINK) {
        bodyContent = (
            <>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("site_information.document_change_link_label")}</label>
                        <input type={"text"} className={"form-control"} value={fileUrl} onChange={(e) => setFileUrl(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"alert bg-warning"}>{Rosetta.string("site_information.document_change_link_notice")}</div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} onClick={submitForm}>{Rosetta.string("common.submit")}</button>
                    </div>
                </div>
            </>
        )
    }

    let errorElem = [];
    if (errorMessage !== null) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert bg-danger"}>{errorMessage}</div>
                </div>
            </div>
        );
    }

    let newTypeElem = [];
    if (documentTypeID === "-1") {
        newTypeElem = (
            <div className={"col-12 mt-2"}>
                <label>{Rosetta.string("site_information.document_change_upload_type_name")}</label>
                <input type={"text"} className={"form-control"} value={newDocumentTypeName} onChange={(e) => setNewDocumentTypeName(e.target.value)} />
            </div>
        );
    }

    return (
        <Offcanvas
            title={Rosetta.string("site_information.document_change")}
            gravity={OffcanvasGravity.END}
            shown={true}
            forceDismiss={forceDismiss}
            allowDismiss={!uploadNetworkInFlight}
            callback={handleCallback}>

            <div className={"project-document-selection-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("site_information.document_change_upload_type")}</label>
                        <select className={"form-select"} value={documentTypeID} onChange={(e) => setDocumentTypeID(e.target.value)}>
                            {
                                documentTypes.map((type) => (
                                    <option value={type.id}>{type.title}</option>
                                ))
                            }
                            <option value={"-1"}>{Rosetta.string("site_information.document_change_upload_type_new")}</option>
                        </select>
                    </div>

                    {newTypeElem}
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <SegmentTabBar selected={selectedTab} callback={onTabSelected}>
                            <SegmentTab id={TAB_UPLOAD} label={Rosetta.string("site_information.document_change_upload")} />
                            <SegmentTab id={TAB_LINK} label={Rosetta.string("site_information.document_change_link")} />
                        </SegmentTabBar>
                    </div>
                </div>

                {bodyContent}

                {errorElem}

            </div>

        </Offcanvas>
    )

    /*
    <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("site_information.document_active")}</label>
                        <select className={"form-select"} value={active} onChange={(e) => setActive(e.target.value)}>
                            <option value={"0"}>{Rosetta.string("common.no")}</option>
                            <option value={"1"}>{Rosetta.string("common.yes")}</option>
                        </select>
                    </div>
                </div>
     */

}