import {useEffect, useState} from "react";
import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import Rosetta from "../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export const UserRoleSelectionModal = (props) => {

    const {shown} = props;
    const {title} = props;
    const {message} = props;

    const [userRoles, setUserRoles] = useState([]);
    const [roleNetworkInFlight, setRoleNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    const [selectedRoleID, setSelectedRoleID] = useState(null);

    useEffect(() => {
        if (shown) {
            fetchUserRolesOverNetwork()
        } else {
            setUserRoles([]);
            setRoleNetworkInFlight(false);
            setForceDismiss(false);
            setSelectedRoleID(null);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function fetchUserRolesOverNetwork() {
        if (roleNetworkInFlight) return;
        setRoleNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getUserRoleTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUserRoles(resp.data.userRoles);

                    if (selectedRoleID === null) {
                        if (resp.data.userRoles.length > 0) {
                            setSelectedRoleID(resp.data.userRoles[0].id);
                        }
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setRoleNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setRoleNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let titleElem = [];
    if (title !== undefined) {
        titleElem = (<div className={"alert-modal-dialog-title"}>{title}</div>);
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            {titleElem}
            <div className={"alert-modal-dialog-message"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        {message}
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <label>{Rosetta.string("user.select_role")}</label>
                    <select className={"form-select"} value={selectedRoleID} onChange={(e) => setSelectedRoleID(e.target.value)}>
                        {
                            userRoles.map((role) => (
                                <option value={role.id}>{role.name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className={"alert-modal-dialog-buttons"}>
                <button className={"alert-dialog-button success"} onClick={() => handleCallback(BaseModalActions.CLOSE, selectedRoleID)}>{Rosetta.string("common.ok")}</button>
                <button className={"alert-dialog-button"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>{Rosetta.string("common.cancel")}</button>
            </div>

        </BaseModal>
    )

}