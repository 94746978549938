import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";

import "./SubscriptionCompanyDetailsComponent.css";
import AlertModal from "../../alertmodal/AlertModal";
import {CommonUtil} from "../../../util/CommonUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export const SubscriptionCompanyDetailsComponent = (props) => {

    const {step} = props;
    const {formData} = props;

    const {subscriptionType} = props;
    const {sku} = props;

    const [formState, setFormState] = useState({});
    const [numUsers, setNumUsers] = useState(1);
    const [subscriptionSku, setSubscriptionSku] = useState(null);

    const standardFormFields = [
        { keyName : "companyName", label : "subscription.company_name" },
        { keyName : "companyAddress1", label : "subscription.company_address_line1" },
        { keyName : "companyAddress2", label : "subscription.company_address_line2" },
        { keyName : "companyCity", label : "subscription.company_address_city" },
        { keyName : "companyCounty", label : "subscription.company_address_county" },
        { keyName : "companyPostcode", label : "subscription.company_address_postcode" },
        { keyName : "companyPhone", label : "subscription.company_address_phone" }
    ];

    useEffect(() => {
        if (formData) {
            let newFormState = {};

            for (let i = 0; i < standardFormFields.length; i++) {
                let keyname = standardFormFields[i].keyName
                if (formData[keyname] !== undefined) {
                    newFormState[keyname] = formData[keyname];
                }
            }

            if (formData.numUsers !== undefined) {
                newFormState.numUsers = formData.numUsers;
            }

            if (formData.subscriptionSku !== undefined) {
                newFormState.subscriptionSku = formData.subscriptionSku;
            }

            setFormState(newFormState);
        }
    }, []);

    useEffect(() => {
        let newSku = null;

        if (subscriptionType.hasOwnProperty("skus") && subscriptionType.skus.length > 0) {
            if (sku) {
                for (let i = 0; i < subscriptionType.skus.length; i++) {
                    if (subscriptionType.skus[i].sku === sku) {
                        newSku = subscriptionType.skus[i];
                    }
                }
            }

            if (!newSku) {
                newSku = subscriptionType.skus[0];
            }
        }

        setSubscriptionSku(newSku);
    }, [subscriptionType]);

    function handleCallback(action,data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function submitForm() {
        let outData = {
            step
        };

        for (let i = 0; i < standardFormFields.length; i++) {
            let data = getFormData(standardFormFields[i].keyName, null);
            if (data !== null && data !== "") {
                outData[standardFormFields[i].keyName] = data;
            } else {
                AlertModal.showError(Rosetta.string("validation.missing_property", { label : Rosetta.string(standardFormFields[i].label) }));
                return false;
            }
        }

        if (parseInt(subscriptionType.id) === SubscriptionUtil.TYPES.BUSINESS) {
            outData.numUsers = numUsers;
            outData.subscriptionSku = subscriptionSku;
        }

        handleCallback("submit", outData);
    }

    function getFormData(keyName, defaultValue) {
        if (!defaultValue) {
            defaultValue = "";
        }

        if (formState) {
            if (formState.hasOwnProperty(keyName)) {
                return formState[keyName];
            }
        }

        return defaultValue;
    }

    function setFormData(keyName, value) {
        let newState = {};
        if (formState) {
            newState = CommonUtil.cloneObject(formState);
        }
        newState[keyName] = value;

        setFormState(newState);
    }

    function getUserNumberOptions(maxNum) {
        let out = [];
        if (maxNum === undefined) {
            maxNum = 1000;
        }
        for (let i = 1; i < maxNum; i++) {
            out.push(<option value={i}>{i}</option>);
        }
        return out;
    }

    function selectSku(sku) {
        if (subscriptionType.hasOwnProperty("skus") && subscriptionType.skus.length > 0) {
            for (let i = 0; i < subscriptionType.skus.length; i++) {
                if (subscriptionType.skus[i].sku === sku) {
                    setSubscriptionSku(subscriptionType.skus[i]);
                    break;
                }
            }
        }
    }

    let skuPrice = "£0";
    let skuTotal = "£0";
    let skuDuration = "";
    if (subscriptionSku) {
        skuPrice = (subscriptionSku.price / 100).toFixed(2);
        skuTotal = (skuPrice * numUsers).toFixed(2);
        skuDuration = SubscriptionUtil.parseDuration(subscriptionSku.cycleDuration);

        skuPrice = "£" + skuPrice.toLocaleString();
        skuTotal = "£" + skuTotal.toLocaleString();
    }

    let rightmostContent = [];
    if (parseInt(subscriptionType.id) === SubscriptionUtil.TYPES.FREE) {
        rightmostContent = (
            <div className={"summary-box"}>
                <div className={"summary-title"}>{Rosetta.string("subscription.subscription_details_title")}</div>
                <div className={"summary-subtitle mt-2"}>
                    <span className={"emphasis"}>&pound;0</span> {Rosetta.string("subscription.subscription_details_free", {duration : Rosetta.string("time.days", {days : 14}, 14)})}
                </div>
                <div className={"summary-subtitle mt-2"}>{Rosetta.string("subscription.subscription_details_free_per", {count : 1})}</div>

                <div className={"summary-fineprint mt-2"}>{Rosetta.string("subscription.subscription_details_free_fineprint", {duration : Rosetta.string("time.days", {days : 14}, 14)})}</div>
            </div>
        )
    } else if (parseInt(subscriptionType.id) === SubscriptionUtil.TYPES.BUSINESS) {
        rightmostContent = (
            <>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.company_users")}</label>
                        <select className={"form-select"} value={numUsers} onChange={(e) => setNumUsers(e.target.value)}>
                            {getUserNumberOptions()}
                        </select>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("subscription.subscription_type")}</label>
                        <select className={"form-select"} value={subscriptionSku ? subscriptionSku.sku : ""} onChange={(e) => { selectSku(e.target.value) }}>
                            {
                                (subscriptionType ? subscriptionType.skus : []).map((sku) => (
                                    <option value={sku.sku}>{sku.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"summary-box"}>
                            <div className={"summary-title"}>{Rosetta.string("subscription.subscription_details_title")}</div>
                            <div className={"summary-text"}>{Rosetta.string("subscription.subscription_details_business", { user_count : numUsers, price : skuPrice, duration : skuDuration })}</div>
                            <div className={"summary-subtitle"}>{Rosetta.string("subscription.subscription_details_per", { price : skuTotal, duration : skuDuration })}</div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

    return (
        <div className={"animate-screen-content subscription-company-details-component"}>
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    {
                                        standardFormFields.map((field) => (
                                            <div>
                                                <label>{Rosetta.string(field.label)}</label>
                                                <input type={"text"} className={"form-control"} value={getFormData(field.keyName)} onChange={(e) => setFormData(field.keyName, e.target.value)} />
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    {rightmostContent}
                                </div>
                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col-12 text-center"}>
                                    <button className={"btn btn-primary"} onClick={submitForm}>{Rosetta.string("common.next")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}