import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {EmergingButton} from "../../button/EmergingButton";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {CommonUtil} from "../../../util/CommonUtil";
import {createRef, useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {DocumentWidget} from "./DocumentWidget";
import {Navigator} from "../../../util/Navigator";
import {TextInputModal, TextInputModalActions} from "../../alertmodal/TextInputModal";
import {Bouncer} from "../../../util/Bouncer";
import AlertModal from "../../alertmodal/AlertModal";

export const ProjectInformationScreen = (props) => {

    const [project, setProject] = useState();
    const [projectNetworkInFlight, setProjectNetworkInFlight] = useState(false);

    const [exportNetworkInFlight, setExportNetworkInFlight] = useState(false);
    const [signInPDFNetworkInFlight, setSignInPDFNetworkInFlight] = useState(false);

    const [textInputShown, setTextInputShown] = useState(false);

    useEffect(() => {
        Bouncer.checkProjectSelected();

        let project = DataManager.getSelectedProject();
        if (project !== null) {
            fetchSiteDetailsFromNetwork(project.id);
        }
    }, []);

    function moveToEditor(projectID) {
        if (projectID === undefined) {
            projectID = project.id;
        }

        Navigator.navigate("/project/" + projectID);
    }

    function requestProjectArchival() {
        setTextInputShown(true);
    }

    function textInputDidCallback(action, data) {
        if (action === TextInputModalActions.SUBMIT) {
            console.log(data.toLowerCase() + " :: " + project.project_name.toLowerCase() + " " + (data.toLowerCase() === project.project_name.toLowerCase()));
            if (data.toLowerCase() === project.project_name.toLowerCase()) {
                submitProjectActiveOverNetwork();
            }
        }
        setTextInputShown(false);
    }

    function getHospitalAddressForMaps() {
        if (project) {
            if (project.hospital_lat !== null && project.hospital_lon !== null) {
                return project.hospital_lat + "," + project.hospital_lon;
            } else {
                let addressParts = [
                    project.hospital_address1,
                    project.hospital_address2,
                    project.hospital_town,
                    project.hospital_county,
                    project.hospital_postcode
                ];

                for (let i = 0; i < addressParts.length; i++) {
                    if (addressParts[i] !== null) {
                        addressParts[i] = encodeURI(addressParts[i]);
                    }
                }

                return CommonUtil.implode(addressParts);
            }
        }
        return "";
    }

    function promptForQRCodeOptions() {
        AlertModal.showModal(
            Rosetta.string("site_information.export_sign_in"),
            Rosetta.string("site_information.export_sign_in_message"),
            [
                AlertModal.button(
                    Rosetta.string("site_information.export_sign_in_new"),
                    () => {
                        AlertModal.dismissModal();
                        getSignInPDFOverNetwork(true);
                    }, "danger"
                ),
                AlertModal.button(
                    Rosetta.string("site_information.export_sign_in_keep"),
                    () => {
                        AlertModal.dismissModal();
                        getSignInPDFOverNetwork(false);
                    }, "success"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal()
                    }
                )
            ]
        );
    }

    function exportWasRequested() {
        if (exportNetworkInFlight) return;
        setExportNetworkInFlight(true);

        let formData = {
            projectID : project ? project.id : undefined
        };

        Axios.post(ENDPOINTS.project.exportProjectData, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.downloadUri) {
                        window.location.href = resp.data.downloadUri;
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("site_information.export_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    } else {
                        Toast.show(
                            Rosetta.string("common.error"),
                            Rosetta.string("site_information.export_failure"),
                            Toast.ERROR,
                            Toast.LONG
                        );
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setExportNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setExportNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PIE1000C" }));
            });
    }

    function fetchSiteDetailsFromNetwork(projectID) {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            projectID
        };

        Axios.post(ENDPOINTS.project.fetchSiteInfo, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setProject(resp.data.siteInfo);
                } else {
                    showError(API.formatError(resp));
                }
                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", { error_code : "PD1000C" }));
                setProjectNetworkInFlight(false);
            });
    }

    function submitProjectActiveOverNetwork() {
        if (!project || projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            projectID : project.id,
            status : 0
        };

        Axios.post(ENDPOINTS.project.setProjectArchiveStatus, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let message = "project.action_archive_success";

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(message),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    // Clear Project and move to Select Project screen
                    DataManager.setSelectedProject(null);
                    Navigator.navigate("/select-project");
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "PIA1000C"}));
                setProjectNetworkInFlight(false);
            });
    }

    function getSignInPDFOverNetwork(createCode) {
        if (createCode === undefined) {
            createCode = false;
        }

        if (signInPDFNetworkInFlight) return;
        setSignInPDFNetworkInFlight(true);

        let data = {
            projectID : project.id,
            createCode
        };

        Axios.post(ENDPOINTS.project.getProjectSignInQR, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.downloadUri) {
                        window.location.href = resp.data.downloadUri;
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("site_information.export_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );
                    } else {
                        Toast.show(
                            Rosetta.string("common.error"),
                            Rosetta.string("site_information.export_failure"),
                            Toast.ERROR,
                            Toast.LONG
                        );
                    }
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setSignInPDFNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSignInPDFNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "PSID1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                );
            })
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    let documentWidgets = [];
    let addressParts = [];
    if (!projectNetworkInFlight) {
        if (project) {
            if (project.site_documents !== undefined && project.site_documents.length > 0) {
                documentWidgets = project.site_documents.map((document) => (
                    <div className={"col-4 col-lg-3 mb-2"}>
                        <DocumentWidget document={document}/>
                    </div>
                ))
            } else {
                // TODO Empty message
            }

            let addressFields = [
                project.address1,
                project.address2,
                project.town,
                project.city,
                project.postcode
            ];

            addressFields.forEach((field) => {
                if (field !== null && field !== "") {
                    addressParts.push(field);
                }
            });
        }
    } else {
        for (let i = 0; i < 6; i++) {
            documentWidgets.push(
                <DocumentWidget />
            )
        }
    }

    let exportButton = (
        <button className={"btn btn-primary"} onClick={() => exportWasRequested()}>{Rosetta.string("site_information.action_export")}</button>
    );
    if (exportNetworkInFlight) {
        exportButton = (
            <button className={"btn btn-primary disabled"}>{Rosetta.string("site_information.action_exporting")}</button>
        )
    }

    let signInExportButton = (<button className={"btn btn-primary"} onClick={() => promptForQRCodeOptions()}>{Rosetta.string("site_information.export_sign_in")}</button>)
    if (signInPDFNetworkInFlight) {
        signInExportButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("site_information.action_exporting")}</button>)
    }

    return (
        <div className={"app-screen project-information-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-6"}>
                    <ScreenTitle title={Rosetta.string("site_information.title")} />
                </div>
                <div className={"col-12 col-md-6 screen-actions"}>
                    {exportButton}

                    <EmergingButton pill={true} className={"ms-1"}>
                        <button className={"btn"} onClick={() => requestProjectArchival()}>{Rosetta.string("site_information.action_archive")}</button>
                    </EmergingButton>

                    <EmergingButton pill={true} className={"ms-1"}>
                        <button className={"btn"} onClick={() => moveToEditor()}>{Rosetta.string("site_information.action_edit")}</button>
                    </EmergingButton>
                </div>
            </div>

            <div className={"row mt-2 animate-screen-content"}>
                <div className={"col-12 col-md-6"}>
                    <div className={"card"}>
                        <div className="card-header">
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <h4>{Rosetta.string("site_information.details_title")}</h4>
                                </div>
                            </div>
                        </div>

                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>

                                    <div>
                                        <label>{Rosetta.string("site_information.details_code")}</label>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight}>
                                                <span>{CommonUtil.getOrDefault(project, "project_code", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"mt-2"}>
                                        <label>{Rosetta.string("site_information.details_name")}</label>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight}>
                                                <span>{CommonUtil.getOrDefault(project, "project_name", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                    <div className={"mt-2"}>
                                        <label>{Rosetta.string("site_information.details_address")}</label>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "address1", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "address2", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "town", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "city", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "county", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                        <div>
                                            <ContentOrPlaceholder showContent={!projectNetworkInFlight} className={"mb-1"}>
                                                <span>{CommonUtil.getOrDefault(project, "postcode", "")}</span>
                                            </ContentOrPlaceholder>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"col-12 col-md-6"}>

                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4>{Rosetta.string("site_information.description_title")}</h4>
                        </div>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <ContentOrPlaceholder
                                        showContent={!projectNetworkInFlight}
                                        placeholder={<><PlaceholderText className={"mb-1"} /><PlaceholderText className={"mb-1"} /><PlaceholderText /></>}>
                                        <span>{CommonUtil.getOrDefault(project, "short_description", Rosetta.string("site_information.description_empty"))}</span>
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 text-end"}>
                                    {signInExportButton}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"card mt-2"}>
                        <div className={"card-header"}>
                            <h4>{Rosetta.string("site_information.hospital_title")}</h4>
                        </div>
                        <div className={"card-body"}>
                            <div className={"mb-1"}>
                                <label>{Rosetta.string("site_information.hospital_ae_name")}</label>
                                <ContentOrPlaceholder showContent={!projectNetworkInFlight}>
                                    <div>{CommonUtil.getOrDefault(project, "hospital_name", "")}</div>
                                </ContentOrPlaceholder>
                            </div>

                            <div className={"mb-1"}>
                                <label>{Rosetta.string("site_information.hospital_ae_postcode")}</label>
                                <ContentOrPlaceholder showContent={!projectNetworkInFlight}>
                                    <div>{CommonUtil.getOrDefault(project, "hospital_postcode", "")}</div>
                                </ContentOrPlaceholder>
                            </div>

                            <div className={"mb-1"}>
                                <label>{Rosetta.string("site_information.hospital_ae_phone")}</label>
                                <ContentOrPlaceholder showContent={!projectNetworkInFlight}>
                                    <div>{CommonUtil.getOrDefault(project, "hospital_telephone", "")}</div>
                                </ContentOrPlaceholder>
                            </div>

                            <div className={"mb-1 text-end"}>
                                <a className={"btn btn-primary"} target={"_blank"} href={CommonUtil.getGoogleMapsLink(getHospitalAddressForMaps())}>{Rosetta.string("site_information.hospital_view_map")}</a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-header"}>
                            <h4>{Rosetta.string("site_information.documents_title")}</h4>
                        </div>

                        <div className={"card-body"}>
                            <div className={"row"}>
                                {documentWidgets}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TextInputModal
                shown={textInputShown}
                title={Rosetta.string("site_information.archive_title")}
                message={Rosetta.string("site_information.archive_message", { project_name : (project) ? project.project_name : "" })}
                callback={textInputDidCallback} />

        </div>
    )

}