import {useEffect, useState} from "react";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import Rosetta from "../../../rosetta/Rosetta";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {FilterText} from "../../filter/FilterText";

export const ProjectSelectionModal = (props) => {

    const {shown} = props;
    const {gravity} = props;

    const [projects, setProjects] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
            getFormsFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();
            setProjects([]);
            setTotalCount(0);
            setCurrentPage(1);
            setKeyword("");
            setForceDismiss(false);
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        getFormsFromNetwork();
    }, [currentPage]);

    useEffect(() => {
        setProjects([]);
        setTotalCount(0);
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            getFormsFromNetwork(true);
        }
    }, [keyword]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action, callbackData);
        }
    }

    function selectUser(user) {
        setCallbackData(user);
        setForceDismiss(true);
    }

    function getFormsFromNetwork(reset) {
        if (!shown || networkInFlight) return;
        setNetworkInFlight(true);

        if (reset === undefined) {
            reset = false;
        }

        let formData = {
            keyword,
            page : currentPage
        };

        Axios.post(ENDPOINTS.project.getProjects, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let newData = resp.data.projects;
                    if (!reset) {
                        newData = [...projects];
                        for (let i = 0; i < resp.data.projects.length; i++) {
                            newData.push(resp.data.projects[i]);
                        }
                    }

                    setProjects(newData);
                    setTotalCount(resp.data.count);
                } else {
                    // TODO Error?
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                // TODO Error?
                setNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let clientContent = [];
    if (projects.length > 0) {
        for (let i = 0; i < projects.length; i++) {
            clientContent.push(
                <span className="list-group-item clickable" onClick={() => selectUser(projects[i])}>
                    <strong>{projects[i].project_name}</strong><br />
                    <span>{projects[i].clientName}</span>
                </span>
            )
        }
    }

    let loadMoreButton = [];

    if (totalCount > projects.length) {
        loadMoreButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setCurrentPage(currentPage + 1)}>{Rosetta.string("common.load_more")}</button>
                </div>
            </div>
        );
    }

    if (networkInFlight) {
        loadMoreButton = [];

        for (let i = 0; i < 3; i++) {
            clientContent.push(
                <span className="list-group-item">
                    <PlaceholderText />
                </span>
            )
        }
    }

    if (clientContent.length > 0) {
        clientContent = (
            <div className="list-group">
                {clientContent}
            </div>
        );
    } else {
        clientContent = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        );
    }

    return (
        <Offcanvas
            title={Rosetta.string("project.title")}
            shown={true}
            gravity={(gravity !== undefined) ? gravity : OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    {clientContent}
                </div>
            </div>

            {loadMoreButton}

        </Offcanvas>
    )

}