import React from 'react';

import './AlertModal.css';
import Rosetta from "../../rosetta/Rosetta";
import WindowUtil from "../../util/WindowUtil";

export default class AlertModal extends React.Component {

    keyEvent = (e) => {
        console.log(e.key);
        if (e.key === " " || e.key.toLowerCase() === "enter") {
            let didAction = false;
            if (this.props.buttons !== undefined) {
                if (this.props.buttons.length > 0) {
                    this.props.buttons[0].click();
                    didAction = true;
                }
            }

            if (!didAction) {
                this.dismiss();
            }
        } else if (e.key === "Escape") {
            this.dismiss();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("ALERT MODAL UPDATE");
        if (prevProps.open !== this.props.open) {
            if (this.props.open) {
                WindowUtil.lockBodyScroll();
                window.addEventListener("keypress", this.keyEvent);
                document.body.focus();
            } else {
                WindowUtil.unlockBodyScroll();
                window.removeEventListener("keypress", this.keyEvent);
            }
        }
    }

    static showModal = (title, message, buttons, icon) => {
        window._showModal(title, message, buttons, icon);
    }

    static showError = (message) => {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    static dismissModal = () => {
        window._dismissModal();
    }

    static button(label, callback, className) {
        return {
            label,
            click : callback,
            className
        };
    }

    dismiss = () => {
        if (this.props.dismissCallback !== undefined) {
            this.props.dismissCallback();
        }
    }

    render() {
        let open = false;
        if (this.props.open !== undefined) {
            open = this.props.open;
        }

        if (open) {
            let icon = [];
            if (this.props.icon !== undefined) {
                icon = (
                    <div className={"alert-modal-dialog-icon"} style={{backgroundImage : "url(" + this.props.icon + ")"}} />
                );
            }

            let title = [];
            if (this.props.title !== undefined) {
                title = (
                    <div className={"alert-modal-dialog-title"}>{this.props.title}</div>
                );
            }

            let message = "";
            if (this.props.message !== undefined) {
                message = this.props.message;
            }

            let buttons = [
                {
                    label : Rosetta.string("common.ok"),
                    click : () => {
                        this.dismiss();
                    }
                }
            ];
            if (this.props.buttons !== undefined) {
                buttons = this.props.buttons;
            }

            return (
                <div className={"alert-modal"}>
                    <div className={"spacer"} />

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"hidden-xs col-md-3 col-lg-4"} />
                            <div className={"col-12 col-md-6 col-lg-4"}>
                                <div className={"alert-modal-dialog"}>
                                    {icon}
                                    {title}
                                    <div className={"alert-modal-dialog-message"}>{message}</div>
                                    <div className={"alert-modal-dialog-buttons"}>
                                        {buttons.map((button, index) => {
                                            let className = "alert-dialog-button";
                                            if (button.hasOwnProperty("className")) {
                                                className += " " + button.className;
                                            }

                                            return (
                                                <div className={className} onClick={button.click}>{button.label}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />
                </div>
            );
        }
        return [];
    }
}