import Rosetta from "../../../rosetta/Rosetta";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {OrganiserNoteEditorModal} from "./OrganiserNoteEditorModal";

import "./OrganiserTodoComponent.css";
import {Toast} from "../../toast/TokyoToaster";
import {OrganiserNoteScheduleModal} from "./OrganiserNoteScheduleModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {EventUtil} from "../../../util/EventUtil";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const OrganiserTodoComponent = (props) => {

    const TYPE_ACTIVE = 1;
    const TYPE_COMPLETE = 2;

    const [activeTodos, setActiveTodos] = useState([]);
    const [activeTodosCount, setActiveTodosCount] = useState(0);
    const [activeTodosPage, setActiveTodosPage] = useState(1);
    const [activeTodosNetworkInFlight, setActiveTodosNetworkInFlight] = useState(false);

    const [completeTodos, setCompleteTodos] = useState([]);
    const [completeTodosCount, setCompleteTodosCount] = useState(0);
    const [completeTodosPage, setCompleteTodosPage] = useState(1);
    const [completeTodosNetworkInFlight, setCompleteTodosNetworkInFlight] = useState(false);

    const [noteEditorShown, setNoteEditorShown] = useState(false);
    const [noteEditorID, setNoteEditorID] = useState(null);

    const [noteSchedulerShown, setNoteSchedulerShown] = useState(false);
    const [noteSchedulerNote, setNoteSchedulerNote] = useState(null);

    const [completeNetworkInFlight, setCompleteNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchTodosFromNetwork(TYPE_ACTIVE);
        fetchTodosFromNetwork(TYPE_COMPLETE);
    }, []);

    useEffect(() => {
        fetchTodosFromNetwork(TYPE_ACTIVE);
    }, [activeTodosPage])

    useEffect(() => {
        fetchTodosFromNetwork(TYPE_COMPLETE);
    }, [completeTodosPage]);

    function showNoteEditor(id) {
        setNoteEditorID(id);
        setNoteEditorShown(true);
    }

    function noteEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setNoteEditorShown(false);

            if (data) {
                Toast.show(
                    Rosetta.string("common.success"),
                    Rosetta.string("common.save_confirmation"),
                    Toast.SUCCESS,
                    Toast.LONG
                );

                fetchTodosFromNetwork(TYPE_ACTIVE);
                fetchTodosFromNetwork(TYPE_COMPLETE);
            }
        }
    }

    function promptToDeleteNote(note) {
        AlertModal.showModal(
            Rosetta.string("organiser.note_deletion_prompt_title"),
            Rosetta.string("organiser.note_deletion_prompt_message", { title : note.title }),
            [
                AlertModal.button(Rosetta.string("organiser.note_deletion_prompt_title"), () => {
                    deleteNoteOverNetwork(note.id);
                    AlertModal.dismissModal();
                }, "danger"),
                AlertModal.button(Rosetta.string("common.cancel"), () => {
                    AlertModal.dismissModal();
                })
            ]
        );
    }

    function launchNoteScheduler(note) {
        setNoteSchedulerNote(note);
        setNoteSchedulerShown(true);
    }

    function schedulerDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            setNoteSchedulerShown(false);
        }
    }

    function fetchTodosFromNetwork(type) {
        if (type === TYPE_ACTIVE) {
            if (activeTodosNetworkInFlight) return;
            setActiveTodosNetworkInFlight(true);
        } else {
            if (completeTodosNetworkInFlight) return;
            setCompleteTodosNetworkInFlight(true);
        }

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            noteTypeID : 2,
            completion : (type === TYPE_COMPLETE ? 1 : 0),
            page : (type === TYPE_ACTIVE ? activeTodosPage : completeTodosPage),
            pageSize : 20
        };

        Axios.post(ENDPOINTS.personal.getUserNotes, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (type === TYPE_ACTIVE) {
                        setActiveTodos(resp.data.notes);
                        setActiveTodosCount(resp.data.count);
                    } else {
                        setCompleteTodos(resp.data.notes);
                        setCompleteTodosCount(resp.data.count);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }

                if (type === TYPE_ACTIVE) {
                    setActiveTodosNetworkInFlight(false);
                } else {
                    setCompleteTodosNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                if (type === TYPE_ACTIVE) {
                    setActiveTodosNetworkInFlight(false);
                } else {
                    setCompleteTodosNetworkInFlight(false);
                }
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PTL1000C"}));
            });
    }

    function setNoteCompletionOverNetwork(id, completed) {
        if (completeNetworkInFlight) return;
        setCompleteNetworkInFlight(true);

        const formData = {
            id,
            completed : completed
        };

        Axios.post(ENDPOINTS.personal.completeUserNote, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(completed === 1 ? "organiser.note_completion_complete" : "organiser.note_completion_not_complete"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchTodosFromNetwork(TYPE_ACTIVE);
                    fetchTodosFromNetwork(TYPE_COMPLETE);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setCompleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "OTDC3000C" }));
                setCompleteNetworkInFlight(false);
            });
    }

    function deleteNoteOverNetwork(id) {
        if (completeNetworkInFlight) return;
        setCompleteNetworkInFlight(true);

        let formData = {
            id
        };

        Axios.post(ENDPOINTS.personal.deleteUserNote, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("organiser.note_deletion_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchTodosFromNetwork(TYPE_ACTIVE);
                    fetchTodosFromNetwork(TYPE_COMPLETE);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setCompleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "OTDC3000C" }));
                setCompleteNetworkInFlight(false);
            });
    }

    // RENDER

    const tableColumns = [
        <Column name={"id"} title={""} render={(data, row) => (
            <span className={"table_check" + (parseInt(row.completed) === 1 ? " active" : "")} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setNoteCompletionOverNetwork(row.id, parseInt(row.completed) === 0 ? 1 : 0)}} />
        )} />,
        <Column name={"text"} title={Rosetta.string("organiser.todos_table_item")} />,
        <Column name={"id"} title={Rosetta.string("organiser.todos_table_actions")} className={"text-end"} render={(data, row) => {
            let buttons = [];

            if (parseInt(row.completed) === 0) {
                buttons.push(<button className={"btn btn-primary me-1"} onClick={(e) => { EventUtil.cancel(e); showNoteEditor(row.id); }}>{Rosetta.string("common.edit")}</button>);
                buttons.push(<button className={"btn btn-outline-primary me-1"} onClick={(e) => { EventUtil.cancel(e); launchNoteScheduler(row); } }>{Rosetta.string("organiser.todo_table_action_add")}</button>);
            }

            buttons.push(
                <button className={"btn btn-outline-primary"} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    promptToDeleteNote(row);
                }}>{Rosetta.string("common.delete")}</button>
            )

            return buttons;
        }} />
    ];

    return (
        <>
            <div className={"animate-screen-content organiser-todo-component"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8"}>
                        <h3>{Rosetta.string("organiser.todos_active_title")}</h3>
                    </div>
                    <div className={"col-12 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showNoteEditor()}>{Rosetta.string("organiser.todos_action_add")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={activeTodos}
                                placeholderMode={activeTodosNetworkInFlight}
                                placeholderRows={5}
                                onRowClick={(row) => { if (row) showNoteEditor(row.id) } }>
    
                                {tableColumns}
    
                            </TableComponent>
                        </div>
    
                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={activeTodosPage}
                                totalCount={activeTodosCount}
                                pageSize={20}
                                onClick={setActiveTodosPage} />
                        </div>
                    </div>
                </div>
    
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("organiser.todos_completed_title")}</h3>
                    </div>
                </div>
    
                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={completeTodos}
                                placeholderMode={completeTodosNetworkInFlight}
                                placeholderRows={5}>
    
                                {tableColumns}
    
                            </TableComponent>
                        </div>
    
                        <div className={"ep-table-paginate-container mt-1"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={completeTodosPage}
                                totalCount={completeTodosCount}
                                pageSize={20}
                                onClick={setCompleteTodosPage} />
                        </div>
                    </div>
                </div>
    
                
            </div>
            
            <OrganiserNoteEditorModal
                shown={noteEditorShown}
                id={noteEditorID}
                noteTypeID={2}
                callback={noteEditorDidCallback} />

            <OrganiserNoteScheduleModal
                shown={noteSchedulerShown}
                note={noteSchedulerNote}
                callback={schedulerDidCallback} />
            
        </>
    )

}