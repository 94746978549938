import Rosetta from "../rosetta/Rosetta";
import Axios from "axios";
import {ENDPOINTS} from "../network/API";
import {CommonUtil} from "./CommonUtil";

export const SubscriptionUtil = {
    TYPES : {
        FREE : 1,
        BUSINESS : 2,
        BESPOKE : 3
    },
    getTypeFromString : (string) => {
        if (string) {
            string = string.toLowerCase();

            if (string === "free") {
                return SubscriptionUtil.TYPES.FREE;
            } else if (string === "business") {
                return SubscriptionUtil.TYPES.BUSINESS;
            } else if (string === "bespoke") {
                return SubscriptionUtil.TYPES.BESPOKE;
            }
        }

        return -1;
    },
    parseDuration : (string) => {
        let out = "";

        if (string && string.length > 1) {
            let numeric = parseInt(string.substring(0, string.length - 1));
            let endStr = string.substring(string.length - 1, string.length);

            switch (endStr) {
                case "D":
                    out += Rosetta.string("time.days", { days : numeric}, numeric);
                    break;
                case "M":
                    out += Rosetta.string("time.months", { months : numeric }, numeric);
                    break;
                case "W":
                    out += Rosetta.string("time.weeks", { weeks : numeric }, numeric);
                    break;
                case "Y":
                    out += Rosetta.string("time.years", { years : numeric }, numeric);
                    break;
                default:
                    out += "";
            }
        }

        console.log(out);

        return out;
    },
    submitSubscription : (formData, paymentDigestID, paymentMethod) => {
        let subData = {
            companyName : formData.companyName,
            companyAddressLine1 : formData.companyAddress1,
            companyAddressLine2 : formData.companyAddress2,
            companyAddressCity : formData.companyCity,
            companyAddressCounty : formData.companyCounty,
            companyAddressPostcode : formData.companyPostcode,
            companyPhoneNumber : formData.companyPhone,
            companyNumUsers : formData.numUsers,
            userFirstName : formData.userFirstName,
            userSurname : formData.userSurname,
            userEmailAddress : formData.userEmailAddress,
            userPhoneNumber : formData.userPhoneNumber,
            userPassword : formData.userPassword,
            billingName : CommonUtil.getOrDefault(formData, "paymentName", null),
            billingAddressLine1 : CommonUtil.getOrDefault(formData, "paymentAddressLine1", null),
            billingAddressLine2 : CommonUtil.getOrDefault(formData, "paymentAddressLine2", null),
            billingAddressCity : CommonUtil.getOrDefault(formData, "paymentAddressCity", null),
            billingAddressCounty : CommonUtil.getOrDefault(formData, "paymentAddressCounty", null),
            billingAddressPostcode : CommonUtil.getOrDefault(formData, "paymentAddressPostcode", null),
        };

        if (paymentDigestID !== undefined) {
            subData.paymentDigestID = paymentDigestID;
        }

        if (paymentMethod !== undefined) {
            subData.paymentMethodID = paymentMethod.id;
        }

        return Axios.post(ENDPOINTS.subscription.submitSubscription, subData);
    }
}