import "./TokyoHeaderBar.css";
import {NavLink} from "react-router-dom";

import defaultProfile from "../../assets/images/default_profile.svg";
import {ImageUtil} from "../../util/ImageUtil";
import {useEffect, useRef, useState} from "react";
import {DataManager} from "../../data/DataManager";
import data from "bootstrap/js/src/dom/data";
import {CommonUtil} from "../../util/CommonUtil";

export const TokyoHeaderBarActions = {
    SIGNOUT : "signout",
    PROFILE_CLICKED : "profile_click"
};

export const TokyoHeaderBar = (props) => {

    const {bare} = props;

    const [appCounts, setAppCounts] = useState();

    const dataManagerListener = useRef();

    useEffect(() => {
        dataManagerListener.current = (keyName, data) => {
            console.log("HEADER. Key change: " + keyName);
            if (keyName.includes(DataManager.keys.app_counts)) {
                console.log("HEADER. App Counts!");
                if (!CommonUtil.isObject(data)) {
                    data = JSON.parse(data);
                }
                console.log(data);
                setAppCounts(data);
            } else if (keyName === DataManager.keys.project) {
                if (!CommonUtil.isObject(data)) {
                    data = JSON.parse(data);
                }
                setAppCounts(
                    DataManager.getAppCounts(data.id)
                );
            }
        };
        DataManager.addCallback(dataManagerListener.current);

        const currentProject = DataManager.getSelectedProject();
        if (currentProject) {
            setAppCounts(DataManager.getAppCounts(currentProject.id));
        }

        return () => {
            DataManager.removeCallback(dataManagerListener.current);
        }
    }, []);

    let navItems = [];
    if (props.hasOwnProperty("items")) {
        navItems = props.items;
    }

    function actionCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    let customNavArea = null;
    if (props.hasOwnProperty("navigationContent")) {
        customNavArea = props.navigationContent;
    }

    let logoBackground = null;
    let logoExtraCSS = "";
    let logoContent = [];
    if (props.logo !== undefined) {
        if (props.logo.image !== undefined) {
            logoBackground = props.logo.image;
            logoExtraCSS = " logo-rectangle";
        }

        if (props.logo.text !== undefined) {
            logoContent = (
                <div className={"logo-text"}>{props.logo.text}</div>
            )
        }
    }

    let logoTag = [];
    if (process.env.REACT_APP_LOGO_TAG) {
        logoTag = (<div className={"tag-text"}>{process.env.REACT_APP_LOGO_TAG}</div>);
    }

    let logoArea = (
        <div className={"logo-area" + logoExtraCSS} style={{backgroundImage : "url(" + logoBackground + ")"}}>
            {logoContent}
            {logoTag}
        </div>
    )

    let navAreaContent = [];
    if (navItems.length > 0) {
        navAreaContent = navItems.map((item) => {
            let extraClass = "";
            if (item.active) {
                extraClass = " active";
            }

            let title = "";
            if (item.title !== undefined) {
                title = item.title;
            }

            let href = "#";
            if (item.href !== undefined) {
                href = item.href;
            } else if (item.to !== undefined) {
                href  = item.to;
            }

            return (
                <NavLink className={"nav-pill" + extraClass} to={href} activeClassName={"active"} onClick={item.onClick}>{title}</NavLink>
            )
        });
    } else if (customNavArea !== null) {
        navAreaContent = customNavArea;
    }

    let accountAreaElem = [];
    if (bare === undefined || !bare) {
        let badgeElem = [];
        let badgeCount = 0;

        if (appCounts && appCounts.hasOwnProperty("invites")) {
            badgeCount += appCounts.invites;
        }

        if (badgeCount > 0) {
            badgeElem = (
                <span className={"badge text-bg-danger"}>{badgeCount}</span>
            );
        }

        accountAreaElem = (
            <div className={"account-area"}>
                <div className={"account-circle"} style={{backgroundImage : ImageUtil.background(defaultProfile) }} onClick={() => actionCallback(TokyoHeaderBarActions.PROFILE_CLICKED)} />
                {badgeElem}
            </div>
        );
    }

    return (
        <div className={"tokyo-header-bar"}>
            <div className={"row align-items-center"}>
                <div className={"col-5 col-md-4 col-lg-3"}>
                    {logoArea}
                </div>
                <div className={"col-5 col-md-6 col-lg-7"}>
                    <div className={"navigation-area"}>
                        {navAreaContent}
                    </div>
                </div>
                <div className={"col-2 text-end"}>
                    {accountAreaElem}
                </div>
            </div>
        </div>
    )

}