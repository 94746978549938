import {useEffect, useRef, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";

export const FileComponent = (props) => {

    const {callback} = props;
    const {onChange} = props;

    const [file, setFile] = useState();

    const fileInput = useRef();

    useEffect(() => {
        if (file) {
            if (onChange) {
                onChange({ target : { value : file } });
            }

            if (callback) {
                callback(file);
            }
        }
    }, [file]);

    function fileWasRequested() {
        if (fileInput && fileInput.current) {
            fileInput.current.click();
        }
    }

    function fileDidChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    }

    let fileLabel = Rosetta.string("file_selector.nothing_selected");
    if (file) {
        fileLabel = file.name;

        if (file.size > 0) {
            const sizeLookup = [
                { label : "file_selector.size_gb", size : 1073741824 }, // Gigabytes
                { label : "file_selector.size_mb", size : 1048576 }, // Megabytes
                { label : "file_selector.size_kb", size : 1024 }, // Kilobytes
                { label : "file_selector.size_b", size : 1 } // Bytes
            ];

            let multiple = 0;
            let label = null;
            for (let i = 0; i < sizeLookup.length; i++) {
                multiple = file.size / sizeLookup[i];
                if (multiple >= 1) {
                    label = sizeLookup[i].label;
                    break;
                }
            }

            if (label !== null) {
                fileLabel += Rosetta.string(label, { file_size : multiple })
            }
        }
    }

    return (
        <div className={"card"}>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        {fileLabel}
                    </div>

                    <div className={"col-12 text-center mt-2"}>
                        <button className={"btn btn-primary"} onClick={() => fileWasRequested()}>{Rosetta.string("file_selector.pick_file")}</button>
                        <div className={"file-hide"}>
                            <input type={"file"} ref={fileInput} onChange={fileDidChange} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}