import {CommonUtil} from "../../../../util/CommonUtil";

export const FormRadioComponent = (props) => {

    let value = CommonUtil.getOrDefault(props.input, "value", null);

    return (
        <div className={"form-radio-input"}>
            <label>{CommonUtil.getOrDefault(props.input, "label", "")}</label>
            <div>
                {
                    CommonUtil.getOrDefault(props.input, "options", []).map((option) => (
                        <label><input type={"radio"} checked={value == option.value || value == option.id} /> {option.label}</label>
                    ))
                }
            </div>
        </div>
    )

}