import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {API, ENDPOINTS} from "../../../network/API";
import {Column} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {AutoTable, AutoTableActions} from "../../table/AutoTable";
import {useState} from "react";
import {DataManager} from "../../../data/DataManager";
import {AppUser} from "../../../util/AppUser";
import {Chronos} from "../../../chronos/Chronos";
import AlertModal from "../../alertmodal/AlertModal";
import Axios from "axios";
import {Toast} from "../../toast/TokyoToaster";
import {AppCounts} from "../../../util/AppCounts";

export const InvitationScreen = (props) => {

    const ACTION_ACCEPT = 1;
    const ACTION_REJECT = 0;

    const [userPage, setUserPage] = useState(1);
    const [userPageKey, setUserPageKey] = useState();
    const [clientId, setClientId] = useState(DataManager.getClientId());
    const [clientPage, setClientPage] = useState(1);
    const [clientKey, setClientKey] = useState();

    const [actionNetworkInFlight, setActionNetworkInFlight] = useState(false);

    const user = DataManager.getUser();

    function userTableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setUserPage(data);
        } else if (action === AutoTableActions.DATA_CHANGED) {
            console.log("User table did change");
        }
    }

    function clientTableDidCallback(action, data) {
        if (action === AutoTableActions.PAGE_CHANGED) {
            setClientPage(data);
        } else if (action === AutoTableActions.DATA_CHANGED) {
            console.log("Client table did change");
        }
    }

    function getTableStatusBadge(row) {
        let label = Rosetta.string("user_invitation.status_pending");
        if (row.actionedDate !== null) {
            if (parseInt(row.accepted) === 1) {
                label = Rosetta.string("user_invitation.status_accepted");
            } else {
                label = Rosetta.string("user_invitation.status_rejected");
            }
        } else if (parseInt(row.active) === 0) {
            label = Rosetta.string("user_invitation.status_revoked");
        }

        return (
            <span className={"badge text-bg-secondary"}>{label}</span>
        )
    }

    function formatTableData(data) {
        let out = "---";
        try {
            out = Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
        } catch (e) {
            console.log(e);
        }
        return out;
    }

    function promptUserAction(status, invite) {
        let title = Rosetta.string("user_invitation.user_accept_title");
        let message = Rosetta.string("user_invitation.user_accept_message", { client_name : invite.clientName });
        let submitLabel = Rosetta.string("user_invitation.user_accept_confirm");
        let submitClass = "success";

        if (status === ACTION_REJECT) {
            title = Rosetta.string("user_invitation.user_reject_title");
            message = Rosetta.string("user_invitation.user_reject_message", { client_name : invite.clientName });
            submitLabel = Rosetta.string("user_invitation.user_reject_confirm");
            submitClass = "danger";
        }

        AlertModal.showModal(
            title,
            message,
            [
                AlertModal.button(
                    submitLabel,
                    () => {
                        actionUserInviteOverNetwork(invite, status);
                        AlertModal.dismissModal();
                    },
                    submitClass
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        )
    }

    function promptForRevocation(invite) {
        AlertModal.showModal(
            Rosetta.string("user_invitation.client_revoke_prompt_title"),
            Rosetta.string("user_invitation.client_revoke_prompt_message"),
            [
                AlertModal.button(
                    Rosetta.string("user_invitation.client_revoke_prompt_confirm"),
                    () => {
                        revokeInviteOverNetwork(invite);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function actionUserInviteOverNetwork(invite, action) {
        if (actionNetworkInFlight) return;
        setActionNetworkInFlight(true);

        const data = {
            id : invite.id,
            accepted : (action === ACTION_ACCEPT) ? 1 : 0
        };

        Axios.post(ENDPOINTS.user.actionUserClientInvitation, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let message = Rosetta.string("user_invitation.user_accept_success");
                    if (action === ACTION_REJECT) {
                        message = Rosetta.string("user_invitation.user_reject_success");
                    }

                    // Update User Invite Table
                    setUserPageKey(Math.random());

                    const project = DataManager.getSelectedProject();
                    if (project) {
                        AppCounts.refreshAppCounts(project.id);
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        message,
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setActionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(API.defaultError("UCIA1000C"));
                setActionNetworkInFlight(false);
            });
    }

    function revokeInviteOverNetwork(invite) {
        if (actionNetworkInFlight) return;
        setActionNetworkInFlight(true);

        const data = {
            id : invite.id,
            clientId
        };

        Axios.post(ENDPOINTS.client.revokeUserInvitation, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("user_invitiation.client_revoke_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    // Update client table now
                    setClientKey(Math.random());
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setActionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setActionNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UCIR1000C"));
            });
    }

    // RENDER

    let clientContent = [];
    if (parseInt(user.userRoleId) === AppUser.roles.LEAD_ADMIN || parseInt(user.userRoleId) === AppUser.roles.SYSTEM_ADMIN) {
        // Client Selector?
        // Status filter?

        clientContent = (
            <>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h4>{Rosetta.string("user_invitation.client_title")}</h4>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <AutoTable
                            url={ENDPOINTS.client.getUserInvitations}
                            data={{
                                clientId
                            }}
                            page={clientPage}
                            pageSize={20}
                            ordering={{
                                name : "dateCreated",
                                direction : "desc"
                            }}
                            mutation={clientKey}
                            callback={clientTableDidCallback}>

                            <Column name={"userGivenName"} title={Rosetta.string("user_invitation.client_table_given_name")}/>
                            <Column name={"userFamilyName"} title={Rosetta.string("user_invitation.client_table_family_name")}/>
                            <Column name={"userEmailAddress"} title={Rosetta.string("user_invitation.client_table_email")} />
                            <Column name={"userRoleName"} className={"text-center"} title={Rosetta.string("user_invitation.client_table_role")} />
                            <Column name={"accepted"} className={"text-center"} title={Rosetta.string("user_invitation.client_table_status")} render={(data, row) => getTableStatusBadge(row)}/>
                            <Column name={"dateCreated"} className={"text-center"} title={Rosetta.string("user_invitation.client_table_date")} render={(data, row) => formatTableData(data)}/>

                            <Column name={"id"} className={"text-end"} render={(data, row) => {
                                let buttons = [];
                                if (parseInt(row.active) === 1) {
                                    buttons.push(<span className={"btn btn-danger"} onClick={() => promptForRevocation(row)}>{Rosetta.string("user_invitation.client_table_action_revoke")}</span>);
                                }
                                return buttons;
                            }}/>

                        </AutoTable>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={"app-screen invitation-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("user_invitation.title")}/>
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h4>{Rosetta.string("user_invitation.user_title")}</h4>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <AutoTable
                        url={ENDPOINTS.user.getUserClientInvitations}
                        page={userPage}
                        pageSize={20}
                        emptyMessage={Rosetta.string("user_invitation.user_table_empty")}
                        mutation={userPageKey}
                        callback={userTableDidCallback}>

                        <Column name={"clientName"} title={Rosetta.string("user_invitation.user_table_client_name")}/>
                        <Column name={"userRoleName"} className={"text-center"} title={Rosetta.string("user_invitation.user_table_role")}/>
                        <Column name={"accepted"} className={"text-center"} title={Rosetta.string("user_invitation.user_status")} render={(data, row) => getTableStatusBadge(row)}/>
                        <Column name={"dateCreated"} className={"text-center"} title={Rosetta.string("user_invitation.user_date")} render={(data, row) => formatTableData(data)}/>

                        <Column name={"id"} className={"text-end"} render={(data, row) => (
                            <>
                                <span className={"btn btn-success"} onClick={() => promptUserAction(ACTION_ACCEPT, row)}>{Rosetta.string("user_invitation.user_action_approve")}</span>
                                <span className={"btn btn-danger ms-1"} onClick={() => promptUserAction(ACTION_REJECT, row)}>{Rosetta.string("user_invitation.user_action_reject")}</span>
                            </>
                        )}/>

                    </AutoTable>
                </div>
            </div>

            {clientContent}
        </div>
    )

}