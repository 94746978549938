import {CommonUtil} from "../../../../util/CommonUtil";
import {ImageUtil} from "../../../../util/ImageUtil";

export const FormPhotosComponent = (props) => {

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function imageWasClicked(image) {
        let images = [];
        props.input.images.forEach((image) => {
            images.push({
                imageUrl : image.imagePath,
                thumbUrl : image.thumbPath
            });
        });

        handleCallback("image", {
            input : props.input,
            imageUrl : image,
            images
        });
    }

    return (
        <div className={"form-photos-component"}>
            <label>{CommonUtil.getOrDefault(props.input, "label", "")}</label>
            <div className={"photo-thumbs"}>
                {CommonUtil.getOrDefault(props.input, "images", []).map((photo) => (
                    <div className={"image-thumbnail"} onClick={() => imageWasClicked(photo.imagePath)} style={{backgroundImage : ImageUtil.background(photo.thumbPath)}} />
                ))}
            </div>
        </div>
    );

}