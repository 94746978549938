import {useCallback, useEffect, useRef, useState} from "react";
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import iconPin from "../../assets/images/location.svg";

import "./PDFDocViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PDFDocViewerActions = {
    PIN_CLICK : "pinclicked",
    INITIAL_SCALE : "initialscale",
    REPORT_PAGES : "reportpages"
}

export const PDFDocViewer = (props) => {

    const {url} = props;
    const {page} = props;
    const {scale} = props;
    const {pins} = props;
    const {callback} = props;

    const [pdf, setPDF] = useState(null);
    const [boundingBox, setBoundingBox] = useState(null);

    const [isRendering, setIsRendering] = useState(false);

    const canvas = useRef(null);
    const curRenderTask = useRef(null);
    const curViewport = useRef(null);

    const reportedScale = useRef(false);

    const pinSize = 40; // px

    const root = useCallback((node) => {
        if (node !== null) {
            setBoundingBox({
                width : node.getBoundingClientRect().width,
                height : node.getBoundingClientRect().height
            })
        }
    }, []);

    useEffect(() => {
        reportedScale.current = false;
        initPDF();
    }, [url]);

    useEffect(() => {
        renderPage();
    }, [page, scale, pins]);

    useEffect(() => {
        renderPage();
    }, [pdf, boundingBox]);

    useEffect(() => {
        if (pdf) {
            handleCallback(PDFDocViewerActions.REPORT_PAGES, pdf.numPages);
        }
    }, [pdf]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    async function initPDF() {
        let pdfDoc = null;

        if (url) {
            try {
                pdfDoc = await pdfjs.getDocument({ url }).promise;
                setPDF(pdfDoc);
                await renderPage(pdfDoc);
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function renderPage(pdfDoc) {
        if (isRendering) return;

        if (!pdfDoc) {
            pdfDoc = pdf;
        }

        if (!pdfDoc || !canvas.current) return;
        if (!boundingBox) return;

        setIsRendering(true);

        let defaultScale = null;

        try {
            const curPage = await pdfDoc.getPage(page)

            if (!reportedScale.current) {
                let initialViewport = curPage.getViewport({scale: 1});

                if (boundingBox) {
                    if (initialViewport.width > boundingBox.width) {
                        defaultScale = boundingBox.width / initialViewport.width;
                    } else {
                        defaultScale = initialViewport.width / boundingBox.width;
                    }
                }
            }

            const viewport = curPage.getViewport({ scale, rotation : 0 });
            curViewport.current = viewport;

            const can = canvas.current;
            can.height = viewport.height;
            can.width = viewport.width;

            // if (boundingBox) {
            //     if (can.width > boundingBox.width) {
            //         let diff;
            //         if (can.width > can.height) {
            //             diff = can.height / can.width;
            //         } else {
            //             diff = can.width / can.height;
            //         }
            //
            //         can.width = boundingBox.width;
            //         can.height = can.height * diff;
            //     }
            // }

            let canvasContext = can.getContext("2d");
            canvasContext.clearRect(0, 0, can.width, can.height);
            canvasContext.beginPath();

            const renderContext = {
                canvasContext,
                viewport
            };

            if (curRenderTask.current !== null) {
                curRenderTask.current.cancel()
            }

            const task = curPage.render(renderContext)

            curRenderTask.current = task;

            await task.promise.then((r) => {
                setIsRendering(false);

                if (reportedScale.current) {
                    drawPinsOnCanvas(canvasContext);
                }

                if (defaultScale !== null) {
                    handleCallback(PDFDocViewerActions.INITIAL_SCALE, defaultScale);
                    reportedScale.current = true;
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    async function drawPinsOnCanvas(context) {
        if (!curViewport.current || !canvas.current) return;
        if (!pins || pins.length === 0) return;

        let pinImage = new Image();
        pinImage.src = iconPin;
        pinImage.onload = () => {
            for (let i = 0; i < pins.length; i++) {
                let pin = pins[i];
                let points = curViewport.current.convertToViewportPoint(
                    pin.x,
                    pin.y
                );

                let canvasX = points[0];
                // TODO Why is the Y value "flipped"?
                let canvasY = canvas.current.height - points[1];
                context.drawImage(
                    pinImage,
                    canvasX - (pinSize / 2),
                    canvasY - (pinSize / 2),
                    pinSize,
                    pinSize
                );
                context.stroke();

                pin.touchX = canvasX;
                pin.touchY = canvasY;
            }
        }
    }

    function canvasWasClicked(e) {
        if (canvas.current !== null && pins !== null) {
            const bounding = canvas.current.getBoundingClientRect();
            let clickX = e.clientX - bounding.left;
            let clickY = e.clientY - bounding.top;

            for (let i = 0; i < pins.length; i++) {
                let pin = pins[i];

                if (
                    clickX >= (pin.touchX - (pinSize / 2)) && clickX <= (pin.touchX + (pinSize / 2))
                    && clickY >= (pin.touchY - (pinSize / 2)) && clickY <= (pin.touchX + (pinSize / 2))
                ) {
                    handleCallback(PDFDocViewerActions.PIN_CLICK, pin);
                    break;
                }
            }
        }
    }

    return (
        <div ref={root}>
            <canvas ref={canvas} onClick={canvasWasClicked} />
        </div>
    )

}