export const AppUser = {
    roles : {
        SITE_MANAGER : 1,
        SUB_CONTRACTOR : 2,
        VISITOR : 3,
        LEAD_ADMIN : 4,
        SYSTEM_ADMIN : 5
    },
    getRolePriority : () => {
        return [
            AppUser.roles.SYSTEM_ADMIN,
            AppUser.roles.LEAD_ADMIN,
            AppUser.roles.SITE_MANAGER,
            AppUser.roles.SUB_CONTRACTOR,
            AppUser.roles.SYSTEM_ADMIN
        ]
    },
    getUserRolePriority : (userRoleId) => {
        let priority = 999;

        const rolePriority = AppUser.getRolePriority();

        for (let i = 0; i < rolePriority.length; i++) {
            if (parseInt(userRoleId) === rolePriority[i]) {
                priority = i;
                break;
            }
        }

        return priority;
    }
}