import {useEffect, useState} from "react";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {Navigator} from "../../../util/Navigator";
import {FilterText} from "../../filter/FilterText";

export const DocumentCaptureListScreen = (props) => {

    const [collections, setCollections] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [collectionsNetworkInFlight, setCollectionsNetworkInFlight] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState("");

    const [tableRefresh, setTableRefresh] = useState(0);

    useEffect(() => {
        fetchCollectionsFromNetwork();
    }, []);

    useEffect(() => {
        fetchCollectionsFromNetwork();
    }, [currentPage, tableRefresh, keyword]);

    function tableRowClicked(row) {
        if (row !== undefined && row !== null) {
            Navigator.navigate("/document-capture/" + row.id);
        }
    }

    function fetchCollectionsFromNetwork() {
        if (collectionsNetworkInFlight) return;
        setCollectionsNetworkInFlight(true);

        let project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            fetchFiles : false,
            page : currentPage,
            keyword
        };

        Axios.post(ENDPOINTS.projectCollectionDocument.getCollectionDocuments, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setCollections(resp.data.documents);
                    setTotalCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
                setCollectionsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", {error_code : "PDC1000C"}));
                setCollectionsNetworkInFlight(false);
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    return (
        <div className={"app-screen document-capture-list-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("document_collection.title")} />
                </div>
                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row mt-4 animate-screen-content"}>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            placeholderMode={collectionsNetworkInFlight}
                            placeholderRows={5}
                            data={collections}
                            onRowClick={tableRowClicked}>

                            <Column name={"dateCreated"} title={Rosetta.string("document_collection.table_date")} render={(data, row) => (
                                <>{Chronos.parse(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"id"} className={"text-center"} title={Rosetta.string("document_collection.table_submitted_by")} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", {given_name : row.firstName, family_name : row.surname })}</>
                            )} />
                            <Column name={"documentType"} className={"text-center"} title={Rosetta.string("document_collection.table_category")} />
                            <Column name={"filesCount"} className={"text-center"} title={Rosetta.string("document_collection.table_count")} render={(data, row) => (
                                <span className={"badge bg-secondary"}>{data}</span>
                            )} />

                        </TableComponent>
                    </div>

                    <div className={"ep-table-paginate-container mt-1"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={currentPage}
                            totalCount={totalCount}
                            pageSize={20}
                            onClick={(page) => setCurrentPage(page)} />
                    </div>
                </div>
            </div>
        </div>
    )

}