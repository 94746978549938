import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {UserSelectionModal} from "../common/UserSelectionModal";
import {Chronos} from "../../../chronos/Chronos";
import WindowUtil from "../../../util/WindowUtil";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import AlertModal from "../../alertmodal/AlertModal";

export const CalendarEventEditorModal = (props) => {

    const {shown} = props;
    const {forceDismiss} = props;
    const {id} = props;

    const [event, setEvent] = useState(null);

    const [eventDate, setEventDate] = useState(null);
    const [eventTime, setEventTime] = useState(null);
    const [eventTitle, setEventTitle] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventUsers, setEventUsers] = useState([]);

    const [requestingUser, setRequestingUser] = useState(false);

    const [eventNetworkInFlight, setEventNetworkInFlight] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (id) {
                fetchEventFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();
            setEventDate(null);
            setEventTime(null);
            setEventTitle("");
            setEventDescription("");
            setEventUsers([]);
        }
    }, [shown]);

    useEffect(() => {
        if (event) {
            if (event.eventDate) {
                let dateParse = Chronos.withTimestampSeconds(event.eventDate);
                setEventDate(dateParse.format("yyyy-MM-dd"));
                setEventTime(dateParse.format("HH:mm"));
                setEventTitle(event.title);
                setEventDescription(event.description);
                if (event.users) {
                    setEventUsers(event.users);
                }
            }
        }
    }, [event]);

    function submitForm() {
        if (eventDate === null) {
            setError(Rosetta.string("calendar.detail_validation_date"));
            return;
        }

        if (eventTitle === "") {
            setError(Rosetta.string("calendar.detail_validation_title"));
            return;
        }

        if (eventDescription === "") {
            setError(Rosetta.string("calendar.detail_validation_description"));
            return;
        }

        setError(null);
        submitEventOverNetwork();
    }

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    function userModalDidCallback(action, data) {
        if (action === "close") {
            setRequestingUser(false);

            if (data !== null) {
                addUser(data);
            }
        }
    }

    function addUser(user) {
        let hasRecord = false;
        for (let i = 0; i < eventUsers.length; i++) {
            if (parseInt(eventUsers[i].id) === parseInt(user.id)) {
                hasRecord = true;
                break;
            }
        }

        if (!hasRecord) {
            let users = [...eventUsers];
            users.push(user);
            setEventUsers(users);
        }
    }

    function removeUser(user) {
        let users = [...eventUsers];
        for (let i = users.length - 1; i >= 0; i--) {
            if (parseInt(users[i].id) === parseInt(user.id)) {
                users.splice(i, 1);
                break;
            }
        }
        setEventUsers(users);
    }

    function fetchEventFromNetwork() {
        if (eventNetworkInFlight) return;
        setEventNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        const formData = {
            projectID : (project ? project.id : undefined),
            id,
            page : 1,
            pageSize : 1,
        }

        Axios.post(ENDPOINTS.project.getUserCalendarEvents, formData)
            .then((r) => {
                const resp = API.parse(r);

                let errorMessage = null;

                if (resp.success) {
                    if (resp.data.events.length > 0) {
                        setEvent(resp.data.events[0]);
                    } else {
                        errorMessage = Rosetta.string("calendar.detail_fetch_error_no_record");
                    }
                } else {
                    errorMessage = API.formatError(resp);
                }

                if (errorMessage !== null) {
                    AlertModal.showError(errorMessage);
                }

                setEventNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "CED1000C"}));

                setEventNetworkInFlight(false);
            });
    }

    function submitEventOverNetwork() {
        if (eventNetworkInFlight) return;
        setEventNetworkInFlight(true);

        let assignedUserIDs = [];
        eventUsers.forEach((user) => {
            assignedUserIDs.push(user.id);
        });

        const project = DataManager.getSelectedProject();

        let dateString = eventDate;
        if (eventTime) {
            dateString += " " + eventTime;
        }

        let formData = {
            projectID : (project ? project.id : undefined),
            title : eventTitle,
            description : eventDescription,
            eventDate : Chronos.parse(dateString).seconds(),
            assignedUserIDs
        };

        if (id) {
            formData.id = id;
        }

        Axios.post(ENDPOINTS.schedule.submitUserCalendarEvent, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    handleCallback("submit", true);
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("calendar.detail_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    showError(API.formatError(resp));
                }
                setEventNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("error.error_common_unknown", { error_code : "CSES1000C"}));
                setEventNetworkInFlight(false);
            });
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    if (!shown) return [];

    let usersElems = [];
    for (let i = 0; i < eventUsers.length; i++) {
        let user = eventUsers[i];

        usersElems.push(
            <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">{Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.surname })}</div>
                    {user.jobTitle}
                </div>
                <span className="badge bg-danger rounded-pill clickable" onClick={() => removeUser(user)}>{Rosetta.string("calendar.detail_users_remove")}</span>
            </li>
        );
    }

    if (usersElems.length > 0) {
        usersElems = (
            <ul className="list-group">
                {usersElems}
            </ul>
        )
    } else {
        usersElems = (
            <div className={"empty-message"}>{Rosetta.string("calendar.detail_users_empty")}</div>
        )
    }

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger text-center"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    let title = Rosetta.string("calendar.detail_title_create");

    return (
        <>

            <Offcanvas
                shown={true}
                title={title}
                gravity={OffcanvasGravity.END}
                forceDismiss={forceDismiss}
                callback={handleCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("calendar.detail_date")}</label>
                        <input type={"date"} className={"form-control"} value={eventDate} onChange={(e) => setEventDate(e.target.value) } />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("calendar.detail_time")}</label>
                        <input type={"time"} className={"form-control"} value={eventTime} onChange={(e) => setEventTime(e.target.value) } />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("calendar.detail_title")}</label>
                        <input type={"text"} className={"form-control"} value={eventTitle} onChange={(e) => setEventTitle(e.target.value) } />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("calendar.detail_description")}</label>
                        <textarea className={"form-control"} value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("calendar.detail_users")}</label>

                        {usersElems}

                        <div className={"text-center mt-2"}>
                            <button className={"btn btn-outline-primary"} onClick={() => setRequestingUser(true)}>{Rosetta.string("calendar.detail_users_add")}</button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <hr />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} onClick={() => submitForm()}>{Rosetta.string("calendar.detail_submit")}</button>
                    </div>
                </div>

                {errorElem}

            </Offcanvas>

            <UserSelectionModal
                shown={requestingUser}
                callback={userModalDidCallback} />

        </>
    )

}