import {CommonUtil} from "../../../../util/CommonUtil";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";

export const FormLabelComponent = (props) => {

    let {placeholder} = props;

    let description = (<span>{CommonUtil.getOrDefault(props.input, "description", "")}</span>);

    return (
        <div className={"form-label-component"}>
            <label>
                <ContentOrPlaceholder showContent={!placeholder}>
                    {CommonUtil.getOrDefault(props.input, "label", "")}
                </ContentOrPlaceholder>
            </label>
            <ContentOrPlaceholder showContent={!placeholder}>
                {description}
            </ContentOrPlaceholder>
        </div>
    )

}