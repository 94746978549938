import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import {useEffect, useRef, useState} from "react";
import {Navigator} from "../../../util/Navigator";
import {useLocation} from "react-router-dom";
import {FilledFormsComponent} from "./FilledFormsComponent";
import {ToolboxVideoListComponent} from "./ToolboxVideoListComponent";
import {ReportListComponent} from "./ReportListComponent";
import {InductionKitComponent} from "./InductionKitComponent";
import {DataManager} from "../../../data/DataManager";
import {AppCounts} from "../../../util/AppCounts";

export const HealthSafetyScreen = (props) => {

    const TAB_FILLED_FORMS = 1;
    const TAB_TOOLBOX_TALKS = 2;
    const TAB_INDUCTION_KIT = 3;
    const TAB_REPORTED_ISSUES = 4;

    const HASH_FILLED_FORMS = "forms";
    const HASH_TOOLBOX_TALKS = "toolbox-talks";
    const HASH_INDUCTION_KIT = "induction-kit";
    const HASH_REPORTED_ISSUES = "reported-issues";

    const location = useLocation();

    const [selectedTab, setSelectedTab] = useState(TAB_FILLED_FORMS);
    const [appCounts, setAppCounts] = useState({});

    const dataManagerCallback = useRef();

    useEffect(() => {
        const selectedProject = DataManager.getSelectedProject();
        if (selectedProject) {
            setAppCounts(AppCounts.getAppCounts(selectedProject.id));
        }
    }, []);

    useEffect(() => {
        handleTabNavigation();

        dataManagerCallback.current = (key, value) => {
            if (key.includes(DataManager.keys.app_counts)) {
                let appCount = JSON.parse(value);
                let project = DataManager.getSelectedProject();
                if (appCount && project) {
                    if (parseInt(appCount.projectID) === parseInt(project.id)) {
                        setAppCounts(appCount);
                    }
                }
            }
        };
        DataManager.addCallback(dataManagerCallback.current);
    }, []);
    useEffect(handleTabNavigation, [location]);

    function handleTabNavigation() {
        let hashTab = Navigator.getHashParam("t", null);
        setSelectedTab(getTabForHashName(hashTab));
    }

    function tabDidChange(action, tab) {
        if (action === "select" || action === "change") {
            setSelectedTab(tab);
            Navigator.navigate("/healthSafety#t=" + getHashNameForTab(tab));
        }
    }

    function getHashNameForTab(tabID) {
        switch (tabID) {
            case TAB_FILLED_FORMS: return HASH_FILLED_FORMS;
            case TAB_TOOLBOX_TALKS: return HASH_TOOLBOX_TALKS;
            case TAB_INDUCTION_KIT: return HASH_INDUCTION_KIT;
            case TAB_REPORTED_ISSUES: return HASH_REPORTED_ISSUES;
            default: return "";
        }
    }

    function getTabForHashName(name) {
        switch (name) {
            case HASH_FILLED_FORMS: return TAB_FILLED_FORMS;
            case HASH_TOOLBOX_TALKS: return TAB_TOOLBOX_TALKS;
            case HASH_INDUCTION_KIT: return TAB_INDUCTION_KIT;
            case HASH_REPORTED_ISSUES: return TAB_REPORTED_ISSUES;
            default: return TAB_FILLED_FORMS;
        }
    }

    let tabContent = [];
    switch (selectedTab) {
        case TAB_FILLED_FORMS: tabContent = <FilledFormsComponent />; break;
        case TAB_TOOLBOX_TALKS: tabContent = <ToolboxVideoListComponent />; break;
        case TAB_INDUCTION_KIT: tabContent = <InductionKitComponent />; break;
        case TAB_REPORTED_ISSUES: tabContent = <ReportListComponent />; break;
        default: console.log("Invalid tab");
    }

    let inductionBadge = 0;
    if (appCounts && appCounts.hasOwnProperty("inductions")) {
        inductionBadge = appCounts.inductions;
    }

    let reportCount = 0;
    if (appCounts && appCounts.hasOwnProperty("reports")) {
        reportCount = appCounts.reports;
    }

    return (
        <div className={"app-screen health-safety-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("health_safety.title")} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <SegmentTabBar
                        selected={selectedTab}
                        callback={tabDidChange}>

                        <SegmentTab id={TAB_FILLED_FORMS} label={Rosetta.string("health_safety.tab_forms")} />
                        <SegmentTab id={TAB_TOOLBOX_TALKS} label={Rosetta.string("health_safety.tab_toolbox_talks")} />
                        <SegmentTab id={TAB_INDUCTION_KIT} label={Rosetta.string("health_safety.tab_induction_kit")} badge={inductionBadge} />
                        <SegmentTab id={TAB_REPORTED_ISSUES} label={Rosetta.string("health_safety.tab_reported_issues")} badge={reportCount} />

                    </SegmentTabBar>
                </div>
            </div>

            {tabContent}

        </div>
    )

}