import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {FileComponent} from "../common/FileComponent";
import Validator from "../../../util/Validator";
import {Toast} from "../../toast/TokyoToaster";

export const ProjectDrawingEditorModal = (props) => {

    const {shown} = props;
    const {drawingId} = props;
    const {projectID} = props;
    const {callback} = props;

    const [drawing, setDrawing] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    const [categories, setCategories] = useState([]);
    const [categoryNetworkInFlight, setCategoryNetworkInFlight] = useState(false);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [categoryId, setCategoryId] = useState(-1);
    const [uploadFile, setUploadFile] = useState(undefined);

    const [error, setError] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            fetchCategoriesFromNetwork();
            if (drawingId) {
                fetchDrawingFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setDrawing(null);
            setNetworkInFlight(false);
            setSubmitNetworkInFlight(false);
            setForceDismiss(false);

            setName("");
            setDescription("");
            setCategoryId(-1);
            setUploadFile(undefined);
        }
    }, [shown]);

    useEffect(() => {
        if (drawing) {
            setName(drawing.name);
            setDescription(drawing.description);
            setCategoryId(drawing.projectDrawingCategoryID);
        }
    }, [drawing]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function submissionWasSuccessful() {
        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string("site_information.drawing_editor_success"),
            Toast.SUCCESS,
            Toast.LONG
        );

        setSubmitNetworkInFlight(false);
        setForceDismiss(true);
    }

    function fetchDrawingFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = {
            id : drawingId,
            projectID,
            page : 1,
            pageSize : 1,
            fetchSnags : false
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawings, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.drawings.length > 0) {
                        setDrawing(resp.data.drawings[0]);
                    } else {
                        AlertModal.showError(API.defaultError("DEF1001C"));
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("DEF1000C"));
            });
    }

    function fetchCategoriesFromNetwork() {
        if (categoryNetworkInFlight) return;
        setCategoryNetworkInFlight(true);

        const data = {
            projectID
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawingCategories, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let categories = resp.data.drawingCategories;
                    setCategories(categories);

                    if (categories.length > 0) {
                        if (parseInt(categoryId) === -1) {
                            setCategoryId(categories[0].id);
                        }
                    }
                } else {
                    console.log(API.formatError(resp));
                }
                setCategoryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setCategoryNetworkInFlight(false);
            });
    }

    function submitDrawingOverNetwork() {
        if (submitNetworkInFlight) return;
        setError(null);

        const validationResult = Validator.validateCreateFormData({
            drawingId, projectID,
            name, description, categoryId
        }, [
            Validator.rule("drawingId", "int", "id", "id", true),
            Validator.rule("projectID", "int", "projectID", "projectID", true),
            Validator.rule("name", "string", Rosetta.string("site_information.drawing_editor_name"), "name", false),
            Validator.rule("description", "string", Rosetta.string("site_information.drawing_editor_description"), "description", false),
            Validator.rule("categoryId", "int", Rosetta.string("site_information.drawing_editor_category"), "projectDrawingCategoryID", false)
        ]);

        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        setSubmitNetworkInFlight(true);

        const formData = validationResult.formData;
        if (uploadFile) {
            console.log("FILE: ", uploadFile);
            formData.append("file", uploadFile);
        }

        Axios.post(ENDPOINTS.projectDrawing.submitProjectDrawing, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    submissionWasSuccessful();
                } else {
                    setError(API.formatError(resp));
                    setSubmitNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setError(API.defaultError("PDS1000C"));
                setSubmitNetworkInFlight(false);
            });
    }

    // RENDER

    if (!shown) return [];

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitDrawingOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (submitNetworkInFlight) {
        submitButton = (<button className={"btn btn-primary disabled"}>{Rosetta.string("common.please_wait")}</button>);
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("site_information.drawing_editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_information.drawing_editor_name")}</label>
                    <input type={"text"} className={"form-control"} value={name} onChange={(e) => setName(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_information.drawing_editor_description")}</label>
                    <textarea className={"form-control"} value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_information.drawing_editor_category")}</label>
                    <select className={"form-select"} value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                        {categories.map((category) => (
                            <option value={category.id}>{category.name}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("site_information.drawing_editor_file")}</label>
                    <FileComponent callback={(file) => setUploadFile(file)} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>

    )

}