import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Validator from "../../../util/Validator";
import {Toast} from "../../toast/TokyoToaster";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {ImageUtil} from "../../../util/ImageUtil";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";
import {CommonUtil} from "../../../util/CommonUtil";
import {useParams} from "react-router-dom";

export const ClientEditorScreen = (props) => {

    const {clientID} = useParams();

    const [client, setClient] = useState();
    const [clientNetworkInFlight, setClientNetworkInFlight] = useState(false);
    const [clientSubmitNetworkInFlight, setClientSubmitNetworkInFlight] = useState(false);

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [subscriptionTypesNetworkInFlight, setSubscriptionTypesNetworkInFlight] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0);

    const [clientName, setClientName] = useState();
    const [clientOwner, setClientOwner] = useState();
    const [clientEmail, setClientEmail] = useState();
    const [clientAddress1, setClientAddress1] = useState();
    const [clientAddress2, setClientAddress2] = useState();
    const [clientTown, setClientTown] = useState();
    const [clientCounty, setClientCounty] = useState();
    const [clientPostcode, setClientPostcode] = useState();
    const [clientTelephone, setClientTelephone] = useState();

    const [subscriptionType, setSubscriptionType] = useState(SubscriptionUtil.TYPES.FREE);
    const [subscriptionSku, setSubscriptionSku] = useState(null);
    const [subscriptionSlots, setSubscriptionSlots] = useState(1);
    const [subscriptionNextSku, setSubscriptionNextSku] = useState(null);
    const [subscriptionNextSlots, setSubscriptionNextSlots] = useState(null);
    const [subscriptionRenewalDate, setSubscriptionRenewalDate] = useState(null);
    const [subscriptionLastRenewalDate, setSubsciptionLastRenewalDate] = useState(null);
    const [subscriptionAutoRenewal, setSubscriptionAutoRenewal] = useState(0);

    const [subscriptionSkus, setSubscriptionSkus] = useState([]);

    const [initialUserFirstName, setInitialUserFirstName] = useState(null);
    const [initialUserLastName, setInitialUserLastName] = useState(null);
    const [initialUserEmail, setInitialUserEmail] = useState(null);
    const [initialUserPassword, setInitialUserPassword] = useState(null);

    const [uploadFile, setUploadFile] = useState(undefined);
    const [clientImageContent, setClientImageContent] = useState(null);
    const fileInput = useRef();

    useEffect(() => {
        if ((clientID && clientID !== "new") || process.env.REACT_APP_MODE !== "cms") {
            fetchClientFromNetwork();
        }

        if (process.env.REACT_APP_MODE === "cms") {
            fetchSubscriptionTypesFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (subscriptionType && subscriptionTypes && subscriptionTypes.length > 0) {
            let subscription = null;
            for (let i = 0; i < subscriptionTypes.length; i++) {
                if (parseInt(subscriptionTypes[i].id) === parseInt(subscriptionType)) {
                    subscription = subscriptionTypes[i];
                }
            }

            if (subscription && subscription.skus !== undefined) {
                setSubscriptionSkus(subscription.skus);
            } else {
                setSubscriptionSkus([]);
            }
        }
    }, [subscriptionType]);

    function populateIntoState(client) {
        setClient(client);
        setClientName(client.company_name);
        setClientOwner(client.company_owner);
        setClientAddress1(client.address1);
        setClientAddress2(client.address2);
        setClientTown(client.town);
        setClientCounty(client.county);
        setClientPostcode(client.postcode);
        setClientTelephone(client.telephone);

        if (client.hasOwnProperty("emailAddress")) {
            setClientEmail(client.emailAddress);
        }

        setSubscriptionType(CommonUtil.getOrDefault(client, "subscriptionTypeID", null));
        setSubscriptionSku(CommonUtil.getOrDefault(client, "subscriptionSkuID", null));

        if (client.hasOwnProperty("imagePath")) {
            setClientImageContent(client.imagePath);
        }
    }

    function fileInputWasSummoned() {
        fileInput.current.click();
    }

    function fileInputDidChange(e) {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            setUploadFile(file);

            const reader = new FileReader();
            reader.onload = () => {
                setClientImageContent(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    function submissionDidComplete() {
        Toast.show(
            Rosetta.string("common.success"),
            Rosetta.string("common.save_confirmation"),
            Toast.SUCCESS,
            Toast.LONG
        );

        setClientSubmitNetworkInFlight(false);
    }

    function selectDefaultSubscriptionType() {
        if (client !== null && subscriptionType === null && subscriptionTypes.length > 0 && client.hasOwnProperty("subscriptionTypeID")) {
            let subTypeID = parseInt(client.subscriptionTypeID);

            for (let i = 0; i < subscriptionTypes.length; i++) {
                if (parseInt(subscriptionTypes[i].id) === subTypeID) {
                    setSubscriptionType(subscriptionTypes[i]);
                    break;
                }
            }
        }
    }

    function fetchClientFromNetwork() {
        if (clientNetworkInFlight) return;
        setClientNetworkInFlight(true);

        let data = {};
        if (process.env.REACT_APP_MODE === "cms") {
            data.clientID = clientID;
        }

        Axios.post(ENDPOINTS.client.getClient, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    populateIntoState(resp.data.client);
                } else {
                    AlertModal.showModal(API.formatError(resp));
                }
                setClientNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setClientNetworkInFlight(false);
                AlertModal.showModal(Rosetta.string("common.error_common_unknown", { error_code : "CE1000C" }));
            });
    }

    function submitClientOverNetwork() {
        if (clientSubmitNetworkInFlight) return;

        let validationRules = [
            Validator.rule("clientName", "string", Rosetta.string("client.company_name"), "company_name"),
            Validator.rule("clientOwner", "string", Rosetta.string("client.company_owner"), "company_owner", true),
            Validator.rule("clientAddress1", "string", Rosetta.string("client.address1"), "address1"),
            Validator.rule("clientAddress2", "string", Rosetta.string("client.address2"), "address2"),
            Validator.rule("clientTown", "string", Rosetta.string("client.town"), "town"),
            Validator.rule("clientCounty", "string", Rosetta.string("client.county"), "county"),
            Validator.rule("clientPostcode", "string", Rosetta.string("client.postcode"), "postcode"),
            Validator.rule("clientTelephone", "string", Rosetta.string("client.telephone"), "telephone")
        ];

        if (process.env.REACT_APP_MODE === "cms") {
            validationRules.push(Validator.rule("clientID", "int", "", "id", true));
            validationRules.push(Validator.rule("subscriptionType", "int", Rosetta.string("client.subscription_type"), "subscriptionTypeID"));
            validationRules.push(Validator.rule("subscriptionSku", "int", Rosetta.string("client.subscription_sku"), "subscriptionSkuID", true));
            validationRules.push(Validator.rule("subscriptionSlots", "int", Rosetta.string("client.subscription_slots"), "subscriptionSlots", true));
            validationRules.push(Validator.rule("subscriptionAutoRenewal", "int", Rosetta.string("client.subscription_auto_renewal"), "subscriptionAutoRenewal", true));

            const initialUserOptionality = !(clientID === "new" && !client);

            validationRules.push(Validator.rule("initialUserFirstName", "string", Rosetta.string("client.initial_user_first_name"), "initialUserFirstName", initialUserOptionality));
            validationRules.push(Validator.rule("initialUserLastName", "string", Rosetta.string("client.initial_user_surname"), "initialUserLastName", initialUserOptionality));
            validationRules.push(Validator.rule("initialUserEmail", "string", Rosetta.string("client.initial_user_email_address"), "initialUserEmail", initialUserOptionality));
        }

        const validationResult = Validator.validateCreateFormData({
            clientID,
            clientName, clientOwner, clientAddress1,
            clientAddress2, clientTown, clientCounty,
            clientPostcode, clientTelephone, subscriptionType,
            subscriptionSku, subscriptionSlots, subscriptionAutoRenewal,
            initialUserFirstName, initialUserLastName, initialUserEmail
        }, validationRules);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setClientSubmitNetworkInFlight(true);

        const formData = validationResult.formData;

        Axios.post(ENDPOINTS.client.submitClient, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    populateIntoState(resp.data.client);

                    if (uploadFile) {
                        uploadFileOverNetwork(resp.data.client.id, uploadFile);
                    } else {
                        submissionDidComplete();
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                    setClientSubmitNetworkInFlight(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setClientSubmitNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.common_error_unknown", { error_code : "CE2000C" }));
            })
    }

    function uploadFileOverNetwork(clientID, file) {
        setClientSubmitNetworkInFlight(true);

        let formData = new FormData();
        formData.append("clientID", clientID);
        formData.append("file", file);

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.client.uploadClientImage, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setClientImageContent(resp.data.image.imagePath);
                    submissionDidComplete();
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setClientSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setClientSubmitNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "CIU1000C" }));
            })
    }

    function fetchSubscriptionTypesFromNetwork() {
        if (subscriptionTypesNetworkInFlight) return;
        setSubscriptionTypesNetworkInFlight(true);

        Axios.get(ENDPOINTS.subscription.getSubscriptionTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSubscriptionTypes(resp.data.types);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setSubscriptionTypesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubscriptionTypesNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "CES3100" }),
                    Toast.ERROR,
                    Toast.LONG
                );
            });
    }

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitClientOverNetwork()}>{Rosetta.string("common.save")}</button>);
    if (clientSubmitNetworkInFlight) {
        submitButton = (
            <div className={"progress-bar"}>
                <div className={"progress"} style={{width : uploadProgress + "%"}}/>
            </div>
        )
    } else if (clientNetworkInFlight) {
        submitButton = (
            <LoadingSpinner small={true} inline={true} />
        );
    }

    let cmsAdditional = [];
    if (process.env.REACT_APP_MODE === "cms") {
        cmsAdditional.push(
            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-header"}>
                            {Rosetta.string("client.subscription_details")}
                        </div>

                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-1"}>
                                    <label>{Rosetta.string("client.subscription_type")}</label>
                                    <select className={"form-select"} value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                                        {
                                            subscriptionTypes.map((type) => (
                                                <option value={type.id}>{type.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className={"col-12 col-md-6 mt-1"}>
                                    <label>{Rosetta.string("client.subscription_sku")}</label>
                                    <select className={"form-select"} value={subscriptionSku} onChange={(e) => setSubscriptionSku(e.target.value)}>
                                        {
                                            subscriptionSkus.map((sku) => (
                                                <option value={sku.id}>{sku.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-1"}>
                                    <label>{Rosetta.string("client.subscription_slots")}</label>
                                    <input type={"number"} className={"form-control"} value={subscriptionSlots} onChange={(e) => setSubscriptionSlots(e.target.value)} />
                                </div>

                                <div className={"col-12 col-md-6 mt-1"}>
                                    <label>{Rosetta.string("client.subscription_renewal_date")}</label>
                                    <input type={"text"} className={"form-control readOnly"} value={subscriptionRenewalDate} readOnly={true} />
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12 col-md-6 mt-1"}>
                                    <label>{Rosetta.string("client.subscription_auto_renewal")}</label>
                                    <select className={"form-select"} value={subscriptionAutoRenewal} onChange={(e) => setSubscriptionAutoRenewal(e.target.value)}>
                                        <option value={"0"}>{Rosetta.string("client.subscription_auto_renewal_off")}</option>
                                        <option value={"1"}>{Rosetta.string("client.subscription_auto_renewal_on")}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );

        if (clientID === "new" && !client) {
            cmsAdditional.push(
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("client.initial_user_title")}
                            </div>

                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("client.initial_user_first_name")}</label>
                                        <input type={"text"} className={"form-control"} value={initialUserFirstName} onChange={(e) => setInitialUserFirstName(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("client.initial_user_surname")}</label>
                                        <input type={"text"} className={"form-control"} value={initialUserLastName} onChange={(e) => setInitialUserLastName(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("client.initial_user_email_address")}</label>
                                        <input type={"text"} className={"form-control"} value={initialUserEmail} onChange={(e) => setInitialUserEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    let useClientEmail = clientEmail;
    if (!useClientEmail) {
        useClientEmail = Rosetta.string("clients.editor_email_address_undefined");
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("client.title")}/>
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4"}>

                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("client.detail_title")}
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.company_name")}</label>
                                        <input type={"text"} className={"form-control"} value={clientName} onChange={(e) => setClientName(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.company_owner")}</label>
                                        <input type={"text"} className={"form-control"} value={clientOwner} onChange={(e) => setClientOwner(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("clients.editor_email_address")}</label>
                                        <input type={"text"} className={"form-control disabled"} value={useClientEmail} readOnly={true} disabled={true} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.address1")}</label>
                                        <input type={"text"} className={"form-control"} value={clientAddress1} onChange={(e) => setClientAddress1(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.address2")}</label>
                                        <input type={"text"} className={"form-control"} value={clientAddress2} onChange={(e) => setClientAddress2(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.town")}</label>
                                        <input type={"text"} className={"form-control"} value={clientTown} onChange={(e) => setClientTown(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.county")}</label>
                                        <input type={"text"} className={"form-control"} value={clientCounty} onChange={(e) => setClientCounty(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.postcode")}</label>
                                        <input type={"text"} className={"form-control"} value={clientPostcode} onChange={(e) => setClientPostcode(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("client.telephone")}</label>
                                        <input type={"text"} className={"form-control"} value={clientTelephone} onChange={(e) => setClientTelephone(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("client.image_title")}
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"ratio ratio-16x9 image-thumbnail"} style={{backgroundImage : ImageUtil.background(clientImageContent)}} />
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        <button className={"btn btn-primary"} onClick={() => fileInputWasSummoned()}>{Rosetta.string("client.image_select")}</button>
                                        <div className={"file-hide"}>
                                            <input type={"file"} ref={fileInput} onChange={fileInputDidChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {cmsAdditional}

                    <div className={"row mt-4"}>
                        <div className={"col-12 text-center"}>
                            {submitButton}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}