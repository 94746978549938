import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {CommonUtil} from "../../../util/CommonUtil";
import {ImageUtil} from "../../../util/ImageUtil";

import PDFViewer from 'pdf-viewer-reactjs'
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {useParams} from "react-router-dom";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";

import "./DrawingDetailScreen.css";

import errorCircle from "../../../assets/images/error_circle.svg";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {Chronos} from "../../../chronos/Chronos";
import {PDFDocViewer, PDFDocViewerActions} from "../../pdf/PDFDocViewer";
import {Navigator} from "../../../util/Navigator";
import {EventUtil} from "../../../util/EventUtil";
import {PDFPresenter, PDFPresenterActions} from "../../pdf/PDFPresenter";

export const DrawingDetailScreen = (props) => {

    const {id} = useParams();

    const [drawing, setDrawing] = useState();
    const [drawingNetworkInFlight, setDrawingNetworkInFlight] = useState(false);

    const [drawingPage, setDrawingPage] = useState(1);

    useEffect(() => {
        if (id) {
            getDrawingFromNetwork();
        }
    }, []);

    function pdfDidCallback(action, data) {
        if (action === PDFDocViewerActions.PIN_CLICK) {
            if (data && drawing && drawing.snags !== undefined) {
                for (let i = 0; i < drawing.snags.length; i++) {
                    let snag = drawing.snags[i];
                    if (snag.id === data.id) {
                        AlertModal.showModal(
                            snag.snagNumber,
                            snag.locationName,
                            [
                                AlertModal.button(
                                    "View Snag Detail",
                                    () => {
                                        Navigator.navigate("/snagging/snag/" + snag.id)
                                        AlertModal.dismissModal();
                                    },
                                    "success"
                                ),
                                AlertModal.button(
                                    Rosetta.string("common.cancel"),
                                    () => {
                                        AlertModal.dismissModal();
                                    }
                                )
                            ]
                        )
                    }
                }
            }
        } else if (action === PDFPresenterActions.PAGE_CHANGE) {
            setDrawingPage(data);
        }
    }

    function getDrawingFromNetwork() {
        if (drawingNetworkInFlight) return;
        setDrawingNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            id,
            projectID : project ? project.id : undefined,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawings, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.drawings.length > 0) {
                        setDrawing(resp.data.drawings[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDrawingNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDrawingNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "DD1000C"}));
            });
    }

    let contentElem = [];
    let downloadButton = [];

    if (drawingNetworkInFlight) {
        contentElem = (<LoadingSpinner />);
    } else {
        if (drawing != null) {
            downloadButton = (
                <div className={"col-12 text-center"}>
                    <a className={"btn btn-primary"} href={drawing ? drawing.imagePath : "#"}>{Rosetta.string("snagging.drawing_unsupported_download")}</a>
                </div>
            )

            let mime = CommonUtil.getOrDefault(drawing, "imageMime", null);
            if (mime === null) {
                mime = ImageUtil.getMimeFromPath(drawing.imagePath);
            }

            let pins = [];
            if (drawing.snags) {
                for (let i = 0; i < drawing.snags.length; i++) {
                    let snag = drawing.snags[i];

                    if (snag.snagLocationPage === null) {
                        snag.snagLocationPage = 0;
                    }

                    if (parseInt(snag.snagLocationPage) === (drawingPage - 1)) {
                        pins.push({
                            id : snag.id,
                            x: snag.snagLocationX,
                            y: snag.snagLocationY,
                            title: snag.number,
                            subtitle: snag.description
                        });
                    }
                }
            }

            if (mime !== null) {
                mime = mime.toLowerCase();
                if (mime === "application/pdf") {
                    contentElem = (
                        <PDFPresenter
                            url={drawing.imagePath}
                            options={{pins}}
                            callback={pdfDidCallback} />
                    )
                } else if (ImageUtil.isMimeADisplayableImage(mime)) {
                    contentElem = (
                        <div className={"drawing-image-container"}>
                            <img src={drawing.imagePath} className={"drawing-image"} alt={drawing.title}/>
                        </div>
                    )
                } else {
                    contentElem = (
                        <div className={"unsupported-message animate-screen-content"}>
                            <div className={"icon-image"} style={{backgroundImage : ImageUtil.background(errorCircle)}} />
                            <div className={"message-text"}>{Rosetta.string("snagging.drawing_unsupported_message")}</div>
                            <div className={"text-center"}><a className={"btn btn-primary"} href={drawing.imagePath}>{Rosetta.string("snagging.drawing_unsupported_download")}</a></div>
                        </div>
                    )
                }
            } else {
                contentElem = (
                    <div>ERROR</div>
                );
            }
        }
    }

    return (
        <div className={"app-screen drawing-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("snagging.drawing_title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <ContentOrPlaceholder showContent={!drawingNetworkInFlight}>
                                            <strong>{drawing ? drawing.name : ""}</strong>
                                        </ContentOrPlaceholder>
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <ContentOrPlaceholder showContent={!drawingNetworkInFlight}>
                                            {Rosetta.string("snagging.drawing_date", { date : drawing ? Chronos.withTimestampSeconds(drawing.dateCreated).format("dd/MM/yyyy HH:mm") : ""})}
                                        </ContentOrPlaceholder>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        {drawing ? drawing.description : ""}
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    {downloadButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"text-center mt-2"}>
                                    {contentElem}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}