import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import {Toast} from "../../toast/TokyoToaster";
import {Chronos} from "../../../chronos/Chronos";
import {Navigator} from "../../../util/Navigator";

export const DiarySnaggingComponent = (props) => {

    const {date} = props;

    const [reportedSnags, setReportedSnags] = useState([]);
    const [reportedSnagCount, setReportedSnagCount] = useState(0);
    const [reportedSnagPage, setReportedSnagPage] = useState(1);
    const [reportedSnagNetworkInFlight, setReportedSnagNetworkInFlight] = useState(false);

    const [actionedSnags, setActionedSnags] = useState([]);
    const [actionedSnagCount, setActionedSnagCount] = useState(0);
    const [actionedSnagPage, setActionedSnagPage] = useState(1);
    const [actionedSnagNetworkInFlight, setActionedSnagNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchReportedSnagsFromNetwork();
        fetchActionedSnagsFromNetwork();
    }, []);

    useEffect(() => {
        fetchReportedSnagsFromNetwork();
    }, [reportedSnagPage, date, filterKeyword]);

    useEffect(() => {
        fetchActionedSnagsFromNetwork();
    }, [actionedSnagPage, date, filterKeyword]);

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    function tableRowWasClicked(row) {
        if (row !== undefined) {
            Navigator.navigate("/snagging/snag/" + row.id);
        }
    }

    function fetchReportedSnagsFromNetwork() {
        if (reportedSnagNetworkInFlight) return;
        setReportedSnagNetworkInFlight(true);

        performNetworking(5, reportedSnagPage, (result, resp) => {
            if (result) {
                if (resp.success) {
                    setReportedSnags(resp.data.snags);
                    setReportedSnagCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
            } else {
                showError(Rosetta.string("common.error_common_unknown", {error_code : "HSSL1000C"}));
            }
            setReportedSnagNetworkInFlight(false);
        });
    }

    function fetchActionedSnagsFromNetwork() {
        if (actionedSnagNetworkInFlight) return;
        setActionedSnagNetworkInFlight(true);

        performNetworking(4, actionedSnagPage, (result, resp) => {
            if (result) {
                if (resp.success) {
                    setActionedSnags(resp.data.snags);
                    setActionedSnagCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
            } else {
                showError(Rosetta.string("common.error_common_unknown", {error_code : "HSSL1000C"}));
            }
            setActionedSnagNetworkInFlight(false);
        });
    }

    function performNetworking(mode, page, callback) {
        const project = DataManager.getSelectedProject();

        const chronosDate = Chronos.with(date);
        let startDate = null;
        let endDate = null;
        if (chronosDate && chronosDate.isValid()) {
            startDate = chronosDate.startOfDay().seconds();
            endDate = chronosDate.endOfDay().seconds();
        }

        let formData = {
            projectID : (project ? project.id : undefined),
            mode,
            page,
            keyword: filterKeyword,
            startDate,
            endDate
        };

        Axios.post(ENDPOINTS.projectDrawing.getProjectDrawingSnags, formData)
            .then((r) => {
                callback(true, API.parse(r));
            })
            .catch((e) => {
                console.log(e);
                callback(false);
            });
    }

    return (
        <div className={"animate-screen-content diary-health-safety-component"}>
            <div className={"row mt-4"}>

                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <h3>Snags reported</h3>
                </div>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={reportedSnags}
                        placeholderMode={reportedSnagNetworkInFlight}
                        placeholderRows={10}
                        onRowClick={tableRowWasClicked}>

                        <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                        <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                            <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                        )} />
                        <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                        <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                            <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                        )} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={reportedSnagPage}
                        totalCount={reportedSnagCount}
                        pageSize={20}
                        onClick={setReportedSnagPage} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <h3>Snags actioned</h3>
                </div>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={actionedSnags}
                        placeholderMode={actionedSnagNetworkInFlight}
                        placeholderRows={10}
                        onRowClick={tableRowWasClicked}>

                        <Column name={"snagNumber"} title={Rosetta.string("snagging.table_number")} className={"text-center"} />
                        <Column name={"dateCreated"} title={Rosetta.string("snagging.table_date_time")} className={"text-center"} render={(data, row) => (
                            <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                        )} />
                        <Column name={"locationName"} title={Rosetta.string("snagging.table_location")} className={"text-center"} />
                        <Column name={"userFirstName"} title={Rosetta.string("snagging.table_author")} className={"text-center"} render={(data, row) => (
                            <>{Rosetta.string("common.name_format", { given_name : row.userFirstName, family_name : row.userSurname })}</>
                        )} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={actionedSnagPage}
                        totalCount={actionedSnagCount}
                        pageSize={20}
                        onClick={setActionedSnagPage} />
                </div>
            </div>
        </div>
    )

}