import {Chronos} from "../../../chronos/Chronos";
import Rosetta from "../../../rosetta/Rosetta";
import {PlaceholderText} from "../../placeholder/PlaceholderText";

export const OrganiserNoteWidget = (props) => {

    const {note} = props;
    const {showActions} = props;

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    let titleClass = "col-12";
    let actionElems = [];
    if (showActions === undefined || showActions) {
        titleClass = "col-12 col-md-6 col-lg-8";
        actionElems = (
            <div className={"col-12 col-md-6 col-lg-4 text-end"}>
                <button className={"btn btn-primary me-1"} onClick={() => handleCallback("show", note)}>{Rosetta.string("common.edit")}</button>
                <button className={"btn btn-outline-primary"} onClick={() => handleCallback("delete", note)}>{Rosetta.string("common.delete")}</button>
            </div>
        )
    }

    if (note) {
        return (
            <div className={"card"}>
                <div className={"card-header"}>
                    <div className={"row"}>
                        <div className={titleClass}>
                            <h4>{note.title}</h4>
                            <div>{Chronos.withTimestampSeconds(note.dateCreated).format("dd/MM/yyyy HH:mm")}</div>
                        </div>

                        {actionElems}
                    </div>
                </div>

                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {note.text}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"card"}>
            <div className={"card-header"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h4><PlaceholderText /></h4>
                        <div className={"mt-1"}><PlaceholderText /></div>
                    </div>
                </div>
            </div>

            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <PlaceholderText />
                        <PlaceholderText />
                        <PlaceholderText />
                    </div>
                </div>
            </div>
        </div>
    )

}