import React from "react";
import {StringUtil} from "../util/StringUtil";

export default class BaseComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.initState({}); // Load a blank object into state to begin
    }

    /**
     * Initialises the state for this component
     * @param state     State Object
     */
    initState(state) {
        this.state = state;
        this.setState(state);
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     * @param callback  ReactJS callback when the state has been mutated
     */
    handleChange(event, callback) {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;
        }

        this.setState({
            [name] : value
        }, callback);
    }

    /**
     * Gets a property from a given object or a default value if the property is does not exist
     * @param object            Source object
     * @param key               Property name to find
     * @param defaultValue      Default value to return if the value does not exist
     * @returns {*}             The extracted value or the default value
     */
    getOrDefault = (object, key, defaultValue) => {
        if (object !== undefined && object !== null && object) {
            if (object.hasOwnProperty(key)) {
                return object[key];
            }
        }
        return defaultValue;
    }

    /**
     * Gets a property from a given object or the default if the property does not exist or the value of the property is
     * null.
     * @param object            Source object
     * @param key               Property name to find
     * @param defaultValue      Default value to return if the value does not exist or is null
     * @returns {*}             The extracted value or default value
     */
    getOrDefaultNotNull = (object, key, defaultValue) => {
        let out = this.getOrDefault(object, key, defaultValue);
        if (out === null) {
            out = defaultValue;
        }
        return out;
    }

    /**
     * Takes in an object and applies it to the current state. Can be prefixed, prefixed items are camel-cased.
     * @param object        Object to extract from
     * @param prefix        Prefix to append. If undefined, no prefix is added, objects are added raw.
     * @param callback      Callback for when state has been updated
     */
    populateObjectIntoState = (object, prefix, callback, camelCase) => {
        if (camelCase === undefined) {
            camelCase = true;
        }

        let state = {};

        let keys = Object.keys(object);
        keys.forEach((key) => {
            if (prefix === undefined) {
                prefix = "";
            }

            let value = object[key];

            if (camelCase) {
                key = StringUtil.capitalizeFirstLetter(key);
            }

            key = prefix + key;
            state[key] = value;
        });

        console.log("NEW STATE:");
        console.log(state);

        this.setState(state, callback);
        // console.log(state);
    }

    /**
     * Returns a URI property name or a default value
     * @param keyName           URI name to find
     * @param defaultValue      Default value if property doesn't exist
     * @param props             (Optional) The props to use, default is this.props
     * @returns {*}             Property value or default
     */
    getUriProperty = (keyName, defaultValue, props) => {
        console.log(props);
        if (props === undefined) {
            props = this.props;
        }

        if (props !== undefined) {
            if (props.match !== undefined) {
                if (props.match.params !== undefined) {
                    if (props.match.params[keyName] !== undefined) {
                        return props.match.params[keyName];
                    }
                }
            }
        }

        return defaultValue;
    }

}
