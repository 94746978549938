import {CommonUtil} from "../../../../util/CommonUtil";
import {Form} from "../../../../util/Form";
import {FormLabelComponent} from "./FormLabelComponent";
import {FormTextComponent} from "./FormTextComponent";
import {FormSignatureComponent} from "./FormSignatureComponent";
import {FormRadioComponent} from "./FormRadioComponent";
import {FormPhotosComponent} from "./FormPhotosComponent";

export const FormInput = (props) => {

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    let typeId = parseInt(CommonUtil.getOrDefault(props.input, "dataTypeID", "-1"));

    let out = [];

    switch (typeId) {
        case Form.DATA_TYPE.LABEL:
            out = (<FormLabelComponent input={props.input} callback={handleCallback} />); break;
        case Form.DATA_TYPE.TEXT:
        case Form.DATA_TYPE.NUMBER:
        case Form.DATA_TYPE.DATE_TIME:
            out = (<FormTextComponent input={props.input} callback={handleCallback} />); break;
        case Form.DATA_TYPE.RADIO:
            out = (<FormRadioComponent input={props.input} callback={handleCallback} />); break;
        case Form.DATA_TYPE.SIGNATURE:
            out = (<FormSignatureComponent input={props.input} callback={handleCallback} />); break;
        case Form.DATA_TYPE.PHOTO_SELECTOR:
            out = (<FormPhotosComponent input={props.input} callback={handleCallback} />); break;
        default:
            console.log("UNSUPPORTED TYPE: " + typeId);
    }

    return (
        <div className={"form-question-wrapper"}>
            {out}
        </div>
    );

}