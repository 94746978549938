import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Navigator} from "../../../util/Navigator";

export const FilledFormsComponent = (props) => {

    const [forms, setForms] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [formsNetworkInFlight, setFormsNetworkInFlight] = useState(false);

    const [formTypes, setFormTypes] = useState([]);
    const [filterFormTypeID, setFilterFormTypeID] = useState(-1);
    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchFormsFromNetwork();
    }, [])

    useEffect(() => {
        fetchFormsFromNetwork();
    }, [filterFormTypeID, filterKeyword, page]);

    function tableRowClicked(row) {
        if (row !== undefined) {
            Navigator.navigate("/forms/" + row.id);
        }
    }

    function fetchFormsFromNetwork() {
        if (formsNetworkInFlight) return;
        setFormsNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            page,
            pageSize : 20,
            keyword : filterKeyword,
            projectFormTypeID : (filterFormTypeID > 0 ? filterFormTypeID : undefined),
            requireSubmissions : true,
            fetchForm : false,
            fetchFormTypes : true
        };

        Axios.post(ENDPOINTS.projectForm.getProjectForms, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setForms(resp.data.forms);
                    setTotalCount(resp.data.count);
                    setFormTypes(resp.data.formTypes);
                } else {
                    showError(API.formatError(resp));
                }
                setFormsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFormsNetworkInFlight(false);
                showError(Rosetta.string("common.error_common_unknown", { error_code : "HSPF1000C"}));
            });
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    return (
        <div className={"animate-screen-content filled-forms-component"}>
            <div className={"row mt-4"}>

                <div className={"col-12 col-md-4 mt-2"}>
                    <h3>{Rosetta.string("health_safety.tab_forms")}</h3>
                </div>

                <div className={"col-12 col-md-4 mt-2"}>
                    <select className={"form-select"} onChange={(e) => setFilterFormTypeID(parseInt(e.target.value))}>
                        <option value={-1}>{Rosetta.string("health_safety.forms_filter_all")}</option>
                        {formTypes.map((type) => (
                            <option value={type.id}>{type.name}</option>
                        ))}
                    </select>
                </div>

                <div className={"col-12 col-md-4 mt-2"}>
                    <FilterText callback={setFilterKeyword} />
                </div>

            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>

                    <div className={"table-container"}>
                        <TableComponent
                            className={"clickable"}
                            placeholderMode={formsNetworkInFlight}
                            placeholderRows={5}
                            data={forms}
                            onRowClick={tableRowClicked}>

                            <Column name={"formType"} className={"text-center"} title={Rosetta.string("health_safety.forms_table_type")} />
                            <Column name={"title"} className={"text-center"} title={Rosetta.string("health_safety.forms_table_title")} />
                            <Column name={"dateCreated"} className={"text-center"} title={Rosetta.string("health_safety.forms_table_date")} render={(data, row) => (
                                <>{(data ? Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm") : "")}</>
                            )} />
                            <Column name={"id"} className={"text-center"} title={Rosetta.string("health_safety.forms_table_author")} render={(data, row) => (
                                <>{Rosetta.string("common.name_format", {given_name : row.firstName, family_name : row.surname })}</>
                            )} />

                        </TableComponent>
                    </div>

                    <div className={"ep-table-paginate-container mt-1"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={page}
                            totalCount={totalCount}
                            pageSize={20}
                            onClick={setPage} />
                    </div>

                </div>
            </div>
        </div>
    )

}