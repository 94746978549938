import {SegmentTab, SegmentTabBar} from "../../segmenttab/SegmentTabBar";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {SnagsDrawingComponent} from "./SnagsDrawingComponent";
import {SnagsListComponent} from "./SnagsListComponent";
import {Snag} from "../../../util/Snag";
import {Navigator} from "../../../util/Navigator";
import {useLocation} from "react-router-dom";

export const SnaggingLogScreen = (props) => {

    const TAB_DRAWING = 1;
    const TAB_ACTIONED = 2;
    const TAB_SIGNED_OFF = 3;
    const TAB_COMPLETED = 4;

    const HASH_DRAWING = "drawing";
    const HASH_ACTIONED = "actioned";
    const HASH_SIGNED_OFF = "signedoff";
    const HASH_COMPLETE = "completed";

    const location = useLocation();

    const [selectedTab, setSelectedTab] = useState(TAB_DRAWING);

    useEffect(() => {
        // Pick up hash values in URL on Component load
        locationDidChange();
    }, []);

    useEffect(() => {
        // When location changes, check hash values
        locationDidChange();
    }, [location]);

    function locationDidChange() {
        let hashTab = Navigator.getHashParam("t", null);
        if (hashTab !== null) {
            let defaultTab = selectedTab;
            switch (hashTab.toLowerCase()) {
                case HASH_DRAWING: defaultTab = TAB_DRAWING; break;
                case HASH_ACTIONED: defaultTab = TAB_ACTIONED; break;
                case HASH_SIGNED_OFF: defaultTab = TAB_SIGNED_OFF; break;
                case HASH_COMPLETE: defaultTab = TAB_COMPLETED; break;
                default: console.log("Hash value didn't match records.");
            }
            setSelectedTab(defaultTab);
        }
    }

    function onTabSelected(action, tab) {
        if (action === "select") {
            let hashTag = "t=";

            switch (tab) {
                case TAB_DRAWING: hashTag += HASH_DRAWING; break;
                case TAB_ACTIONED: hashTag += HASH_ACTIONED; break;
                case TAB_SIGNED_OFF: hashTag += HASH_SIGNED_OFF; break;
                case TAB_COMPLETED: hashTag += HASH_COMPLETE; break;
                default: console.log("Nope");
            }

            Navigator.navigate("/snagging#" + hashTag);

            setSelectedTab(tab);
        }
    }

    let tabContent = [];
    switch (selectedTab) {
        case TAB_DRAWING:
            tabContent = (<SnagsDrawingComponent />); break;
        case TAB_ACTIONED:
            tabContent = (<SnagsListComponent mode={Snag.MODES.UNACTIONED} />); break;
        case TAB_SIGNED_OFF:
            tabContent = (<SnagsListComponent mode={Snag.MODES.UNSIGNED} />); break;
        case TAB_COMPLETED:
            tabContent = (<SnagsListComponent mode={Snag.MODES.COMPLETE} />); break;
        default:
            console.log("Nothing, sorry");
    }

    return (
        <div className={"app-screen snagging-log-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("snagging.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4"}>
                    <div className={"col-12 align-items-center"}>
                        <SegmentTabBar selected={selectedTab} callback={onTabSelected}>
                            <SegmentTab id={TAB_DRAWING} label={Rosetta.string("snagging.tab_drawing")} />
                            <SegmentTab id={TAB_ACTIONED} label={Rosetta.string("snagging.tab_actioned")} />
                            <SegmentTab id={TAB_SIGNED_OFF} label={Rosetta.string("snagging.tab_signoff")} />
                            <SegmentTab id={TAB_COMPLETED} label={Rosetta.string("snagging.tab_completed")} />
                        </SegmentTabBar>
                    </div>
                </div>

                {tabContent}
            </div>

        </div>
    );

}