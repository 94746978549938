import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {FilterText} from "../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {Chronos} from "../../../chronos/Chronos";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {UserSelectionModal} from "../common/UserSelectionModal";
import {Toast} from "../../toast/TokyoToaster";

export const SiteRegisterListScreen = (props) => {

    const FILTER_SIGNED_IN = 1;
    const FILTER_SIGNED_OUT = 2;
    const FILTER_NOT_SIGNED_IN = 3;

    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [page, setPage] = useState(1);
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);

    const [filterType, setFilterType] = useState(FILTER_SIGNED_IN);
    const [filterDate, setFilterDate] = useState(Chronos.now().format("yyyy-MM-dd 00:00:00"));
    const [filterKeyword, setFilterKeyword] = useState("");

    const [userSelectionShown, setUserSelectionShown] = useState(false);
    const [assignNetworkInFlight, setAssignNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchSiteRegisterFromNetwork();
    }, []);

    useEffect(() => {
        fetchSiteRegisterFromNetwork();
    }, [page, filterType, filterDate, filterKeyword]);

    function formatTableDate(date) {
        console.log("FORMAT DATE: " + date);
        if (date !== null) {
            return Chronos.withTimestampSeconds(date).format("dd/MM/yy - HH:mm");
        }
        return "---";
    }

    function userWasSelected(action, data) {
        if (action === "close") {
            if (data) {
                assignUserOverNetwork(data);
            }
            setUserSelectionShown(false);
        }
    }

    function fetchSiteRegisterFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let date = null;
        if (filterDate) {
            try {
                date = Chronos.with(new Date(filterDate)).seconds();
            } catch (e) {
                console.log(e);
            }
        }

        let formData = {
            projectID : project ? project.id : undefined,
            mode : filterType,
            date,
            keyword : filterKeyword,
            page,
            pageSize : 20
        };

        Axios.post(ENDPOINTS.project.getSiteRegisterTable, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.users);
                    setUserCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PSRL1000C" }));
            });
    }

    function assignUserOverNetwork(user) {
        if (assignNetworkInFlight) return;
        setAssignNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : project ? project.id : undefined,
            userIDs : JSON.stringify([user.id])
        };

        Axios.post(ENDPOINTS.project.assignUsers, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("site_information.user_assign_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    fetchSiteRegisterFromNetwork();
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setAssignNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setAssignNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "SRAU1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                );
            });
    }

    return (
        <div className={"app-screen site-register-list-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("register.title")} />
                </div>
                <div className={"col-12 col-md-4 text-end"}>
                    <button className={"btn btn-primary"} onClick={() => setUserSelectionShown(true)}>{Rosetta.string("register.add_user")}</button>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <select className={"form-select"} value={filterType} onChange={(e) => setFilterType(parseInt(e.target.value))}>
                            <option value={FILTER_SIGNED_IN}>{Rosetta.string("register.filter_type_signed_in")}</option>
                            <option value={FILTER_SIGNED_OUT}>{Rosetta.string("register.filter_type_signed_out")}</option>
                            <option value={FILTER_NOT_SIGNED_IN}>{Rosetta.string("register.filter_type_not_signed_in")}</option>
                        </select>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <input type={"date"} className={"form-control"} value={filterDate} onChange={(e) => setFilterDate(e.target.value)} />
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterKeyword} />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                data={users}
                                placeholderMode={userNetworkInFlight}
                                placeholderRows={10}>

                                <Column name={"id"} title={""} />
                                <Column name={"firstName"} title={Rosetta.string("register.table_first_name")} />
                                <Column name={"surname"} title={Rosetta.string("register.table_last_name")} />
                                <Column name={"jobTitle"} title={Rosetta.string("register.table_job_title")} />
                                <Column name={"company"} title={Rosetta.string("register.table_company")} />
                                <Column name={"userRoleName"} title={Rosetta.string("register.table_user_type")} />
                                <Column name={"signInDate"} title={Rosetta.string("register.table_signed_in")} className={"text-center"} render={(data, row) => (
                                    <>{formatTableDate(data)}</>
                                )} />
                                <Column name={"signOutDate"} title={Rosetta.string("register.table_signed_out")} className={"text-center"} render={(data, row) => (
                                    <>{formatTableDate(data)}</>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={userCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <UserSelectionModal
                shown={userSelectionShown}
                callback={userWasSelected} />

        </div>
    )

}