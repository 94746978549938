import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useRef, useState} from "react";
import {UrlUtil} from "../../../util/UrlUtil";
import {EmergingButton} from "../../button/EmergingButton";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {useParams} from "react-router-dom";
import Validator from "../../../util/Validator";
import {Toast} from "../../toast/TokyoToaster";
import {DocumentVersionModal} from "./DocumentVersionModal";
import {Navigator} from "../../../util/Navigator";

export const ProjectDocumentDetailScreen = (props) => {

    const {sectionID} = useParams();
    const {id} = useParams();

    const fileInput = useRef();

    const [document, setDocument] = useState(null);
    const [documentNetworkInFlight, setDocumentNetworkInFlight] = useState(false);

    const [documentTitle, setDocumentTitle] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");

    const [uploadProgress, setUploadProgress] = useState(50);
    const [uploadNetworkInFlight, setUploadNetworkInFlight] = useState(false);

    const [documentVersionShown, setDocumentVersionShown] = useState(false);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    useState(() => {
        if (id !== undefined && id !== "new") {
            fetchDocumentFromNetwork();
        }
    }, []);


    function onFileDidChange(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            uploadFileOverNetwork(file);
        }
    }

    function handleVersionCallback(action, data) {
        if (action === "close" || action === "dismiss") {
            setDocumentVersionShown(false);
        }
    }

    function promptDeletionConfirm() {
        AlertModal.showModal(
            Rosetta.string("document_management.deletion_prompt_title"),
            Rosetta.string("document_management.deletion_prompt_message"),
            [
                AlertModal.button(
                    Rosetta.string("common.delete"),
                    () => {
                        deleteDocumentOverNetwork();
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchDocumentFromNetwork() {
        if (documentNetworkInFlight) return;
        setDocumentNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = {
            projectID : (project ? project.id : undefined),
            sectionID : sectionID,
            documentID : id
        };

        Axios.post(ENDPOINTS.project.getProjectDocument, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let document = resp.data.document;
                    if (document) {
                        setDocument(document);
                        setDocumentTitle(document.title);
                        setDocumentDescription(document.description);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDV1000C" }));
            })
    }

    function submitDocumentOverNetwork() {
        if (documentNetworkInFlight) return;

        let result = Validator.validateCreateFormData({
            documentTitle,
            documentDescription
        }, [
            Validator.rule("documentTitle", "string", Rosetta.string("document_management.detail_document_title"), "title"),
            Validator.rule("documentDescription", "string", Rosetta.string("document_management.detail_document_description"), "description", true)
        ]);

        if (!result.success) {
            AlertModal.showError(result.error);
            return;
        }

        setDocumentNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = result.formData;
        formData.append("projectID", project ? project.id : undefined);

        if (document) {
            formData.append("id", document.id);
        }

        if (sectionID) {
            formData.append("projectDocumentSectionID", sectionID);
        }

        Axios.post(ENDPOINTS.project.submitProjectDocument, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setDocument(resp.data.document);
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_management.detail_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDU1000C" }));
            });
    }

    function uploadFileOverNetwork(file) {
        if (uploadNetworkInFlight) return;
        setUploadNetworkInFlight(true);

        const project = DataManager.getSelectedProject();

        let formData = new FormData();
        formData.append("projectID",project ? project.id : undefined);
        formData.append("documentID", document.id);
        formData.append("file", file);

        let config = {
            onUploadProgress : (progressEvent) => {
                let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                let progress = Math.ceil((progressEvent.loaded / progressTotal) * 100);

                setUploadProgress(progress);
            }
        }

        Axios.post(ENDPOINTS.project.uploadProjectDocumentFile, formData, config)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let file = resp.data.file;
                    let doc = document;
                    if (doc) {
                        doc.filePath = file.filePath;
                    }

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_management.detail_file_upload_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUploadNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUploadNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDU1000C" }));
            })
    }

    function deleteDocumentOverNetwork() {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        const data = {
            projectID : document.projectID,
            projectDocumentID : document.id
        };

        Axios.post(ENDPOINTS.project.removeProjectDocument, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_management.deletion_prompt_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    Navigator.navigate("/documents/" + sectionID);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDeleteNetworkInFlight(false);
                AlertModal.showError(API.defaultError("PDR1000C"));
            })
    }

    // RENDER

    let fileCardElem = [];
    let deleteButton = [];
    if (document && document.id !== undefined) {
        let fileDetailsElem = [];
        if (!uploadNetworkInFlight) {
            if (document && document.filePath !== null) {
                fileDetailsElem = (
                    <a href={document ? document.filePath : null}>{UrlUtil.getLastPathPart((document ? document.filePath : null))}</a>
                )
            } else {
                fileDetailsElem = (
                    <div className={"text-center"}>{Rosetta.string("document_management.details_file_none")}</div>
                )
            }
        } else {
            fileDetailsElem = (
                <div className={"progress"} style={{ width : "50%", margin : "auto" }}>
                    <div className={"progress-bar"} style={{width : uploadProgress + "%"}}/>
                </div>
            )
        }

        fileCardElem = (
            <div className={"col-12 col-md-6 mt-4"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <h3>{Rosetta.string("document_management.detail_files")}</h3>
                            </div>
                        </div>

                        <div className={"row mt-1"}>
                            <div className={"col-12 text-center"}>
                                {fileDetailsElem}
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <div className={"mt-2"}>
                                    <div className={"file-hide"}>
                                        <input type={"file"} ref={fileInput} onChange={onFileDidChange} />
                                    </div>
                                    <button className={"btn btn-primary"} onClick={() => fileInput.current.click()}>{Rosetta.string("document_management.detail_upload")}</button>
                                </div>
                                <div className={"mt-2"}>
                                    <EmergingButton pill={true}>
                                        <button className={"btn"} onClick={() => setDocumentVersionShown(true)}>{Rosetta.string("document_management.detail_history")}</button>
                                    </EmergingButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        deleteButton = (
            <button className={"btn btn-danger disabled"}>{Rosetta.string("common.please_wait")}</button>
        );
        if (!deleteNetworkInFlight) {
            deleteButton = (
                <button className={"btn btn-danger"} onClick={() => promptDeletionConfirm()}>{Rosetta.string("common.delete")}</button>
            )
        }

    }

    return (
        <div className={"app-screen project-document-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-10"}>
                    <ScreenTitle title={Rosetta.string("document_management.detail_title")} />
                </div>
                <div className={"col-12 col-md-2 text-end"}>
                    {deleteButton}
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12 col-md-6 mt-4"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("document_management.detail_document_title")}</label>
                                    <input type={"text"} className={"form-control"} value={documentTitle} onChange={(e) => setDocumentTitle(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("document_management.detail_document_description")}</label>
                                    <textarea className={"form-control"} value={documentDescription} onChange={(e) => setDocumentDescription(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 text-center"}>
                                    <button className={"btn btn-primary"} onClick={() => submitDocumentOverNetwork()}>{Rosetta.string("common.save")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {fileCardElem}

            </div>

            <DocumentVersionModal
                shown={documentVersionShown}
                versions={(document ? document.versions : [])}
                callback={handleVersionCallback} />
        </div>
    )

}