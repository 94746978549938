export const UrlUtil = {
    getLastPathPart : (url) => {
        if (url) {
            let urlParts = url.split("/");
            if (urlParts.length > 0) {
                return urlParts[urlParts.length - 1];
            }
        }
        return url;
    }
}