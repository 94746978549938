import RosettaAwareComponent from "./RosettaAwareComponent";
import BaseComponent from "./BaseComponent";
import AlertModal from "./alertmodal/AlertModal";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InitNavigator, Navigator} from "../util/Navigator";
import {TokyoToaster} from "./toast/TokyoToaster";
import {DataManager} from "../data/DataManager";
import {API} from "../network/API";
import {TokyoHeaderBar, TokyoHeaderBarActions} from "./headerbar/TokyoHeaderBar";

import logoImage from "../assets/images/header_logo_2.svg";
import {LoginScreen} from "./screens/login/LoginScreen";
import {EmergingButton} from "./button/EmergingButton";
import {TokyoNavigationList} from "./navigationlist/TokyoNavigationList";
import {ProjectSelectionScreen} from "./screens/projectSelection/ProjectSelectionScreen";
import Rosetta from "../rosetta/Rosetta";
import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "./offcanvas/Offcanvas";
import {SettingsPanelContent} from "./settings/SettingsPanelContent";
import {ProjectInformationScreen} from "./screens/projectInformation/ProjectInformationScreen";
import {ProjectInformationEditorScreen} from "./screens/projectInformation/ProjectInformationEditorScreen";
import WindowUtil from "../util/WindowUtil";
import {DocumentCaptureListScreen} from "./screens/documentCapture/DocumentCaptureListScreen";
import {DocumentCaptureDetailScreen} from "./screens/documentCapture/DocumentCaptureDetailScreen";
import {FormsListScreen} from "./screens/forms/FormsListScreen";
import {FormDetailScreen} from "./screens/forms/FormDetailScreen";
import {Comment} from "./comment/Comment";
import {SnaggingLogScreen} from "./screens/snags/SnaggingLogScreen";
import {SnagsDrawingSnagsScreen} from "./screens/snags/SnagsDrawingSnagsScreen";
import {SnagDrawingSnagDetailScreen} from "./screens/snags/SnagDrawingSnagDetailScreen";
import {CalendarScreen} from "./screens/calendar/CalendarScreen";
import {HealthSafetyScreen} from "./screens/healthSafety/HealthSafetyScreen";
import {ReportDetailScreen} from "./screens/healthSafety/report/ReportDetailScreen";
import {InductionDetailScreen} from "./screens/healthSafety/induction/InductionDetailScreen";
import {ProjectDocumentSectionScreen} from "./screens/projectDocument/ProjectDocumentSectionScreen";
import {ProjectDocumentDetailScreen} from "./screens/projectDocument/ProjectDocumentDetailScreen";
import {OrganiserHomeScreen} from "./screens/organiser/OrganiserHomeScreen";
import {SiteRegisterListScreen} from "./screens/siteRegister/SiteRegisterListScreen";
import {DailyDiaryScreen} from "./screens/diary/DailyDiaryScreen";
import {UsersListScreen} from "./screens/settings/UsersListScreen";
import {UserDetailScreen} from "./screens/settings/UserDetailScreen";
import {ProjectListScreen} from "./screens/settings/ProjectListScreen";
import {ClientEditorScreen} from "./screens/settings/ClientEditorScreen";
import {UnderConstructionScreen} from "./screens/debug/UnderConstructionScreen";
import {DrawingDetailScreen} from "./screens/snags/DrawingDetailScreen";
import {SubscriptionLandingScreen} from "./screens/subscription/SubscriptionLandingScreen";
import {SubscriptionSettingsScreen} from "./screens/settings/SubscriptionSettingsScreen";
import {SubscriptionEditorSettingsScreen} from "./screens/settings/SubscriptionEditorSettingsScreen";
import {ClientsLandingComponent} from "./screens/cms/clients/ClientsLandingComponent";
import {ContentConfigLandingScreen} from "./screens/cms/contentConfig/ContentConfigLandingScreen";
import {FormListScreen} from "./screens/cms/contentConfig/FormListScreen";
import {FormEditorScreen} from "./screens/cms/contentConfig/FormEditorScreen";
import {FormSubmittableListScreen} from "./screens/cms/contentConfig/FormSubmittableListScreen";
import {ProjectSubmittableEditorScreen} from "./screens/cms/contentConfig/ProjectSubmittableEditorScreen";
import {DocumentRequestSectionScreen} from "./screens/cms/contentConfig/documents/DocumentRequestSectionScreen";
import {
    DocumentCollectionTypeListScreen
} from "./screens/cms/contentConfig/documentCollection/DocumentCollectionTypeListScreen";
import {
    DocumentCollectionListScreen
} from "./screens/cms/contentConfig/documentCollection/DocumentCollectionListScreen";
import {AdminUserListScreen} from "./screens/cms/users/AdminUserListScreen";
import {UserGuideLandingScreen} from "./screens/cms/userGuide/UserGuideLandingScreen";
import {FormUserGuideContentScreen} from "./screens/cms/userGuide/form/UGFormOverviewScreen";
import {AppCounts} from "../util/AppCounts";
import {InvitationScreen} from "./screens/settings/InvitationScreen";
import {CommonUtil} from "../util/CommonUtil";
import {WelcomeUserScreen} from "./screens/welcome/WelcomeUserScreen";

export default class MainComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let project = DataManager.getSelectedProject();
        let subscriptionActive = DataManager.isSubscriptionActive();

        this.initState({
            alertModalOpen : false,
            settingsShown : false,
            clientId : null,
            project,
            subscriptionActive
        });
    }

    componentDidMount() {
        let user = DataManager.getUser();

        // Apply Session Token
        let sessionToken = DataManager.getSessionToken();
        if (sessionToken !== null) {
            API.setAuthenticationToken(sessionToken);
        }

        let clientId = DataManager.getClientId();
        if (clientId !== null) {
            API.setClientId(clientId);
        }

        API.setAuthFailureCallback(() => {
            this.userDidLogout();
        });

        this.dataManagerCallback = (keyName, value) => {
            console.log("DATA MANAGER CHANGE: " + keyName + " = " + value);
            if (keyName === DataManager.keys.user) {
                if (!CommonUtil.isObject(value)) {
                    value = JSON.parse(value);
                }
                if (value !== this.state.user) {
                    this.userDidChange(value);
                }
            } else if (keyName === DataManager.keys.sessionToken) {
                API.setAuthenticationToken(value);
            } else if (keyName === DataManager.keys.clientId) {
                API.setClientId(value);
            } else if (keyName === DataManager.keys.project) {
                this.setState({
                    project : DataManager.getSelectedProject()
                });
                AppCounts.refreshAppCounts(DataManager.getSelectedProject().id);
            } else if (keyName.includes(DataManager.keys.app_counts)) {
                let appCounts = JSON.parse(value);
                if (appCounts) {
                    let project = DataManager.getSelectedProject();
                    if (project) {
                        if (parseInt(project.id) === parseInt(appCounts.projectID)) {
                            this.setState({
                                appCounts
                            });
                        }
                    }
                }
            } else if (keyName === DataManager.keys.subscriptionActive) {
                this.setState({
                    subscriptionActive : DataManager.isSubscriptionActive()
                });
            }
        };

        DataManager.addCallback(this.dataManagerCallback);

        this.setState({
            user,
            clientId
        });

        let project = DataManager.getSelectedProject();
        if (project) {
            AppCounts.refreshAppCounts(project.id);
        }

        // Set up global Alert Dialog callbacks
        if (!window.hasOwnProperty("_showModal")) {
            window._showModal = (title, message, buttons, icon) => {
                this.showModal(title, message, buttons, icon);
            }
        }

        if (!window.hasOwnProperty("_hideModal")) {
            window._dismissModal = () => {
                this.dismissModal();
            }
        }
    }

    showModal = (title, message, buttons, icon) => {
        this.setState({
            alertModalOpen : true,
            alertModalIcon : icon,
            alertModalTitle : title,
            alertModalMessage : message,
            alertModalButtons : buttons
        })
    }

    dismissModal = () => {
        this.setState({
            alertModalOpen : false
        });
    }

    userDidChange = (user) => {
        if (user != null && user !== "null") {
            this.setState({
                user
            });
        } else {
            this.userDidLogout();
        }
    }

    userDidLogout = () => {
        DataManager.clear();

        this.setState({
            user : null
        });

        // Force page refresh at this point
        window.location.href = "/";
    }

    headerBarDidCallback = (action) => {
        if (action === TokyoHeaderBarActions.SIGNOUT) {
            this.userDidLogout();
        } else if (action === TokyoHeaderBarActions.PROFILE_CLICKED) {
            // Show settings side bar
            this.showSettingsSidebar();
        }
    }

    showSettingsSidebar = () => {
        WindowUtil.lockBodyScroll();
        this.setState({
            settingsShown : true
        });
    }

    settingsSidebarDidCallback = (action) => {
        if (action === OffcanvasActions.CLOSE) {
            WindowUtil.unlockBodyScroll();
            this.setState({
                settingsShown : false
            });
        } else if (action === "signout") {
            AlertModal.showModal(
                Rosetta.string("common.sign_out"),
                Rosetta.string("common.sign_out_prompt"),
                [
                    {
                        label : Rosetta.string("common.sign_out"),
                        className : "danger",
                        click : () => {
                            this.headerBarDidCallback("signout");
                            AlertModal.dismissModal();
                        }
                    },
                    {
                        label : Rosetta.string("common.cancel"),
                        click : () => {
                            AlertModal.dismissModal();
                        }
                    }
                ]
            );
        }
    }

    render() {
        let mainContent = [];

        let navContent = [];
        let sidebarContent = [];

        let mainRoutes = [];

        if (this.state.user !== null) {
            if (process.env.REACT_APP_MODE === "cms") {
                sidebarContent = (
                    <TokyoNavigationList
                        items={[
                            {
                                title : "",
                                items : [
                                    {
                                        title : Rosetta.string("navigation.cms_clients"),
                                        url : "/"
                                    },
                                    {
                                        title : Rosetta.string("navigation.cms_content_config"),
                                        url : "/content-config"
                                    },
                                    {
                                        title : Rosetta.string("navigation.cms_admin_users"),
                                        url : "/admin-users"
                                    }
                                ]
                            }]
                        } />
                );

                mainRoutes = (
                    <>
                        <Route
                            path={""}
                            element={<ClientsLandingComponent />} />

                        <Route
                            path={"/client/:clientID"}
                            element={<ClientEditorScreen />} />

                        <Route
                            path={"/content-config"}
                            element={<ContentConfigLandingScreen />} />

                        <Route
                            path={"/content-config/forms"}
                            element={<FormListScreen />} />

                        <Route
                            path={"/content-config/form/:formID"}
                            element={<FormEditorScreen />} />

                        <Route
                            path={"/content-config/form-submittables/"}
                            element={<FormSubmittableListScreen />} />

                        <Route
                            path={"/content-config/form-submittable/:id"}
                            element={<ProjectSubmittableEditorScreen />} />

                        <Route
                            path={"/content-config/document-requests/"}
                            element={<DocumentRequestSectionScreen />} />

                        <Route
                            path={"/content-config/document-requests/section/:sectionID"}
                            element={<DocumentRequestSectionScreen />} />

                        <Route
                            path={"/content-config/collection-documents/"}
                            element={<DocumentCollectionTypeListScreen />} />

                        <Route
                            path={"/content-config/collection-documents/:typeID"}
                            element={<DocumentCollectionListScreen />} />

                        <Route
                            path={"/admin-users/"}
                            element={<AdminUserListScreen />} />

                        <Route
                            path={"/user-guide"}
                            element={<UserGuideLandingScreen />} />

                        <Route
                            path={"/user-guide/forms/:contentID/:uselessName"}
                            element={<FormUserGuideContentScreen />} />

                    </>
                )
            } else {
                let projectLabel = Rosetta.string("header.selected_project_unset");
                if (this.state.project !== null) {
                    projectLabel = this.state.project.project_name;
                }

                navContent = (
                    <div className={"header-nav-content"}>
                        <EmergingButton>
                            <div className={"header-project-selector"} onClick={() => Navigator.navigate("/select-project")}>
                                <div className={"label"}>{Rosetta.string("header.selected_project")}</div>
                                <div className={"project-name"}>{projectLabel}</div>
                            </div>
                        </EmergingButton>
                    </div>
                );

                if (this.state.project !== null && this.state.subscriptionActive) {
                    console.log("UPDATE NAV.");
                    console.log(this.state.appCounts);
                    let navItems = [
                        {
                            title : Rosetta.string("navigation.title"),
                            items : [
                                {
                                    title : Rosetta.string("navigation.site_information"),
                                    url : "/"
                                },
                                {
                                    title : Rosetta.string("navigation.site_register"),
                                    url : "/site-register"
                                },
                                {
                                    title : Rosetta.string("navigation.calendar"),
                                    url : "/calendar"
                                },
                                {
                                    title : Rosetta.string("navigation.personal_organiser"),
                                    url : "/organiser"
                                },
                                {
                                    title : Rosetta.string("navigation.health_safety"),
                                    url : "/healthSafety",
                                    badge : this.getOrDefault(this.state.appCounts, "healthSafety", 0)
                                },
                                {
                                    title : Rosetta.string("navigation.inspections_form_permits"),
                                    url : "/forms"
                                },
                                {
                                    title : Rosetta.string("navigation.daily_diary"),
                                    url : "/daily-diary"
                                },
                                {
                                    title : Rosetta.string("navigation.snagging_log"),
                                    url : "/snagging",
                                    badge : this.getOrDefault(this.state.appCounts, "snagging", 0)
                                },
                                {
                                    title : Rosetta.string("navigation.site_document_capture"),
                                    url : "/document-capture"
                                },
                                {
                                    title : Rosetta.string("navigation.document_management"),
                                    url : "/documents"
                                }
                            ]
                        }
                    ];

                    sidebarContent = (
                        <TokyoNavigationList
                            items={navItems} />
                    );
                }

                mainRoutes = (
                    <>
                        <Route
                            path={""}
                            element={<ProjectInformationScreen />} />

                        <Route
                            path={"/project/:id"}
                            element={<ProjectInformationEditorScreen />} />

                        <Route
                            path={"/select-project"}
                            element={<ProjectSelectionScreen />} />

                        <Route
                            path={"/document-capture"}
                            element={<DocumentCaptureListScreen />} />

                        <Route
                            path={"/document-capture/:id"}
                            element={<DocumentCaptureDetailScreen />} />

                        <Route
                            path={"/forms/"}
                            element={<FormsListScreen />} />

                        <Route
                            path={"/forms/:id"}
                            element={<FormDetailScreen />} />

                        <Route
                            path={"/snagging/"}
                            element={<SnaggingLogScreen />} />

                        <Route
                            path={"/snagging/drawing/:drawingID"}
                            element={<SnagsDrawingSnagsScreen />} />

                        <Route
                            path={"/snagging/snag/:snagID"}
                            element={<SnagDrawingSnagDetailScreen />} />

                        <Route
                            path={"/drawing/view/:id"}
                            element={<DrawingDetailScreen />} />

                        <Route
                            path={"/calendar/"}
                            element={<CalendarScreen />} />

                        <Route
                            path={"/healthSafety/"}
                            element={<HealthSafetyScreen />} />

                        <Route
                            path={"/healthSafety/report/:id"}
                            element={<ReportDetailScreen />} />

                        <Route
                            path={"/healthSafety/induction/:id"}
                            element={<InductionDetailScreen />} />

                        <Route
                            path={"/documents/"}
                            element={<ProjectDocumentSectionScreen />} />

                        <Route
                            path={"/documents/:id"}
                            element={<ProjectDocumentSectionScreen />} />

                        <Route
                            path={"/documents/view/:sectionID/"}
                            element={<ProjectDocumentDetailScreen />} />

                        <Route
                            path={"/documents/view/:sectionID/:id"}
                            element={<ProjectDocumentDetailScreen />} />

                        <Route
                            path={"/organiser/"}
                            element={<OrganiserHomeScreen />} />

                        <Route
                            path={"/site-register/"}
                            element={<SiteRegisterListScreen />} />

                        <Route
                            path={"/daily-diary/"}
                            element={<DailyDiaryScreen />} />

                        <Route
                            path={"/settings/users/"}
                            element={<UsersListScreen />} />

                        <Route
                            path={"/settings/users/:id"}
                            element={<UserDetailScreen />} />

                        <Route
                            path={"/settings/projects/"}
                            element={<ProjectListScreen />} />

                        <Route
                            path={"/settings/company-details/"}
                            element={<ClientEditorScreen />} />

                        <Route
                            path={"/settings/subscription-details/"}
                            element={<SubscriptionSettingsScreen />} />

                        <Route
                            path={"/settings/subscription-details/modify/"}
                            element={<SubscriptionEditorSettingsScreen />} />

                        <Route
                            path={"/settings/invitations/"}
                            element={<InvitationScreen />} />

                        <Route
                            path={"/welcome"}
                            element={<WelcomeUserScreen />} />
                    </>
                )
            }

            if (!this.state.subscriptionActive) {
                // Restrict visible views when subscription has lapsed.
                //navContent = [];

                mainRoutes = [
                    <Route
                        path={"/"}
                        element={<SubscriptionSettingsScreen />} />,

                    <Route
                        path={"/select-project"}
                        element={<ProjectSelectionScreen />} />,

                    <Route
                        path={"/settings/subscription-details/"}
                        element={<SubscriptionSettingsScreen />} />,

                    <Route
                        path={"/settings/subscription-details/modify/"}
                        element={<SubscriptionEditorSettingsScreen />} />
                ]
            }

            mainContent = (
                <>
                    <div className={"header-wrapper"}>
                        <div className={"container"}>
                            <TokyoHeaderBar
                                logo={{image : logoImage}}
                                navigationContent={navContent}
                                callback={this.headerBarDidCallback} />
                        </div>
                    </div>

                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-5 col-md-4 col-lg-3"}>
                                {sidebarContent}
                            </div>
                            <div className={"col-7 col-md-8 col-lg-9"}>
                                <Routes>
                                    {mainRoutes}
                                </Routes>
                            </div>
                        </div>

                        <Offcanvas
                            shown={this.state.settingsShown}
                            gravity={OffcanvasGravity.END}
                            callback={this.settingsSidebarDidCallback}>

                            <SettingsPanelContent
                                callback={this.settingsSidebarDidCallback} />

                        </Offcanvas>
                    </div>
                </>
            )
        } else {
            let routes = [
                <Route
                    path={""}
                    element={<LoginScreen />} />
            ];

            if (process.env.REACT_APP_MODE !== "cms") {
                routes.push(
                    <Route
                        path={"/subscription"}
                        element={<SubscriptionLandingScreen />} />
                );

                routes.push(
                    <Route
                        path={"/welcome"}
                        element={<WelcomeUserScreen />} />
                );
            }

            // Login
            mainContent = (
                <Routes>
                    {routes}
                </Routes>
            )
        }

        return (
            <BrowserRouter>
                <InitNavigator />

                <RosettaAwareComponent callback={() => this.setState({rosettaMutationKey : Math.random()})}>
                    <TokyoToaster>
                        {mainContent}
                    </TokyoToaster>
                </RosettaAwareComponent>

                <AlertModal
                    open={this.state.alertModalOpen}
                    icon={this.state.alertModalIcon}
                    title={this.state.alertModalTitle}
                    message={this.state.alertModalMessage}
                    buttons={this.state.alertModalButtons}
                    dismissCallback={this.dismissModal} />

            </BrowserRouter>
        )
    }

}
