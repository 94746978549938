import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Validator from "../../../util/Validator";
import AlertModal from "../../alertmodal/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

export const OrganiserNoteEditorModal = (props) => {

    const {id} = props;
    const {noteTypeID} = props;
    const {shown} = props;

    const [note, setNote] = useState();
    const [noteTitle, setNoteTitle] = useState();
    const [noteText, setNoteText] = useState();

    const [outputValue, setOutputValue] = useState(undefined);

    const [noteNetworkInFlight, setNoteNetworkInFlight] = useState(false);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            if (id) {
                fetchNoteFromNetwork();
            }
        } else {
            setNote(null);
            setNoteTitle("");
            setNoteText("");
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, outputValue);
        }
    }

    function fetchNoteFromNetwork() {
        if (noteNetworkInFlight) return;
        setNoteNetworkInFlight(true);

        let formData = {
            id,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.personal.getUserNotes, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.notes.length > 0) {
                        let note = resp.data.notes[0];
                        setNoteTitle(note.title);
                        setNoteText(note.text);
                        setNote(note);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNoteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNoteNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PNE1100" }));
            })
    }

    function submitNoteOverNetwork() {
        if (noteNetworkInFlight) return;
        setNoteNetworkInFlight(true);

        const validationResult = Validator.validateCreateFormData({
            noteTitle,
            noteText
        }, [
            Validator.rule("noteTitle", "string", Rosetta.string("organiser.note_editor_label_title"), "title"),
            Validator.rule("noteText", "string", Rosetta.string("organiser.note_editor_label_text"), "text")
        ]);

        if (!validationResult) {
            AlertModal.showError(validationResult.error);
            return;
        }

        let formData = validationResult.formData;
        formData.append("noteTypeID", noteTypeID);

        if (note) {
            formData.append("id", note.id);
        }

        Axios.post(ENDPOINTS.personal.submitUserNote, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    setOutputValue(resp.data.note);
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNoteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PNE1000" }));
                setNoteNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let submitButton = (
        <div className={"row mt-4"}>
            <div className={"col-12 text-end"}>
                <button className={"btn btn-primary"} onClick={() => submitNoteOverNetwork()}>{Rosetta.string("common.save")}</button>
            </div>
        </div>
    );
    if (noteNetworkInFlight) {
        submitButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} small={true} />
                </div>
            </div>
        );
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("organiser.note_editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("organiser.note_editor_label_title")}</label>
                    <input type={"text"} className={"form-control"} value={noteTitle} readOnly={noteNetworkInFlight} onChange={(e) => setNoteTitle(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("organiser.note_editor_label_text")}</label>
                    <textarea className={"form-control"} value={noteText} readOnly={noteNetworkInFlight} onChange={(e) => setNoteText(e.target.value)} />
                </div>
            </div>

            {submitButton}

        </Offcanvas>
    )

}