import BaseComponent from "../../BaseComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {UIBlocker} from "../../loading/UIBlocker";
import Validator from "../../../util/Validator";
import AlertModal from "../../alertmodal/AlertModal";

export default class ProjectInformationEditorComponent extends BaseComponent {


    constructor(props, context) {
        super(props, context);

        this.initState({});
    }

    componentDidMount() {
        if (this.props.project !== undefined) {
            this.populateProjectIntoState(this.props.project);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.project !== undefined) {
            if (this.props.project !== prevProps.project) {
                this.populateProjectIntoState(this.props.project);
            }
        }
    }

    populateProjectIntoState = (project) => {
        this.populateObjectIntoState(project, "project_", undefined, false);
        this.setState({
            project
        });
    }

    createInputField = (dataKey, label, className, placeholder, type, required) => {
        if (className === undefined) {
            className = "";
        } else {
            className = " " + className;
        }

        if (type === undefined) {
            type = "text";
        }

        let value = "";
        if (this.state !== null) {
            if (this.state.hasOwnProperty(dataKey)) {
                value = this.state[dataKey];
            }
        }

        if (required) {
            label += " *";
        }

        return (
            <div className={"row mb-1"}>
                <div className={"col-12"}>
                    <label>{label}</label>
                    <input type={type} className={"form-control" + className} name={dataKey} value={value} placeholder={placeholder} onChange={this.handleChange} />
                </div>
            </div>
        );
    }

    submitForm = () => {
        let result = Validator.validateCreateFormData(this.state, [
            Validator.rule("project_project_name", "string", Rosetta.string("site_information.details_name"), "project_name"),
            Validator.rule("project_project_code", "string", Rosetta.string("site_information.details_code"), "project_code"),
            Validator.rule("project_address1", "string", Rosetta.string("site_information.details_address1"), "address1", true),
            Validator.rule("project_address2", "string", Rosetta.string("site_information.details_address2"), "address2", true),
            Validator.rule("project_town", "string", Rosetta.string("site_information.details_town"), "town", true),
            Validator.rule("project_county", "string", Rosetta.string("site_information.details_county"), "county", true),
            Validator.rule("project_postcode", "string", Rosetta.string("site_information.details_postcode"), "postcode", true),
            Validator.rule("project_short_description", "string", Rosetta.string("site_information.description_title"), "short_description", true),
            Validator.rule("project_hospital_name", "string", Rosetta.string("site_information.hospital_ae_name"), "hospital_name", true),
            Validator.rule("project_hospital_address1", "string", Rosetta.string("site_information.hospital_ae_address1"), "hospital_address1", true),
            Validator.rule("project_hospital_address2", "string", Rosetta.string("site_information.hospital_ae_address2"), "hospital_address2", true),
            Validator.rule("project_hospital_town", "string", Rosetta.string("site_information.hospital_ae_town"), "hospital_town", true),
            Validator.rule("project_hospital_county", "string", Rosetta.string("site_information.hospital_ae_county"), "hospital_county", true),
            Validator.rule("project_hospital_postcode", "string", Rosetta.string("site_information.hospital_ae_postcode"), "hospital_postcode", true),
            Validator.rule("project_hospital_telephone", "string", Rosetta.string("site_information.hospital_ae_phone"), "hospital_telephone", true),
            Validator.rule("project_hospital_lat", "string", Rosetta.string("site_information.hospital_ae_latitude"), "hospital_latitude", true),
            Validator.rule("project_hospital_lon", "string", Rosetta.string("site_information.hospital_ae_longitude"), "hospital_longitude", true)
        ]);

        if (result.success) {
            let formData = result.formData;
            if (this.state.project) {
                formData.append("id", this.state.project.id);
                formData.append("hospitalID", this.state.project.hospitalID);
            }

            if (this.props.callback !== undefined) {
                this.props.callback("submit", formData);
            }
        } else {
            console.log("ERROR: " + result.error);
            AlertModal.showError(result.error);
        }
    }

    render() {
        const detailInputs = [
            { key : "project_name", label : "site_information.details_name", required : true },
            { key : "project_code", label : "site_information.details_code", required : true },
            { key : "address1", label : "site_information.details_address1" },
            { key : "address2", label : "site_information.details_address2" },
            { key : "town", label : "site_information.details_town" },
            { key : "county", label : "site_information.details_county" },
            { key : "postcode", label : "site_information.details_postcode" }
        ];

        const hospitalInputs = [
            { key : "hospital_name", label : "site_information.hospital_ae_name" },
            { key : "hospital_address1", label : "site_information.hospital_ae_address1" },
            { key : "hospital_address2", label : "site_information.hospital_ae_address2" },
            { key : "hospital_town", label : "site_information.hospital_ae_town" },
            { key : "hospital_county", label : "site_information.hospital_ae_county" },
            { key : "hospital_postcode", label : "site_information.hospital_ae_postcode" },
            { key : "hospital_telephone", label : "site_information.hospital_ae_phone" },
            { key : "hospital_lat", label : "site_information.hospital_ae_latitude", type : "number" },
            { key : "hospital_lon", label : "site_information.hospital_ae_longitude", type : "number" }
        ]

        return (
            <div className={"project-information-editor-component animate-screen-content mt-2"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4>{Rosetta.string("site_information.details_title")}</h4>
                            </div>
                            <div className={"card-body"}>
                                {
                                    detailInputs.map((item) => this.createInputField("project_" + item.key, Rosetta.string(item.label), undefined, undefined, undefined, item.required))
                                }

                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("site_information.description_title")}</label>
                                        <textarea className={"form-control"} name={"project_short_description"} value={this.state.project_short_description} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                <h4>{Rosetta.string("site_information.hospital_title")}</h4>
                            </div>
                            <div className={"card-body"}>
                                {
                                    hospitalInputs.map((item) => this.createInputField("project_" + item.key, Rosetta.string(item.label), undefined, undefined, item.type))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} onClick={this.submitForm}>{Rosetta.string("common.save")}</button>
                    </div>
                </div>

                <UIBlocker shown={this.props.blockUI === true} />

            </div>
        )
    }

}