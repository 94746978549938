import {CommonUtil} from "../../../../util/CommonUtil";
import {ImageUtil} from "../../../../util/ImageUtil";

export const FormSignatureComponent = (props) => {

    return (
        <div className={"form-signature-component"}>
            <label>{CommonUtil.getOrDefault(props.input, "label", "")}</label>
            <div className={"signature-input ratio ratio-21x9"} style={{backgroundImage : ImageUtil.background(ImageUtil.createBase64Url(CommonUtil.getOrDefault(props.input, "value", null)))}} />
        </div>
    )

}