import {CommonUtil} from "../../../../util/CommonUtil";
import {ContentOrPlaceholder} from "../../../placeholder/ContentOrPlaceholder";
import {Form} from "../../../../util/Form";
import {Chronos} from "../../../../chronos/Chronos";

export const FormTextComponent = (props) => {

    const {placeholder} = props;

    let value = CommonUtil.getOrDefault(props.input, "value", "");

    if (props.input !== undefined) {
        if (parseInt(props.input.dataTypeID) === Form.DATA_TYPE.DATE_TIME) {
            let parsed = Chronos.withTimestampSeconds(value);

            if (parsed) {
                let outFormat = "dd/MM/yyyy HH:mm"; // TODO Custom Format
                value = parsed.format(outFormat);
            }
        }
    }

    return (
        <div className={"form-text-component"}>
            <label>
                <ContentOrPlaceholder showContent={!placeholder}>
                    {CommonUtil.getOrDefault(props.input, "label", "")}
                </ContentOrPlaceholder>
            </label>
            <span className={"common-value-wrapper"}>
                <ContentOrPlaceholder showContent={!placeholder}>
                    {value}
                </ContentOrPlaceholder>
            </span>
        </div>
    )

}