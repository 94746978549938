import {DataManager} from "../../../data/DataManager";
import {Chronos} from "../../../chronos/Chronos";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import Rosetta from "../../../rosetta/Rosetta";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import {FilterText} from "../../filter/FilterText";
import {Navigator} from "../../../util/Navigator";

export const DiaryDocumentComponent = (props) => {

    const {date} = props;

    const [updateDocs, setUpdateDocs] = useState();
    const [updateDocsCount, setUpdateDocsCount] = useState(0);
    const [updateDocsPage, setUpdateDocsPage] = useState(1);
    const [updateDocsNetworkInFlight, setUpdateDocsNetworkInFlight] = useState(false);

    const [newDocs, setNewDocs] = useState();
    const [newDocsCount, setNewDocsCount] = useState(0);
    const [newDocsPage, setNewDocsPage] = useState(1);
    const [newDocsNetworkInFlight, setNewDocsNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    useEffect(() => {
        getUpdatedDocumentsFromNetwork();
        getCreatedDocumentsFromNetwork();
    }, []);

    useEffect(() => {
        getUpdatedDocumentsFromNetwork();
    }, [updateDocsPage, date, filterKeyword]);

    useEffect(() => {
        getCreatedDocumentsFromNetwork();
    }, [newDocsPage, date, filterKeyword]);

    function onDocumentTableRowClick(document) {
        if (document) {
            Navigator.navigate("/documents/view/" + document.projectDocumentSectionID + "/" + document.id);
        }
    }

    function getUpdatedDocumentsFromNetwork() {
        if (updateDocsNetworkInFlight) return;
        setUpdateDocsNetworkInFlight(true);

        fetchFromNetwork(1, updateDocsPage, (success, resp) => {
            if (success) {
                if (resp.success) {
                    setUpdateDocs(resp.data.documents);
                    setUpdateDocsCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
            } else {
                showError(Rosetta.string("common.error_common_unknown", {error_code : "DUDF1000C"}));
            }
            setUpdateDocsNetworkInFlight(false);
        });
    }

    function getCreatedDocumentsFromNetwork() {
        if (newDocsNetworkInFlight) return;
        setNewDocsNetworkInFlight(true);

        fetchFromNetwork(2, updateDocsPage, (success, resp) => {
            if (success) {
                if (resp.success) {
                    setNewDocs(resp.data.documents);
                    setNewDocsCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }
            } else {
                showError(Rosetta.string("common.error_common_unknown", {error_code : "DNDF1000C"}));
            }
            setNewDocsNetworkInFlight(false);
        });
    }

    function fetchFromNetwork(mode, page, callback) {
        const project = DataManager.getSelectedProject();

        const chronosDate = Chronos.with(date);
        let startDate = null;
        let endDate = null;
        if (chronosDate && chronosDate.isValid()) {
            startDate = chronosDate.startOfDay().seconds();
            endDate = chronosDate.endOfDay().seconds();
        }

        const formData = {
            projectID : (project ? project.id : undefined),
            mode,
            page,
            keyword : filterKeyword,
            startDate,
            endDate
        };

        Axios.post(ENDPOINTS.project.getProjectDocumentUpdates, formData)
            .then((r) => {
                callback(true, API.parse(r));
            })
            .catch((e) => {
                console.log(e);
                callback(false);
            });
    }

    return (
        <div className={"animate-screen-content diary-health-safety-component"}>
            <div className={"row mt-4"}>

                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h4>{Rosetta.string("diary.doc_updated_title")}</h4>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            data={updateDocs}
                            className={"clickable"}
                            placeholderMode={updateDocsNetworkInFlight}
                            placeholderRows={5}
                            onRowClick={onDocumentTableRowClick}>

                            <Column name={"title"} title={Rosetta.string("document_management.table_document_name")} />
                            <Column name={"updateDate"} title={Rosetta.string("document_management.table_document_created")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"id"} title={""} className={"text-end"} render={(data, row) => (
                                <button className={"btn btn-outline-primary"}>{Rosetta.string("common.view")}</button>
                            )} />

                        </TableComponent>
                    </div>

                    <div className={"ep-table-paginate-container mt-1"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={updateDocsPage}
                            totalCount={updateDocsCount}
                            pageSize={20}
                            onClick={setUpdateDocsPage} />
                    </div>

                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <h4>{Rosetta.string("diary.doc_created_title")}</h4>
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"table-container"}>
                        <TableComponent
                            data={newDocs}
                            className={"clickable"}
                            placeholderMode={newDocsNetworkInFlight}
                            placeholderRows={5}
                            onRowClick={onDocumentTableRowClick}>

                            <Column name={"title"} title={Rosetta.string("document_management.table_document_name")} />
                            <Column name={"uploadDate"} title={Rosetta.string("document_management.table_document_created")} className={"text-center"} render={(data, row) => (
                                <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                            )} />
                            <Column name={"id"} title={""} className={"text-end"} render={(data, row) => (
                                <button className={"btn btn-outline-primary"}>{Rosetta.string("common.view")}</button>
                            )} />

                        </TableComponent>
                    </div>

                    <div className={"ep-table-paginate-container mt-1"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={newDocsPage}
                            totalCount={newDocsCount}
                            pageSize={20}
                            onClick={setNewDocsPage} />
                    </div>

                </div>
            </div>

        </div>
    )

}