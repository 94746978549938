import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import Rosetta from "../../../rosetta/Rosetta";
import {PlaceholderText} from "../../placeholder/PlaceholderText";
import WindowUtil from "../../../util/WindowUtil";
import {FilterText} from "../../filter/FilterText";

export const UserSelectionModal = (props) => {

    const {shown} = props;
    const {gravity} = props;

    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState("");

    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
            getUsersFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();
            setUsers([]);
            setUserCount(0);
            setCurrentPage(1);
            setKeyword("");
            setForceDismiss(false);
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        getUsersFromNetwork();
    }, [currentPage]);

    useEffect(() => {
        setUsers([]);
        setUserCount(0);
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            getUsersFromNetwork();
        }
    }, [keyword]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action, callbackData);
        }
    }

    function selectUser(user) {
        setCallbackData(user);
        setForceDismiss(true);
    }

    function getUsersFromNetwork() {
        if (!shown || userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        let formData = {
            keyword,
            page : currentPage
        };

        Axios.post(ENDPOINTS.user.getUsers, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let newUsers = [...users];
                    for (let i = 0; i < resp.data.users.length; i++) {
                        newUsers.push(resp.data.users[i]);
                    }

                    setUsers(newUsers);
                    setUserCount(resp.data.count);
                } else {
                    // TODO Error?
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                // TODO Error?
                setUserNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let userContent = [];
    if (users.length > 0) {
        for (let i = 0; i < users.length; i++) {
            let formattedName = Rosetta.string("common.name_format", {given_name : users[i].firstName, family_name : users[i].surname});

            userContent.push(
                <span className="list-group-item clickable" onClick={() => selectUser(users[i])}>{formattedName}</span>
            )
        }
    }

    let loadMoreButton = [];

    if (userCount > users.length) {
        loadMoreButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setCurrentPage(currentPage + 1)}>{Rosetta.string("common.load_more")}</button>
                </div>
            </div>
        );
    }

    if (userNetworkInFlight) {
        loadMoreButton = [];

        for (let i = 0; i < 3; i++) {
            userContent.push(
                <span className="list-group-item">
                    <PlaceholderText />
                </span>
            )
        }
    }

    if (userContent.length > 0) {
        userContent = (
            <div className="list-group">
                {userContent}
            </div>
        );
    } else {
        userContent = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        );
    }

    return (
        <Offcanvas
            title={Rosetta.string("users.user_selection_title")}
            shown={true}
            gravity={(gravity !== undefined) ? gravity : OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    {userContent}
                </div>
            </div>

            {loadMoreButton}

        </Offcanvas>
    )
}