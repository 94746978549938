import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {DataManager} from "../../../data/DataManager";
import {Navigator} from "../../../util/Navigator";
import {ScreenTitle} from "../screenTitle/ScreenTitle";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import "./ProjectSelectionScreen.css";
import {ImageUtil} from "../../../util/ImageUtil";
import AlertModal from "../../alertmodal/AlertModal";
import {AppUser} from "../../../util/AppUser";

export const ProjectSelectionScreen = () => {

    const [projectNetworkInFlight, setProjectNetworkInFlight] = useState(false);
    const [projects, setProjects] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setPage] = useState(1);

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, []);

    useEffect(() => {
        fetchProjectsFromNetwork();
    }, [currentPage]);

    function tableRowWasClicked(row) {
        if (row !== undefined && row !== null) {
            DataManager.setClientId(row.clientID);
            DataManager.setSelectedProject(row);

            if (row.hasOwnProperty("subscriptionActive")) {
                DataManager.setSubscriptionActive(parseInt(row.subscriptionActive) === 1);
            }

            if (row.hasOwnProperty("userRoleId")) {
                const user = DataManager.getUser();
                if (user) {
                    user.userRoleId = row.userRoleId;
                }
                DataManager.setUser(user);
            }

            Navigator.navigate("/");
        }
    }

    function promptLeaveOrganisation(client) {
        AlertModal.showModal(
            Rosetta.string("project_selection.client_leave_prompt_title"),
            Rosetta.string("project_selection.client_leave_prompt_message", {client_name : client.company_name}),
            [
                AlertModal.button(
                    Rosetta.string("project_selection.client_leave_prompt_confirm"),
                    () => {
                        leaveClientOverNetwork(client);
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function fetchProjectsFromNetwork() {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        let formData = {
            page : currentPage,
            pageSize: 1000,
            byClient : true,
            restrictUserType : JSON.stringify([AppUser.roles.SITE_MANAGER, AppUser.roles.LEAD_ADMIN, AppUser.roles.SYSTEM_ADMIN])
        };

        Axios.post(ENDPOINTS.project.userProjects, formData)
            .then((r) => {
                let resp = API.parse(r);

                if (resp.success) {
                    setProjects(resp.data.projects);
                    setTotalCount(resp.data.count);
                } else {
                    showError(API.formatError(resp));
                }

                setProjectNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                showError(Rosetta.string("common.error_common_unknown", { error_code : "PS1000C"}));
                setProjectNetworkInFlight(false);
            });
    }

    function leaveClientOverNetwork(client) {
        if (projectNetworkInFlight) return;
        setProjectNetworkInFlight(true);

        const data = {
            clientId : client.id
        };

        Axios.post(ENDPOINTS.user.removeFromClient, data)
            .then((r) => {
                const resp = API.parse(r);
                setProjectNetworkInFlight(false);
                if (resp.success) {
                    fetchProjectsFromNetwork();

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("project_selection.client_leave_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                setProjectNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UCR1000C"));
            });
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    let mainContent = [];
    if (!projectNetworkInFlight && projects) {
        if (projects.length > 0) {
            mainContent = projects.map((client) => {
                return [
                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            <div className={"client-header-container"}>
                                <span className={"client-logo"} style={{backgroundImage: ImageUtil.background(client.imagePath)}} />
                                <span className={"client-title"}>
                                    <h3>{client.company_name}</h3>
                                </span>
                                <span className={"btn btn-danger"} onClick={() => promptLeaveOrganisation(client)}>{Rosetta.string("project_selection.client_leave")}</span>
                            </div>
                        </div>
                    </div>,
                    <div className={"row mt-1"}>
                        <div className={"col-12"}>
                            <div className={"table-container"}>
                                <TableComponent
                                    className={"clickable"}
                                    data={client.projects}
                                    placeholderMode={projectNetworkInFlight}
                                    placeholderRows={15}
                                    onRowClick={tableRowWasClicked}>

                                    <Column name={"project_name"} title={Rosetta.string("project_selection.table_project_name")}/>
                                    <Column name={"project_code"} className={"text-center"} title={Rosetta.string("project_selection.table_project_code")}/>
                                    <Column name={"postcode"} className={"text-center"} title={Rosetta.string("project_selection.table_project_address")}/>
                                    <Column name={"id"} className={"text-center"} render={(data, row) => {
                                        return (<button className={"btn btn-primary"}>{Rosetta.string("common.select")}</button>);
                                    }}/>

                                </TableComponent>
                            </div>
                        </div>
                    </div>
                ]
            });

            // mainContent.push(
            //     <div className={"row mt-4"}>
            //         <div className={"col-12"}>
            //             <div className={"alert alert-secondary"}>
            //                 If you are missing some Projects, they may only be available to you in the Construct Apps app. Please open the app to access these Projects.
            //             </div>
            //         </div>
            //     </div>
            // )
        } else {
            mainContent = (
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        {Rosetta.string("project_selection.empty_message")}
                    </div>
                </div>
            )
        }
    } else {
        mainContent = (
            <div className={"text-center"}>
                <LoadingSpinner inline={true}/>
            </div>
        )
    }

    return (
        <div className={"app-screen project-selection-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("project_selection.title")}/>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                {mainContent}
            </div>

        </div>
    )

}

/*
<div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={currentPage}
                        totalCount={totalCount}
                        pageSize={20}
                        onClick={(page) => setPage(page)} />
                </div>
 */