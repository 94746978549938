import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect} from "react";
import WindowUtil from "../../../util/WindowUtil";

import "./DocumentVersionModal.css";
import {Chronos} from "../../../chronos/Chronos";
import {UrlUtil} from "../../../util/UrlUtil";

export const DocumentVersionModal = (props) => {

    const {shown} = props;
    const {versions} = props;

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    if (!shown) return [];

    let listItems = [];
    if (versions && versions.length > 0) {
        listItems = versions.map((version) => (
            <div className={"list-item"}>
                <div className={"item-date"}>{Chronos.withTimestampSeconds(version.dateCreated).format("dd/MM/yyyy HH:mm")}</div>
                <div className={"item-title"}>
                    <a href={version.filePath} target={"_blank"} referrerPolicy={"no-referrer"}>{UrlUtil.getLastPathPart(version.filePath)}</a>
                </div>
            </div>
        ))
    } else {
        listItems = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        )
    }

    return (
        <div className={"document-version-modal"}>
            <Offcanvas
                shown={true}
                title={Rosetta.string("document_management.detail_history")}
                gravity={OffcanvasGravity.END}
                callback={handleCallback}>

                <div className={"history-list"}>
                    {listItems}
                </div>

            </Offcanvas>
        </div>
    )

}