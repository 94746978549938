import "./AlertModal.css";
import Rosetta from "../../rosetta/Rosetta";
import {useEffect, useState} from "react";

export const TextInputModalActions = {
    SUBMIT : "submit",
    DISMISS : "dismiss"
};

export const TextInputModal = (props) => {

    // Setup
    let {shown} = props;
    let {icon} = props;
    let {title} = props;
    let {message} = props;
    let {inputType} = props;
    let {inputClassName} = props;

    let {positiveLabel} = props;
    let {negativeLabel} = props;

    let [input, setInput] = useState("");

    // Lifecycle
    useEffect(() => {
        if (!shown) {
            setInput("");
        }
    }, []);

    // Methods
    function triggerCallback(type, data) {
        if (props.callback !== undefined) {
            props.callback(type, data);
        }
    }

    // Render
    if (!shown) return [];

    if (positiveLabel === undefined) {
        positiveLabel = Rosetta.string("common.submit");
    }

    if (negativeLabel === undefined) {
        negativeLabel = Rosetta.string("common.cancel");
    }

    if (inputClassName !== undefined) {
        inputClassName = " " + inputClassName;
    } else {
        inputClassName = "";
    }

    let inputElem = [];
    if (inputType === "textarea") {
        inputElem = (
            <textarea className={"form-control" + inputClassName} onChange={(e) => setInput(e.target.value)} />
        );
    } else {
        inputElem = (
            <input type={inputType} className={"form-control" + inputClassName} onChange={(e) => setInput(e.target.value)} />
        );
    }

    return (
        <div className={"alert-modal"}>
            <div className={"spacer"} />

            <div className={"container"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-3 col-lg-4"} />
                    <div className={"col-12 col-md-6 col-lg-4"}>
                        <div className={"alert-modal-dialog"}>
                            {icon}
                            {title}
                            <div className={"alert-modal-dialog-message"}>{message}</div>
                            <div className={"alert-modal-dialog-input"}>
                                {inputElem}
                            </div>
                            <div className={"alert-modal-dialog-buttons"}>
                                <div className={"alert-dialog-button success"} onClick={() => triggerCallback(TextInputModalActions.SUBMIT, input)}>{positiveLabel}</div>
                                <div className={"alert-dialog-button"} onClick={() => triggerCallback(TextInputModalActions.DISMISS)}>{negativeLabel}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"spacer"} />
        </div>
    );

}