import {FormInput} from "./types/FormInput";

import "./FormViewComponent.css";
import {FormLabelComponent} from "./types/FormLabelComponent";
import {FormTextComponent} from "./types/FormTextComponent";

export const FormViewComponent = (props) => {

    const {placeholderMode} = props;

    function handleCallback(action, data) {
        if (props.callback !== undefined) {
            props.callback(action, data);
        }
    }

    let formElements = [];
    if (!placeholderMode) {
        if (props.form !== undefined && props.form !== null) {
            props.form.sections.forEach((section) => {
                formElements.push(<div className={"section-title"}>{section.title}</div>);

                section.questions.forEach((question) => {
                    formElements.push(<FormInput input={question} callback={handleCallback} />);
                });
            });
        }
    } else {
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormLabelComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );

        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormLabelComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );

        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormLabelComponent placeholder={true} />
            </div>
        );
        formElements.push(
            <div className={"form-question-wrapper"}>
                <FormTextComponent placeholder={true} />
            </div>
        );
    }

    return (
        <div className={"form-view-component"}>
            {formElements}
        </div>
    )
}