import Rosetta from "../../../rosetta/Rosetta";

import successCircle from "../../../assets/images/success-circle.svg";
import {ImageUtil} from "../../../util/ImageUtil";

import "./SubscriptionFinishComponent.css";

export const SubscriptionFinishComponent = (props) => {

    const {formData} = props;

    let titleExtra = [];
    if (formData.subscriptionSku !== undefined) {
        titleExtra = (<div>{Rosetta.string("subscription.success_payment_confirmed")}</div>);
    }

    return (
        <div className={"animate-screen-content subscription-finish-component"}>
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <span className={"success-icon"} style={{backgroundImage : ImageUtil.background(successCircle)}} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <div className={"success-finish-title"}>{titleExtra} {Rosetta.string("subscription.success_title")}</div>
                    <div className={"success-finish-message"}>{Rosetta.string("subscription.success_message")}</div>
                </div>
            </div>
        </div>
    )

}