import {ScreenTitle} from "../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import {Toast} from "../../toast/TokyoToaster";
import Validator from "../../../util/Validator";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {DataManager} from "../../../data/DataManager";
import {AppUser} from "../../../util/AppUser";
import {Navigator} from "../../../util/Navigator";
import {ProjectSelectionModal} from "../common/ProjectSelectionModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const UserDetailScreen = (props) => {

    const {id} = useParams();

    const [user, setUser] = useState(null);
    const [userNetworkInFlight, setUserNetworkInFlight] = useState(false);
    const [userSubmitNetworkInFlight, setUserSubmitNetworkInFlight] = useState(false);

    const [userRoles, setUserRoles] = useState([]);
    const [rolesNetworkInFlight, setRolesNetworkInFlight] = useState(false);

    const [deleteNetworkInFlight, setDeleteNetworkInFlight] = useState(false);

    const [userFirstName, setUserFirstName] = useState();
    const [userSurname, setUserSurname] = useState();
    const [userEmailAddress, setUserEmailAddress] = useState();
    const [userTelephone, setUserTelephone] = useState();
    const [userRoleId, setUserRoleId] = useState();
    const [userJobTitle, setUserJobTitle] = useState();
    const [userCompany, setUserCompany] = useState();
    const [userProject, setUserProject] = useState(null);

    const [projectSelectionShown, setProjectSelectionShown] = useState(false);

    const currentUser = useRef(DataManager.getUser());

    useEffect(() => {
        fetchUserRolesFromNetwork();
        if (id && id !== "new") {
            fetchUserFromNetwork();
        }
    }, []);

    function populateUserIntoState(user) {
        setUser(user);
        setUserFirstName(user.firstName);
        setUserSurname(user.surname);
        setUserEmailAddress(user.emailAddress);
        setUserTelephone(user.telephone);
        setUserRoleId(user.userRoleId);
        setUserJobTitle(user.jobTitle);
        setUserCompany(user.company);
    }

    function projectSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setUserProject(data);
            }
            setProjectSelectionShown(false);
        }
    }

    function fetchUserFromNetwork() {
        if (userNetworkInFlight) return;
        setUserNetworkInFlight(true);

        let formData = {
            id
        };

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    populateUserIntoState(resp.data.user);
                } else {
                    AlertModal.showError(API.formatError(resp));

                    Navigator.navigate("/settings/users");
                }
                setUserNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "US1000C"}));

                Navigator.navigate("/settings/users");
            });
    }

    function submitUserOverNetwork() {
        if (userSubmitNetworkInFlight) return;

        let projectIdOptional = !!user;

        const validationResult = Validator.validateCreateFormData({
            userFirstName,
            userSurname,
            userEmailAddress,
            userTelephone,
            userRoleId,
            userJobTitle,
            userCompany,
            userProjectId : userProject ? userProject.id : undefined
        }, [
            Validator.rule("userFirstName", "string", Rosetta.string("system_settings.user_first_name"), "firstName"),
            Validator.rule("userSurname", "string", Rosetta.string("system_settings.user_surname"), "surname"),
            Validator.rule("userEmailAddress", "string", Rosetta.string("system_settings.user_email"), "emailAddress"),
            Validator.rule("userRoleId", "int", Rosetta.string("system_settings.user_user_type"), "userRoleId"),
            Validator.rule("userTelephone", "string", Rosetta.string("system_settings.user_phone_number"), "telephoneNumber", true),
            Validator.rule("userJobTitle", "string", Rosetta.string("system_settings.user_profession"), "jobTitle", true),
            Validator.rule("userCompany", "string", Rosetta.string("system_settings.user_company"), "company", true),
            Validator.rule("userProjectId", "int", Rosetta.string("system_settings.user_project_assign_validation"), "projectId", projectIdOptional)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setUserSubmitNetworkInFlight(true);

        const formData = validationResult.formData;
        if (user) {
            formData.append("id", user.id);
        }

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("system_settings.user_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    if (resp.data.user) {
                        setUser(resp.data.user);
                    }
                } else {
                    if (resp.error.code === "USS10010") {
                        AlertModal.showModal(
                            Rosetta.string("system_settings.user_submit_invite_prompt_title"),
                            Rosetta.string("system_settings.user_submit_invite_prompt_message"),
                            [
                                AlertModal.button(
                                    Rosetta.string("system_settings.user_submit_invite_prompt_confirm"),
                                    () => {
                                        AlertModal.dismissModal();

                                        inviteUserOverNetwork(
                                            userEmailAddress,
                                            userRoleId
                                        );
                                    },
                                    "success"
                                ),
                                AlertModal.button(
                                    Rosetta.string("common.cancel"),
                                    () => {
                                        AlertModal.dismissModal();
                                    }
                                )
                            ]
                        );
                    } else {
                        AlertModal.showError(API.formatError(resp));
                    }
                }
                setUserSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserSubmitNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", {error_code : "USS1000C"}));
            });
    }

    function fetchUserRolesFromNetwork() {
        if (rolesNetworkInFlight) return;
        setRolesNetworkInFlight(true);

        Axios.get(ENDPOINTS.user.getUserRoleTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let userRoles = resp.data.userRoles;

                    if (userRoles) {
                        setUserRoles(userRoles);
                        if (!userRoleId && userRoles.length > 0) {
                            setUserRoleId(userRoles[0].id);
                        }
                    }
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setRolesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setRolesNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "US2000C" }));
            });
    }

    function promptUserDeletion() {
        AlertModal.showModal(
            Rosetta.string("system_settings.user_delete_prompt_title"),
            Rosetta.string("system_settings.user_delete_prompt_message"),
            [
                AlertModal.button(
                    Rosetta.string("system_settings.user_delete_prompt_title"),
                    () => {
                        deleteUserOverNetwork();
                        AlertModal.dismissModal();
                    },
                    "danger"
                ),
                AlertModal.button(
                    Rosetta.string("common.cancel"),
                    () => {
                        AlertModal.dismissModal();
                    }
                )
            ]
        );
    }

    function deleteUserOverNetwork() {
        if (deleteNetworkInFlight) return;
        setDeleteNetworkInFlight(true);

        let clientID = undefined;
        const project = DataManager.getSelectedProject();
        if (project) {
            if (project.clientID) {
                clientID = project.clientID;
            }
        }

        const formData = {
            userId : user.id,
            clientId : clientID
        };

        Axios.post(ENDPOINTS.client.removeUserFromClient, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("system_settings.user_delete_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    Navigator.navigate("/settings/users");
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDeleteNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserNetworkInFlight(false);
                AlertModal.showError(API.defaultError("USD1000C"));
            });
    }

    function inviteUserOverNetwork(emailAddress, userRoleId) {
        if (userSubmitNetworkInFlight) return;
        setUserNetworkInFlight(true);

        const data = {
            emailAddress,
            userRoleId,
            projectId : userProject ? userProject.id : -1
        };

        Axios.post(ENDPOINTS.client.submitUserInvitation, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AlertModal.showModal(
                        Rosetta.string("system_settings.user_submit_invite_success_title"),
                        Rosetta.string("system_settings.user_submit_invite_success_message")
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setUserSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setUserSubmitNetworkInFlight(false);
                AlertModal.showError(API.defaultError("UCIC1000C"));
            })
    }

    // RENDER

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitUserOverNetwork()}>{Rosetta.string("common.save")}</button>);
    if (userSubmitNetworkInFlight) {
        submitButton = (<LoadingSpinner small={true} inline={true} />);
    }

    let deleteButton = [];
    if (user && currentUser.current) {
        // Only show the delete button when the user is actually able to remove the user.
        // Users of lower User Role IDs cannot delete users of higher priority Role IDs
        let currentUserPriority = AppUser.getUserRolePriority(currentUser.current.userRoleId);
        let userPriority = AppUser.getUserRolePriority(user.userRoleId);

        if (currentUserPriority <= userPriority) {
            deleteButton = (
                <button className={"btn btn-danger"} onClick={promptUserDeletion}>
                    {Rosetta.string("system_settings.user_delete_prompt_title")}
                </button>
            )

            if (deleteNetworkInFlight) {
                deleteButton = (
                    <button className={"btn btn-danger disabled"}>{Rosetta.string("common.please_wait")}</button>
                )
            }

            deleteButton = (
                <div className={"row mt-4"}>
                    <div className={"col-12 text-end"}>
                        {deleteButton}
                    </div>
                </div>
            )
        }
    }

    let projectElem = [];
    if (user === null) {
        projectElem = (
            <div className={"col-12 col-md-6 mt-2"}>
                <label>{Rosetta.string("system_settings.user_project_assign")}</label>
                <div className={"form-control"} onClick={() => setProjectSelectionShown(true)}>
                    { userProject ? userProject.project_name : Rosetta.string("system_settings.user_project_assign_none") }
                </div>
            </div>
        )
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("system_settings.user_detail")}/>
                </div>
            </div>

            <div className={"animate-screen-content"}>

                {deleteButton}

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-header"}>
                                {Rosetta.string("system_settings.user_personal_details")}
                            </div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_first_name")}</label>
                                        <input type={"text"} className={"form-control"} value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_surname")}</label>
                                        <input type={"text"} className={"form-control"} value={userSurname} onChange={(e) => setUserSurname(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_email")}</label>
                                        <input type={"text"} className={"form-control"} value={userEmailAddress} onChange={(e) => setUserEmailAddress(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_phone_number")}</label>
                                        <input type={"text"} className={"form-control"} value={userTelephone} onChange={(e) => setUserTelephone(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_user_type")}</label>
                                        <select className={"form-select"} value={userRoleId} onChange={(e) => setUserRoleId(e.target.value)}>
                                            { userRoles.map((role) => (
                                                <option value={role.id}>{role.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={"col-12 col-md-6"} data-comment={"Spacer"} />

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_profession")}</label>
                                        <input type={"text"} className={"form-control"} value={userJobTitle} onChange={(e) => setUserJobTitle(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("system_settings.user_company")}</label>
                                        <input type={"text"} className={"form-control"} value={userCompany} onChange={(e) => setUserCompany(e.target.value)} />
                                    </div>

                                    {projectElem}
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12 text-center"}>
                                        {submitButton}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ProjectSelectionModal
                shown={projectSelectionShown}
                callback={projectSelectionDidCallback}/>

        </div>
    )

}