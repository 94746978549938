import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import AlertModal from "../../alertmodal/AlertModal";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {SubscriptionCompanyDetailsComponent} from "./SubscriptionCompanyDetailsComponent";
import {SubscriptionUtil} from "../../../util/SubscriptionUtil";
import {CommonUtil} from "../../../util/CommonUtil";
import {SubscriptionUserDetailsComponent} from "./SubscriptionUserDetailsComponent";

import "./SubscriptionLandingScreen.css";
import {SubscriptionFinishComponent} from "./SubscriptionFinishComponent";
import {SubscriptionPaymentComponent} from "./SubscriptionPaymentComponent";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {SubscriptionBespokeFormComponent} from "./SubscriptionBespokeFormComponent";
import {TokyoHeaderBar} from "../../headerbar/TokyoHeaderBar";

import logoImage from "../../../assets/images/header_logo_2.svg";

export const SubscriptionLandingScreen = (props) => {

    const [params] = useSearchParams()

    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [subTypesNetworkInFlight, setSubTypesNetworkInFlight] = useState(false);

    const [curStep, setCurStep] = useState(1);
    const [formData, setFormData] = useState({});

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    useEffect(() => {
        getSubscriptionTypesFromNetwork();
    }, []);

    useEffect(() => {
        getSubscriptionTypesFromNetwork();
    }, [params]);

    function selectSubscriptionType(subTypes) {
        if (subTypes === undefined) {
            subTypes = subscriptionTypes;
        }

        if (subTypes && subTypes.length > 0) {
            let typeId = SubscriptionUtil.getTypeFromString(type);
            for (let i = 0; i < subTypes.length; i++) {
                if (parseInt(subTypes[i].id) === typeId) {
                    setSubscriptionType(subTypes[i]);
                    break;
                }
            }
        }
    }

    function stepDidCallback(action, data) {
        if (action === "submit") {
            let step = CommonUtil.getOrDefault(data, "step", curStep);
            step++;
            setCurStep(step);

            let newFormData = CommonUtil.mergeObjects(formData, data);

            console.log("NEW FORM DATA:");
            console.log(newFormData);
            setFormData(newFormData);
        }
    }

    function getSubscriptionTypesFromNetwork() {
        if (subTypesNetworkInFlight) return;
        setSubTypesNetworkInFlight(true);

        Axios.get(ENDPOINTS.subscription.getSubscriptionTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSubscriptionTypes(resp.data.types);
                    selectSubscriptionType(resp.data.types);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubTypesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubTypesNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "SUBL1000C" }));
            });
    }

    const type = params.get("type");
    const sku = params.get("sku");

    var mainContent = [];

    let screenTitle = "";
    let maxSteps = 1;
    let stepTitle = null;

    if (subTypesNetworkInFlight) {
        mainContent = (
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <LoadingSpinner inline={true} />
                </div>
            </div>
        )
    } else {
        if (subscriptionType) {
            const subTypeId = parseInt(subscriptionType.id);
            if (subTypeId === SubscriptionUtil.TYPES.FREE) {
                screenTitle = Rosetta.string("subscription.trial_title");
                maxSteps = 2;

                if (curStep === 1) {
                    stepTitle = Rosetta.string("subscription.step_company");
                    mainContent = (
                        <SubscriptionCompanyDetailsComponent
                            subscriptionType={subscriptionType}
                            sku={sku}
                            step={curStep}
                            maxStep={maxSteps}
                            formData={formData}
                            callback={stepDidCallback} />
                    )
                } else if (curStep === 2) {
                    stepTitle = Rosetta.string("subscription.step_account");
                    mainContent = (
                        <SubscriptionUserDetailsComponent
                            subscriptionType={subscriptionType}
                            sku={sku}
                            step={curStep}
                            maxStep={maxSteps}
                            formData={formData}
                            nextLabel={Rosetta.string("common.submit")}
                            callback={stepDidCallback} />
                    )
                } else if (curStep === 3) {
                    mainContent = (
                        <SubscriptionFinishComponent
                            formData={formData} />
                    )
                }
            } else if (subTypeId === SubscriptionUtil.TYPES.BUSINESS) {
                screenTitle = Rosetta.string("subscription.business_title");
                maxSteps = 3;

                if (curStep === 1) {
                    stepTitle = Rosetta.string("subscription.step_company");
                    mainContent = (
                        <SubscriptionCompanyDetailsComponent
                            subscriptionType={subscriptionType}
                            sku={sku}
                            step={curStep}
                            maxStep={maxSteps}
                            formData={formData}
                            callback={stepDidCallback} />
                    )
                } else if (curStep === 2) {
                    stepTitle = Rosetta.string("subscription.step_account");

                    mainContent = (
                        <SubscriptionUserDetailsComponent
                            subscriptionType={subscriptionType}
                            sku={sku}
                            step={curStep}
                            maxStep={maxSteps}
                            formData={formData}
                            nextLabel={Rosetta.string("subscription.subscription_user_next")}
                            callback={stepDidCallback} />
                    )
                } else if (curStep === 3) {
                    stepTitle = Rosetta.string("subscription.step_payment");
                    mainContent = (
                        <SubscriptionPaymentComponent
                            formData={formData}
                            step={curStep}
                            maxStep={maxSteps}
                            callback={stepDidCallback} />
                    )
                } else if (curStep === 4) {
                    mainContent = (
                        <SubscriptionFinishComponent
                            formData={formData} />
                    )
                }
            } else if (subTypeId === SubscriptionUtil.TYPES.BESPOKE) {
                mainContent = (
                    <SubscriptionBespokeFormComponent />
                )
            }
        } else {
            // TODO Some sort of error message
        }
    }

    let screenSubtitle = [];
    if (stepTitle !== null) {
        screenSubtitle = (
            <div className={"col-12 sub-screen-subtitle text-center mt-2"}>
                {Rosetta.string("subscription.step_prompt", { step : curStep, max_step : maxSteps, name : stepTitle })}
            </div>
        );
    }

    return (
        <Elements stripe={stripePromise}>
            <div className={"header-wrapper"}>
                <div className={"container"}>
                    <TokyoHeaderBar
                        bare={true}
                        logo={{ image: logoImage }} />
                </div>
            </div>

            <div className={"container"}>
                <div className={"app-screen subscription-landing-screen"}>
                    <div className={"row justify-content-center"}>
                        <div className={"col-12 col-md-10 col-lg-8 sub-screen-title text-center"}>
                            {screenTitle}
                        </div>
                        {screenSubtitle}
                    </div>

                    {mainContent}

                </div>
            </div>

        </Elements>
    )

}