export const StringUtil = {
    /**
     * Capitalises the first character of a given string
     * @param string        String to capitalise
     * @returns {string}    Capitalised string
     */
    capitalizeFirstLetter : (string) => {
        if (string !== undefined && string !== null && string.length > 0) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return string;
    },
    /**
     * Truncates a string in the centre so that it fits within a given length.
     *
     * e.g. AReallyLongString -> AReall…String
     *
     * @param string        Input string
     * @param length        Max length of the string
     * @param substitute    A string to put between the two halves. Default is an ellipsis character.
     * @returns {string|*}  The output string
     */
    truncateCentre : (string, length, substitute) => {
        if (substitute === undefined) {
            substitute = "…";
        }

        if (string.length > length) {
            let startStr = string.substring(0, Math.floor(length / 2));
            let endStr = string.substring(string.length - (length / 2), string.length);

            return startStr + substitute + endStr;
        }
        return string;
    },
    ellipsise : (string, length, substitute) => {
        if (substitute === undefined) {
            substitute = "…";
        }

        if (string.length > length) {
            return string.substring(0, length - 1) + substitute;
        }
        return string;
    },
    leftPad : (string, length, padString) => {
        string = string + ""; // Make sure input is not a number
        if (padString === undefined) {
            padString = " ";
        }

        let diff = length - string.length;
        if (diff > 0) {
            for (let i = 0; i < diff; i++) {
                string = padString + string;
            }
        }
        return string;
    }
}