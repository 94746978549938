import {ImageUtil} from "../../util/ImageUtil";

import "./SettingsPanelContent.css";

import accountDefault from "../../assets/images/default_profile.svg";
import {DataManager} from "../../data/DataManager";
import {EmergingButton} from "../button/EmergingButton";
import {TokyoNavigationList} from "../navigationlist/TokyoNavigationList";
import Rosetta from "../../rosetta/Rosetta";
import {AppUser} from "../../util/AppUser";
import {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../../util/CommonUtil";
import ArrayUtil from "../../util/ArrayUtil";

export const SettingsPanelContent = (props) => {

    const [appCounts, setAppCounts] = useState();
    const [user, setUser] = useState(null);
    const [clientId, setClientId] = useState(null);

    const dataManagerListener = useRef();

    useEffect(() => {
        dataManagerListener.current = (keyName, data) => {
            if (keyName.includes(DataManager.keys.app_counts)) {
                if (!CommonUtil.isObject(data)) {
                    data = JSON.parse(data);
                }
                setAppCounts(data);
            } else if (keyName === DataManager.keys.project) {
                if (!CommonUtil.isObject(data)) {
                    data = JSON.parse(data);
                }
                setAppCounts(
                    DataManager.getAppCounts(data.id)
                );
                setUser(DataManager.getUser());
            } else if (keyName === DataManager.keys.user) {
                if (!CommonUtil.isObject(data)) {
                    data = JSON.parse(data);
                }
                setUser(data);
            } else if (keyName === DataManager.keys.clientId) {
                setClientId(data);
            }
        };
        DataManager.addCallback(dataManagerListener.current);

        setUser(DataManager.getUser());
        setClientId(DataManager.getClientId());

        const project = DataManager.getSelectedProject();
        if (project) {
            setAppCounts(DataManager.getAppCounts(project.id));
        }

        return () => {
            DataManager.removeCallback(dataManagerListener.current);
        }
    }, []);

    let userName = "";
    if (user !== null) {
        userName = Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.surname });
    }

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    let navigationItems = [];

    if (!(process.env.REACT_APP_MODE === "cms")) {
        let inviteBadge = 0;
        if (appCounts && appCounts.hasOwnProperty("invites")) {
            inviteBadge = appCounts.invites;
        }

        navigationItems = [
            {
                title: Rosetta.string("system_settings.option_invites"),
                url: "/settings/invitations",
                badge : inviteBadge
            },
            {
                title: Rosetta.string("system_settings.option_users"),
                url: "/settings/users"
            },
            {
                title: Rosetta.string("system_settings.option_projects"),
                url: "/settings/projects"
            },
            {
                title: Rosetta.string("system_settings.option_company_details"),
                url: "/settings/company-details"
            }
        ];
    }

    if (!clientId) {
        // If no Client ID has been set, show nothing as it will likely lead to a 401
        // CADE 18/07/24 - Added access to Projects, this led to issues with fresh accounts.
        navigationItems = [
            {
                title: Rosetta.string("system_settings.option_projects"),
                url: "/settings/projects"
            }
        ];
    }

    if (user !== null) {
        if (parseInt(user.userRoleId) === AppUser.roles.LEAD_ADMIN || parseInt(user.userRoleId) === AppUser.roles.SYSTEM_ADMIN) {
            navigationItems.push({
                title : Rosetta.string("system_settings.option_subscription"),
                url : "/settings/subscription-details"
            });
        }
    }

    return (
        <div className={"settings-side-panel-content"}>
            <div className={"side-settings-profile-panel"}>
                <div className={"account-circle"} style={{backgroundImage : ImageUtil.background(accountDefault)}} />
                <div className={"info-area"}>
                    <span className={"profile-name"}>{userName}</span>
                    <span className={"profile-subtitle"}>
                        <EmergingButton pill={true}>
                            <button className={"btn"} onClick={() => handleCallback("signout")}>{Rosetta.string("common.sign_out")}</button>
                        </EmergingButton>
                    </span>
                </div>
            </div>

            <TokyoNavigationList
                callback={() => handleCallback("close")}
                items={[
                    {
                        title : Rosetta.string("system_settings.title"),
                        items : navigationItems
                    }
                ]} />
        </div>
    )

}