import {FilterText} from "../../filter/FilterText";
import {useEffect, useState} from "react";
import TableComponent, {Column, TablePaginator} from "../../table/TableComponent";
import Rosetta from "../../../rosetta/Rosetta";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../chronos/Chronos";
import {Toast} from "../../toast/TokyoToaster";
import {Navigator} from "../../../util/Navigator";
import {StringUtil} from "../../../util/StringUtil";

export const DiaryHealthSafetyComponent = (props) => {

    const TYPE_INDUCTIONS = "inductions";
    const TYPE_FORMS = "incident";
    const TYPE_EVACUATION = "evacuation";

    const {date} = props;

    const [inductions, setInductions] = useState([]);
    const [inductionPage, setInductionPage] = useState(1);
    const [inductionCount, setInductionCount] = useState(0);
    const [inductionNetworkInFlight, setInductionNetworkInFlight] = useState(false);

    const [forms, setForms] = useState([]);
    const [formsPage, setFormsPage] = useState(1);
    const [formsCount, setFormsCount] = useState(0);
    const [formsNetworkInFlight, setFormsNetworkInFlight] = useState(false);

    const [evacuations, setEvacuations] = useState([]);
    const [evacuationsPage, setEvacuationsPage] = useState(1);
    const [evacuationsCount, setEvacuationsCount] = useState(0);
    const [evacuationNetworkInFlight, setEvacuationNetworkInFlight] = useState(false);

    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        fetchInductionsFromNetwork();
        fetchFormsFromNetwork();
        fetchEvacuationsFromNetwork();
    }, []);

    useEffect(() => {
        setInductionNetworkInFlight(false);
        fetchInductionsFromNetwork();
    }, [inductionPage, date, filterKeyword]);

    useEffect(() => {
        setFormsNetworkInFlight(false);
        fetchFormsFromNetwork();
    }, [formsPage, date, filterKeyword]);

    useEffect(() => {
        setEvacuationNetworkInFlight(false);
        fetchEvacuationsFromNetwork();
    }, [evacuationsPage, date, filterKeyword]);

    function inductionRowWasClicked(row) {
        if (row) {
            Navigator.navigate("/healthSafety/induction/" + row.id);
        }
    }

    function formRowWasClicked(row) {
        if (row) {
            Navigator.navigate("/healthSafety/report/" + row.id);
        }
    }

    function getDateSeconds() {
        if (date) {
            const chronosDate = Chronos.with(date);
            if (chronosDate.isValid()) {
                return chronosDate.seconds();
            }
        }
        return null;
    }

    function showError(message) {
        Toast.show(
            Rosetta.string("common.error"),
            message,
            Toast.ERROR,
            Toast.LONG
        );
    }

    function fetchInductionsFromNetwork() {
        if (inductionNetworkInFlight) return;
        setInductionNetworkInFlight(true);

        fetchDataFromNetwork(
            TYPE_INDUCTIONS,
            getDateSeconds(),
            inductionPage,
            (success, response) => {
                if (success) {
                    if (response.success) {
                        setInductions(response.data.result);
                        setInductionCount(response.data.count);
                    } else {
                        showError(API.formatError(response));
                    }
                } else {
                    showError(Rosetta.string("common.error_common_unknown", {error_code : "DHSS1000C"}));
                }
                setInductionNetworkInFlight(false);
            }
        )
    }

    function fetchFormsFromNetwork() {
        if (formsNetworkInFlight) return;
        setFormsNetworkInFlight(true);

        fetchDataFromNetwork(
            TYPE_FORMS,
            getDateSeconds(),
            formsPage,
            (result, resp) => {
                if (result) {
                    if (resp.success) {
                        setForms(resp.data.result);
                        setFormsCount(resp.data.count);
                    } else {
                        showError(API.formatError(resp));
                    }
                } else {
                    showError(Rosetta.string("common.error_common_unknown", {error_code : "DHSS5000C"}));
                }
                setFormsNetworkInFlight(false);
            }
        );
    }

    function fetchEvacuationsFromNetwork() {
        if (evacuationNetworkInFlight) return;
        setEvacuationNetworkInFlight(true);

        fetchDataFromNetwork(
            TYPE_EVACUATION,
            getDateSeconds(),
            evacuationsPage,
            (result, resp) => {
                if (result) {
                    if (resp.success) {
                        setEvacuations(resp.data.records);
                        setEvacuationsCount(resp.data.count);
                    } else {
                        showError(API.formatError(resp));
                    }
                } else {
                    showError(Rosetta.string("common.common_errors_unknown", { error_code : "DHSS3000C"}));
                }
                setEvacuationNetworkInFlight(false);
            }
        )
    }

    function fetchDataFromNetwork(type, date, page, callback) {
        const project = DataManager.getSelectedProject();

        const formData = {
            projectID : (project ? project.id : undefined),
            type,
            date,
            page
        };

        Axios.post(ENDPOINTS.projectHealthSafety.getHealthSafetySummary, formData)
            .then((r) => {
                callback(true, API.parse(r));
            })
            .catch((e) => {
                console.log(e);
                callback(false);
            });
    }

    return (
        <div className={"animate-screen-content diary-health-safety-component"}>
            <div className={"row mt-4"}>

                <div className={"col-12 col-md-4"}>
                    <FilterText callback={setFilterKeyword} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("diary.hs_title_inductions")}</h3>
                </div>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={inductions}
                        placeholderMode={inductionNetworkInFlight}
                        placeholderRows={3}
                        onRowClick={inductionRowWasClicked}>

                        <Column name={"firstName"} title={Rosetta.string("health_safety.induction_table_first_name")} />
                        <Column name={"surname"} title={Rosetta.string("health_safety.induction_table_last_name")} />
                        <Column name={"userRoleName"} title={Rosetta.string("health_safety.induction_table_user_type")} />
                        <Column name={"jobTitle"} title={Rosetta.string("health_safety.induction_table_job_title")} />
                        <Column name={"emailAddress"} title={Rosetta.string("health_safety.induction_table_email")} />
                        <Column name={"company"} title={Rosetta.string("health_safety.induction_table_company")} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={inductionPage}
                        totalCount={inductionCount}
                        pageSize={20}
                        onClick={setInductionPage} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("diary.hs_title_forms")}</h3>
                </div>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={forms}
                        placeholderMode={formsNetworkInFlight}
                        placeholderRows={3}
                        onRowClick={formRowWasClicked}>

                        <Column name={"firstName"} title={Rosetta.string("health_safety.report_table_author")} render={(data, row) => (
                            <>{Rosetta.string("common.name_format", { given_name : row.firstName, family_name : row.surname })}</>
                        )} />,
                        <Column name={"dateCreated"} title={Rosetta.string("health_safety.report_table_date")} render={(data, row) => (
                            <>{Chronos.withTimestampSeconds(data).format("dd/MM/yyyy HH:mm")}</>
                        )} />,
                        <Column name={"projectLocationName"} title={Rosetta.string("health_safety.report_table_location")} />,
                        <Column name={"description"} title={Rosetta.string("health_safety.report_table_description")} render={(data, row) => (
                            <>{StringUtil.ellipsise(data, 40)}</>
                        )} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={formsPage}
                        totalCount={formsCount}
                        pageSize={20}
                        onClick={setFormsCount} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <h3>{Rosetta.string("diary.hs_title_evacuations")}</h3>
                </div>

                <div className={"table-container"}>
                    <TableComponent
                        className={"clickable"}
                        data={evacuations}
                        placeholderMode={evacuationNetworkInFlight}
                        placeholderRows={3}>

                        <Column name={"startDate"} title={Rosetta.string("diary.hs_alarm_started")} />
                        <Column name={"id"} title={Rosetta.string("diary.hs_alarm_started_by")} />
                        <Column name={"endDate"} title={Rosetta.string("diary.hs_alarm_ended")} />
                        <Column name={"id"} title={Rosetta.string("diary.hs_alarm_ended_by")} />

                    </TableComponent>
                </div>

                <div className={"ep-table-paginate-container mt-1"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={evacuationsPage}
                        totalCount={evacuationsCount}
                        pageSize={20}
                        onClick={setEvacuationsCount} />
                </div>
            </div>

        </div>
    )

}